import {dbg} from '../debug/debug';
import {deepEqual} from '../utils/utils';

/* tslint:disable:ban-types */

export interface ElementBase {
  name: string;
  parent?: ElementBase;
}

export interface NoteElement extends ElementBase {
  symbol?: string;
  superscript?: string;
  subscript?: string;
  frequency?: string;
  cents?: string;
}

export function NoteElementDefault(
  name: string = '',
  parent?: TuningElement,
): NoteElement {
  const r: NoteElement = {
    name,
    parent,
  };
  if (parent) {
    InstrumentAsMap.set(parent, r, true);
  } else {
    delete r.parent;
  }

  return r;
}

export function noteElementsAreEqual(
  n1: NoteElement,
  n2: NoteElement,
): boolean {
  if (n1 === n2) return true;
  const c1 = copyNoteElement(n1);
  const c2 = copyNoteElement(n2);
  return deepEqual(c1, c2);
}

export interface TuningElement extends ElementBase {
  noteElement: NoteElement[];
  reference?: Object;
  transpose?: Object;
  // Only set when is "stock" from strobopro
  isDefaultTuning?: boolean;
  userModified?: boolean;
}

export function tuningElementsAreEqual(
  n1: TuningElement,
  n2: TuningElement,
): boolean {
  if (n1 === n2) return true;
  const c1 = copyTuning(n1);
  const c2 = copyTuning(n2);
  return deepEqual(c1, c2);
}

export interface ShareableTuningElement extends TuningElement {
  subInstrumentName?: string;
  instrumentName?: string;
}

export function TuningElementDefault(
  name: string = '',
  parent?: SubInstrumentElement,
): TuningElement {
  const r: TuningElement = {
    name,
    parent,
    noteElement: [],
  };
  if (parent) {
    InstrumentAsMap.set(parent, r, true);
  } else {
    delete r.parent;
  }
  return r;
}

export interface SubInstrumentElement extends ElementBase {
  tuning: TuningElement[];
}

export function subInstrumentElementsAreEqual(
  n1: SubInstrumentElement,
  n2: SubInstrumentElement,
): boolean {
  if (n1 === n2) return true;
  const c1 = copySubInstrument(n1);
  const c2 = copySubInstrument(n2);
  return deepEqual(c1, c2);
}

export function SubInstrumentElementDefault(
  name: string = '',
  parent?: InstrumentElement,
): SubInstrumentElement {
  const r: SubInstrumentElement = {
    name,
    parent,
    tuning: [],
  };
  if (parent) {
    InstrumentAsMap.set(parent, r, true);
  } else {
    delete r.parent;
  }

  return r;
}

export interface InstrumentElement extends ElementBase {
  subInstrument: SubInstrumentElement[];
}

export function instrumentElementsAreEqual(
  n1: InstrumentElement,
  n2: InstrumentElement,
): boolean {
  if (n1 === n2) return true;
  const c1 = copyInstrument(n1, false);
  const c2 = copyInstrument(n2, false);
  return deepEqual(c1, c2);
}

export function InstrumentElementDefault(name: string = ''): InstrumentElement {
  const r: InstrumentElement = {
    name,
    subInstrument: [],
  };
  return r;
}

// ----------------------------------------------------------------------------
export interface InstrumentGeneric extends ElementBase {
  subInstrument?: InstrumentGeneric[];
  tuning?: InstrumentGeneric[];
  noteElement?: InstrumentGeneric[];
}

// Get the child type of the parent.
// ----------------------------------------------------------------------------
export function getChildren(parent: InstrumentGeneric) {
  let rval: null | InstrumentGeneric[] = null;
  if (parent.subInstrument) {
    rval = parent.subInstrument;
  } else if (parent.tuning) {
    rval = parent.tuning;
  } else if (parent.noteElement) {
    rval = parent.noteElement;
  }
  return rval;
}

// ----------------------------------------------------------------------------
export class ElementBaseHelper {
  e: null | undefined | ElementBase;
  constructor(b: undefined | null | ElementBase) {
    this.e = b;
  }
  getName(): string {
    let r = '';
    if (this.e) {
      r = this.e.name;
    }
    return r;
  }
  getParent(): null | ElementBase {
    const r = this.e ? this.e.parent : null;
    return r ? r : null;
  }

  getParentName(): string {
    const p = this.getParent();
    return p ? p.name : 'null';
  }

  getGrandParent(): null | ElementBase {
    const h = new ElementBaseHelper(this.getParent());
    return h.getParent();
  }

  getGrandParentName(): string {
    const p = this.getGrandParent();
    return p ? p.name : 'null';
  }

  getGreatGrandParent(): null | ElementBase {
    const h = new ElementBaseHelper(this.getGrandParent());
    return h.getParent();
  }

  getGreatGrandParentName(): string {
    const p = this.getGreatGrandParent();
    return p ? p.name : 'null';
  }
}

// ----------------------------------------------------------------------------
// Makes a copy of noteElement (and returns it) and also replaces the noteElement
// in parent if replaceMeInParent is true.
export function copyNoteElement(
  noteElement: NoteElement,
  parent?: TuningElement,
  replaceMeInParent?: boolean, // if true, updates the parent with this new element
): NoteElement {
  let rval: NoteElement = {name: '', parent};
  if (noteElement) {
    rval = {...rval, ...noteElement, parent};
  }

  if (!!parent && !!replaceMeInParent) {
    InstrumentAsMap.set(parent, noteElement);
  }
  if (!rval.parent) {
    delete rval.parent;
  }

  return rval;
}

// ----------------------------------------------------------------------------
// @brief Copies tuning into a new tuning. Sets the parent, too, if set
// and sets this as a child of the parent if replaceMeInParent is true.
export function copyTuning(
  tuning: TuningElement,
  parent?: SubInstrumentElement,
  replaceMeInParent?: boolean, // if true, updates the parent with this new element
): TuningElement {
  let rval: TuningElement = TuningElementDefault();
  if (tuning && tuning.noteElement) {
    rval = {...rval, ...tuning, parent};
    rval.noteElement = tuning.noteElement.map((noteElement: NoteElement) => {
      return copyNoteElement(noteElement, !!parent ? rval : undefined);
    });
  }
  rval.parent = parent;
  if (!!parent && !!replaceMeInParent) {
    InstrumentAsMap.set(parent, tuning);
  }
  if (!rval.parent) {
    delete rval.parent;
  }

  return rval;
}

/*
export function copyTuningToTuning(
  src: TuningElement,
  dst: TuningElement,
  isDefaultTuning: boolean = !!dst.isDefaultTuning,
): TuningElement {
  const dstBackup = { ...dst };

  dst = { ...src };
  dst.name = src.name;
  dst.isDefaultTuning = isDefaultTuning;
  dst.userModified = false;
  dst.noteElement = src.noteElement.map((srcElement: NoteElement) => {
    return copyNoteElement(srcElement, dst);
  });
  dst.parent = dstBackup.parent;
  if (dst.parent) {
    const sub: SubInstrumentElement = dst.parent as SubInstrumentElement;
    //const oldTuningIdx = findTuning(sub, dst.name);
    const idx = sub.tuning.findIndex((value: TuningElement) => {
      return value.name === dst.name;
    });
    if (idx >= 0) {
      sub.tuning[idx] = dst;
    }
  }
  return dst;
}

export function deleteNoteFromTuning(
  tuning: TuningElement,
  noteElement: NoteElement,
): TuningElement {
  const newElements: Array<NoteElement> = [];
  tuning.noteElement.forEach((n: NoteElement) => {
    if (n.name !== noteElement.name) {
      newElements.push(n);
    }
  });
  tuning.noteElement = newElements;
  tuning.userModified = true;
  return tuning;
}
*/

export function findSubInstrument(
  inst: InstrumentElement | null,
  name: string,
): null | SubInstrumentElement {
  if (!name || name.length === 0 || inst === null) return null;
  const element: undefined | SubInstrumentElement = inst.subInstrument.find(
    (value: ElementBase) => {
      return value.name === name;
    },
  );
  return element ? element : null;
}

export function findTuning(
  inst: null | SubInstrumentElement,
  name?: string,
): null | TuningElement {
  if (!name || name.length === 0 || inst === null) return null;
  const element: undefined | TuningElement = inst.tuning.find(
    (value: ElementBase) => {
      return value.name === name;
    },
  );
  return element ? element : null;
}

export function findNoteElement(
  inst: null | TuningElement,
  name?: string,
): null | NoteElement {
  if (!name || name.length === 0 || inst === null) return null;
  const element: undefined | NoteElement = inst.noteElement.find(
    (value: ElementBase) => {
      return value.name === name;
    },
  );
  return element ? element : null;
}

// ----------------------------------------------------------------------------
export function copySubInstrument(
  subinstrument: SubInstrumentElement,
  parent?: InstrumentElement,
  replaceMeInParent?: boolean, // if true, updates the parent with this new element
): SubInstrumentElement {
  let rval: SubInstrumentElement = {name: '', parent, tuning: []};
  if (subinstrument && subinstrument.tuning) {
    rval = {...rval, ...subinstrument, parent};
    rval.tuning = subinstrument.tuning.map((tuning: TuningElement) => {
      return copyTuning(tuning, !!parent ? rval : undefined);
    });
  }
  rval.parent = parent;
  if (!!parent && !!replaceMeInParent) {
    InstrumentAsMap.set(parent, subinstrument);
  }
  if (!rval.parent) {
    delete rval.parent;
  }

  return rval;
}

// ----------------------------------------------------------------------------
export function copyInstrument(
  instrument: InstrumentElement,
  doAddParents: boolean = false,
): InstrumentElement {
  let rval: InstrumentElement = {name: '', subInstrument: []};
  if (instrument && instrument.subInstrument) {
    rval = {...rval, ...instrument};
    rval.subInstrument = instrument.subInstrument.map(
      (subinstrument: SubInstrumentElement) => {
        const subInst = copySubInstrument(
          subinstrument,
          !!doAddParents ? rval : undefined,
          false,
        );
        return subInst;
      },
    );
  }
  rval.parent = undefined;
  if (doAddParents) {
    recursiveAddParents(rval);
  }
  return rval;
}

export function findTuningInInstrumentMap(
  tuning: TuningElement,
  instrumentMap: Map<string, InstrumentElement>,
): TuningElement | undefined {
  let r: undefined | TuningElement;
  const e: ElementBaseHelper = new ElementBaseHelper(tuning);
  const sub: null | ElementBase = e.getParent();
  const inst: null | ElementBase = e.getGrandParent();
  if (!!sub && !!inst) {
    const instrumentToSearchFor = inst as InstrumentElement;
    const existingInst = instrumentMap.get(instrumentToSearchFor.name);
    if (!!existingInst) {
      const existingSub = InstrumentAsMap.get(existingInst, sub.name);
      if (!!existingSub) {
        const existingTuning = InstrumentAsMap.get(existingSub, tuning.name);
        if (!!existingTuning) {
          r = existingTuning as TuningElement;
        }
      }
    }
  }
  return r;
}

// ----------------------------------------------------------------------------
export class InstrumentAsMap {
  static get(
    inst: InstrumentGeneric,
    key: string,
  ): undefined | InstrumentGeneric {
    let rval;
    const childrenArray = getChildren(inst);
    if (childrenArray) {
      rval = childrenArray.find((child: InstrumentGeneric) => {
        return child.name === key;
      });
    }
    return rval;
  }

  // Deletes the child in an map. Returns the index where the child was found
  static delete(inst: InstrumentGeneric, key: string): number {
    let r = -1;
    const childrenArray = getChildren(inst);
    if (childrenArray) {
      const newChildrenArray: InstrumentGeneric[] = [];
      childrenArray.forEach((child: InstrumentGeneric, index: number) => {
        if (child.name === key) {
          r = index;
        } else {
          newChildrenArray.push(child);
        }
      });
      if (inst.subInstrument) {
        inst.subInstrument = newChildrenArray;
      } else if (inst.tuning) {
        inst.tuning = newChildrenArray;
      } else if (inst.noteElement) {
        inst.noteElement = newChildrenArray;
      }
    }
    return r;
  }

  // Sets or inserts a new child into the mapping.
  // if replaceName is set, then will replace the given replaceName
  static set(
    inst: InstrumentGeneric,
    newchild: InstrumentGeneric,
    setParent: boolean = true,
    replaceName?: string,
  ) {
    let childrenArray = getChildren(inst);
    replaceName =
      !!replaceName && replaceName.length > 0 ? replaceName : newchild.name;
    if (childrenArray) {
      let inserted = false;
      childrenArray = childrenArray.map((child: InstrumentGeneric) => {
        if (child.name === replaceName) {
          inserted = true;
          return newchild;
        } else {
          return child;
        }
      });
      if (!inserted) {
        childrenArray.push(newchild);
      }
      if (setParent) {
        newchild.parent = inst;
      }
      if (inst.subInstrument) {
        inst.subInstrument = childrenArray;
      } else if (inst.tuning) {
        inst.tuning = childrenArray;
      } else if (inst.noteElement) {
        inst.noteElement = childrenArray;
      }
    }
  }

  // If you want to keep mInst as a state variable, use the nonstatic versions.
  mInst: InstrumentGeneric;

  constructor(inst: InstrumentGeneric) {
    this.mInst = inst;
  }

  getChild(key: string): undefined | InstrumentGeneric {
    return InstrumentAsMap.get(this.mInst, key);
  }

  // Deletes the child in an map. Returns the index where the child was found
  deleteChild(key: string): number {
    return InstrumentAsMap.delete(this.mInst, key);
  }

  // Sets or inserts a new child into the mapping.
  // if replaceName is set, then will replace the given replaceName
  setChild(
    newchild: InstrumentGeneric,
    setParent: boolean = true,
    replaceName?: string,
  ) {
    return InstrumentAsMap.set(this.mInst, newchild, setParent, replaceName);
  }
}

// Goes downwards from parent to the child and lets the children hold the parents hand.
export function recursiveAddParents(parent: InstrumentGeneric) {
  const children = getChildren(parent);
  if (children) {
    children.forEach((child: InstrumentGeneric) => {
      child.parent = parent;

      recursiveAddParents(child);
    });
  }
}

export function recursiveRemoveParents(parent: InstrumentGeneric) {
  const children = getChildren(parent);
  delete parent.parent;
  if (children) {
    children.forEach((child: InstrumentGeneric) => {
      delete child.parent;
      recursiveRemoveParents(child);
    });
  }
}

export interface SerializedTuning {
  saveKey: string;
  instrumentName: string;
  subInstrumentName: string;
  tuningName: string;
  // Stores note elements using name as key
  noteElementsMap: any;
}

// Removes children from instrument and sub-instrument
// Removes parents from tuning and note elements
// This enables tuning to be a single pointer to parents and children of the tuning
export function serializeTuning(
  instrument: InstrumentElement | undefined,
  subInst: SubInstrumentElement | undefined,
  tuning: TuningElement,
): TuningElement {
  let rval = TuningElementDefault();
  if (!subInst || !instrument) {
    const e = new ElementBaseHelper(tuning);
    if (!subInst) {
      subInst = e.getParent() as SubInstrumentElement;
    }
    if (!instrument) {
      instrument = e.getGrandParent() as InstrumentElement;
    }
  }

  if (!!tuning && !!subInst && !!instrument) {
    const tuningCopy = copyTuning(tuning);
    const subInstrument = SubInstrumentElementDefault(subInst.name);
    const newInstrument = InstrumentElementDefault(instrument.name);
    recursiveRemoveParents(tuningCopy);
    tuningCopy.parent = subInstrument;
    tuningCopy.parent.parent = newInstrument;

    try {
      const str = JSON.stringify(tuningCopy, null, 2);
      dbg.ignore(str);
      rval = tuningCopy;
    } catch (e) {
      console.error('Could not stringify ' + tuning.name, e);
    }
  }

  return rval;
}

// The input tuning has parents pointing upwards and children pointing downwards
// Returned value does NOT have any parents set, so it is down only
export function deSerializeTuning(
  reverseTuning: TuningElement,
): InstrumentElement {
  let rval = InstrumentElementDefault();
  if (
    !!reverseTuning &&
    !!reverseTuning.parent &&
    !!reverseTuning.parent.parent
  ) {
    const e = new ElementBaseHelper(reverseTuning);
    const tuningIn = reverseTuning as TuningElement;
    const subInstrumentIn = e.getParent() as SubInstrumentElement;
    const instrumentIn = e.getGrandParent() as InstrumentElement;
    if (!!tuningIn && !!subInstrumentIn && !!instrumentIn) {
      const instrument = {
        ...InstrumentElementDefault(instrumentIn.name),
        ...instrumentIn,
      };
      const subInstrument = {
        ...SubInstrumentElementDefault(subInstrumentIn.name),
        ...subInstrumentIn,
      };
      let tuning = {...TuningElementDefault(tuningIn.name), ...tuningIn};
      tuning = copyTuning(tuning);

      subInstrument.tuning = [tuning];
      instrument.subInstrument = [subInstrument];
      recursiveRemoveParents(instrument);
      rval = instrument;
    }
  }

  return rval;
}

export function makeInstrumentWithOnlyThisTuning(
  t: TuningElement,
): InstrumentElement {
  const s = serializeTuning(undefined, undefined, t);
  const d = deSerializeTuning(s);
  return d;
}

export function expandTemperaments(instrument: InstrumentElement) {
  instrument.subInstrument.forEach((subInstrument: SubInstrumentElement) => {
    subInstrument.tuning.forEach((tuningElement: TuningElement) => {
      tuningElement.noteElement.forEach((noteElement: NoteElement) => {
        if (noteElement.name && noteElement.name.length > 0) {
          dbg.log('instrument_defs::todo:: fill in expandTemperaments!');
        }
      });
    });
  });
}

export function setDefaultFlags(instrument: InstrumentElement) {
  instrument.subInstrument.forEach((subInstrument: SubInstrumentElement) => {
    subInstrument.tuning.forEach((tuningElement: TuningElement) => {
      tuningElement.isDefaultTuning = true;
    });
  });
}

export function copyInstrumentsMap(
  map: Map<string, InstrumentElement>,
  doAddParents: boolean = false,
) {
  const rval = new Map<string, InstrumentElement>();
  map.forEach((instrument: InstrumentElement) => {
    rval.set(instrument.name, copyInstrument(instrument, doAddParents));
  });

  return rval;
}

export function stringifyInstrument(inst: InstrumentElement): string {
  const cp = copyInstrument(inst, false);
  let rval = JSON.stringify(cp, null, 0);
  rval = rval.replace(/\n/g, '');
  rval = rval.replace(/\\n/g, '');
  return rval;
}

export function stringifyTuning(tuning: TuningElement): string {
  const cp = serializeTuning(
    undefined,
    undefined,
    tuning,
  ) as ShareableTuningElement;
  const e = new ElementBaseHelper(tuning);
  delete cp.parent;
  cp.subInstrumentName = e.getParentName();
  cp.instrumentName = e.getGrandParentName();

  let rval = JSON.stringify(cp, null, 0);
  rval = rval.replace(/\n/g, '');
  rval = rval.replace(/\\n/g, '');
  return rval;
}

// Returns an instrument that can be easily used with redux add tuning command
// As it only contains the single tuning.
export function deStringifyTuningToInstrument(
  s: string,
): null | InstrumentElement {
  let inst = null;
  const tuning: ShareableTuningElement = JSON.parse(s);
  if (!!tuning.instrumentName && tuning.instrumentName.length > 0) {
    if (!!tuning.subInstrumentName && tuning.subInstrumentName.length > 0) {
      if (!!tuning.name && tuning.name.length > 0) {
        const subInstrument = SubInstrumentElementDefault(
          tuning.subInstrumentName,
        );
        const instrument = InstrumentElementDefault(tuning.instrumentName);
        subInstrument.parent = instrument;
        tuning.parent = subInstrument;
        inst = deSerializeTuning(tuning);
      }
    }
  }

  return inst;
}

export function deStringifyTuningToTuning(s: string): null | TuningElement {
  let tuning = null;
  const inst = deStringifyTuningToInstrument(s);
  if (!!inst && !!inst.subInstrument[0] && !!inst.subInstrument[0].tuning[0]) {
    // Add parents to the tuning, otherwise the tuning will not point "up" the stack to the parent instrument
    recursiveAddParents(inst);
    tuning = inst.subInstrument[0].tuning[0];
  }
  return tuning;
}

export function forEachTuningInInstrument(
  inst: InstrumentElement,
  cb: (tuning: TuningElement, index: number) => void,
) {
  inst.subInstrument.forEach((subInstrument: SubInstrumentElement) => {
    subInstrument.tuning.forEach(cb);
  });
}

export function forEachTuningInInstrumentMap(
  insts: Map<string, InstrumentElement>,
  cb: (tuning: TuningElement, index: number) => void,
) {
  insts.forEach((inst: InstrumentElement) => {
    forEachTuningInInstrument(inst, cb);
  });
}

export function deUrlifyLinkToTuning(link: string): null | TuningElement {
  let inst = null;
  try {
    const s1 = link.replace('urlified_', '');
    const s2 = decodeURIComponent(s1);

    const tuning = deStringifyTuningToTuning(s2);

    inst = tuning;
  } catch (reason: any) {
    inst = null;
  }
  return inst;
}

/* tslint:enable:ban-types */
