import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';
import {MyTheme} from '../../../../palette';
import {Container} from '@material-ui/core';
import {dbg} from '@common/debug/debug';
import '../../../../App.css';

const useStyles = makeStyles({
  option: {
    color: MyTheme.palette.grey[50],
  },
  autoCompleteStyle: {
    width: '100%',
    alignSelf: 'center',
  },
  '@global': {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: '#333842',
    },
  },
});

export interface MyChooserProps {
  options: string[];
  label: string;
  freeSolo?: boolean;
  onlyTextField?: boolean;
  doRender: (element: string) => JSX.Element;
  onChange: (element: null | string) => void;
}

// ----------------------------------------------------------------------------
export const MyChooser = (props: MyChooserProps) => {
  const [resetInputValue, setResetInputValue] = useState(false);
  const [key, setKey] = useState(0);
  const [stringsList, setStringsList] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const [lastValue, setLastValue] = useState('');

  // --------------------------------------------------------------------------
  // If options change, change selected value and reset the input value.
  useEffect(() => {
    setResetInputValue(true);
    setStringsList(props.options);
  }, [props.options]);

  // --------------------------------------------------------------------------
  // If the strings list changes, then for one render, update the key
  useEffect(() => {
    if (resetInputValue) {
      setResetInputValue(false);
      setKey(key + 1);
    }
  }, [resetInputValue, key]);
  const classes = useStyles();

  const onChange = useCallback(() => {
    if (props.onChange) {
      props.onChange(value);
    }
  }, [value, props]);

  useEffect(() => {
    if (value.length > 0 && value !== lastValue) {
      setLastValue(value);
      onChange();
    }
  }, [value, lastValue, onChange]);

  // --------------------------------------------------------------------------
  return !props.onlyTextField ? (
    <Container style={{flex: 1}}>
      <Autocomplete
        key={key}
        clearOnEscape
        fullWidth={true}
        className={classes.autoCompleteStyle}
        autoSelect={false}
        options={stringsList}
        classes={{
          option: classes.option,
        }}
        includeInputInList={props.freeSolo}
        freeSolo={props.freeSolo}
        getOptionLabel={option => option}
        getOptionSelected={(option: string, selValue: string) => {
          if (
            resetInputValue &&
            (stringsList.length === 0 || option === stringsList[0])
          ) {
            // Prevent error when the strings list has just updated.
            return true;
          } else {
            return option === selValue;
          }
        }}
        onChange={(_event: any, _item: string | null) => {
          dbg.log('Mychooser onChange:', _item);
          if (_item) {
            setValue(_item);
          }
        }}
        renderOption={(item: string) => {
          return props.doRender(item);
        }}
        renderInput={params => (
          <TextField
            onChange={(_event: ChangeEvent<HTMLInputElement>) => {
              if (!!_event && !!_event.target && !!_event.target.value) {
                setValue(_event.target.value);
              }
            }}
            {...params}
            label={props.label}
            inputProps={{
              ...params.inputProps,
              autoComplete: !!props.freeSolo ? undefined : 'new-password',
            }}
          />
        )}
      />
    </Container>
  ) : (
    <Container style={{flex: 1}}>
      <TextField
        style={{width: '100%'}}
        inputProps={{autoComplete: 'new-password'}}
        onChange={(_event: ChangeEvent<HTMLInputElement>) => {
          if (!!_event && !!_event.target && !!_event.target.value) {
            setValue(_event.target.value);
          }
        }}
        label={props.label}
      />
    </Container>
  );
};
