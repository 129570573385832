export enum DatunerNotes {
  E_NOTE_A = 0,
  E_NOTE_Ash = 1,
  E_NOTE_B = 2,
  E_NOTE_C = 3,
  E_NOTE_Csh = 4,
  E_NOTE_D = 5,
  E_NOTE_Dsh = 6,
  E_NOTE_E = 7,
  E_NOTE_F = 8,
  E_NOTE_Fsh = 9,
  E_NOTE_G = 10,
  E_NOTE_Gs = 11,
}

export enum NormalNotes {
  E_NOTE_C = 0,
  E_NOTE_Csh,
  E_NOTE_D,
  E_NOTE_Dsh,
  E_NOTE_E,
  E_NOTE_F,
  E_NOTE_Fsh,
  E_NOTE_G,
  E_NOTE_Gs,
  E_NOTE_A,
  E_NOTE_Ash,
  E_NOTE_B,
}

export const CHROMATIC = 'None (Chromatic)';
export const PERFECT = '(Equal, Perfect Oct)';
export const AUDIO_IN_DEFAULT = 'default';
export const CENTS_IN_TUNE = 8;

export const transpositionStrings = [
  'None',
  'C# (C->B)',
  'D (C->A#)',
  'D# (C->A)',
  'E (C->G#)',
  'F (C->G)',
  'F# (C->F#)',
  'G (C->F)',
  'G# (C->E)',
  'A (C->D#)',
  'A# (C->D)',
  'B (C->C#)',
];

export enum PollResults {
  gotResult = 0, // 0 (is also a ms timestamp)
  note = 1, // 1
  freqHz = 2, // 2
  centsError = 3, // 3
  octave = 4, // 4
  perfectFreq = 5, // 5
  weighting = 6, // 6
  iirLockFreq = 7, // 7
  dummy1 = 8, // 8
  dummy2 = 9, // 9
  gotSigEvt = 10, // 10 (is also a ms timestamp)
  dBFSSignal = 11, // 11
  dbFSThreshold = 12, // 12
  dbFSNoiseFloor = 13, // 13
  analysisActive = 14, // 14
  strobesActive = 15, // 15
  strobe0_amp = 16, // 16
  strobe0_position = 17, // 17
  strobe0_velocity = 18, // 18
  strobe0_lockFreq = 19, // 19
  strobe0_centsError = 20, // 20
  strobe1_amp = 21, // 21
  strobe1_position = 22, // 22
  strobe1_velocity = 23, // 23
  strobe1_lockFreq = 24, // 24
  strobe1_centsError = 25, // 25
  strobe2_amp = 26, // 26
  strobe2_position = 27, // 27
  strobe2_velocity = 28, // 28
  strobe2_lockFreq = 29, // 29
  strobe2_centsError = 30, // 30
  strobe3_amp = 31, // 31
  strobe3_position = 32, // 32
  strobe3_velocity = 33, // 33
  strobe3_lockFreq = 34, // 34
  strobe3_centsError = 35, // 35
  strobe4_amp = 36, // 36
  strobe4_position = 37, // 37
  strobe4_velocity = 38, // 38
  strobe4_lockFreq = 39, // 39
  strobe4_centsError = 40, // 40
  strobe5_amp = 41, // 41
  strobe5_position = 42, // 42
  strobe5_velocity = 43, // 43
  strobe5_lockFreq = 44, // 44
  strobe5_centsError = 45, // 45
  strobe6_amp = 46, // 46
  strobe6_position = 47, // 47
  strobe6_velocity = 48, // 48
  strobe6_lockFreq = 49, // 49
  strobe6_centsError = 50, // 50
}

/*
  export function NormalNoteToDatunerNote(
    normalNoteIdx: number,
    normalNoteOctave: number,
    offset: number = DatunerNotes.E_NOTE_C,
  ) {
    let n = normalNoteIdx + offset;
    let o = normalNoteOctave;
    if (n >= 12) {
      n = n - 12;
      o += 1;
    }
    return { n: n, o: o };
  }

  export function DatunerNoteToNormalNote(
    motunerNote: number,
    motunerOct: number,
    offset: number = DatunerNotes.E_NOTE_C,
  ) {
    let n = motunerNote + NormalNotes.E_NOTE_A;
    let o = motunerOct - 1;
    if (n >= 12) {
      n = n - 12;
      o += 1;
    }
    return { n: n, o: o };
  }
  */

export function NormalNoteToDatunerNote(
  note: number,
  octave: number,

  scaleOffset: number = DatunerNotes.E_NOTE_C,
) {
  let n = (Math.round(note) % 12) + scaleOffset;
  let o = octave - 1;
  if (n >= 12) {
    n = n - 12;
    o += 1;
  }
  return {n, o};
}

// Convert MoTuner note (with offset at note A) to another scale (example, 0 index at C)
export function DatunerNoteToNormalNote(
  note: number,
  octave: number,
  scaleOffset: number = DatunerNotes.E_NOTE_C,
) {
  // If A..scaleOffset-1, then decrease octave by 1
  let n = (Math.round(note) % 12) - scaleOffset;
  let o = octave + 1;
  if (n < 0) {
    o--;
    n += 12;
  }
  return {n, o};
}

// Length of a StrobeEvt
export const StrobeEvtLen: number =
  PollResults.strobe0_centsError - PollResults.strobe0_amp + 1;

export interface StrobeEvt {
  amp: number; // 0..1, amplitude (brightness) of the strobe.
  position: number; // 0..1, corresponding to the most recent phase of the strobe.
  velocity: number; // Current change in position per sample.
  lockFreq: number; // Current lock frequency for this strobe.
  centsError: number; // Get the cents error vs the locked frequency.
}

export const StrobeEvtDefault: StrobeEvt = {
  amp: 0,
  position: 0,
  velocity: 0,
  lockFreq: 0,
  centsError: 0,
};

export interface ThreshEvt {
  analysisActive: boolean;
  dBFSSignal: number;
  dbFSThreshold: number;
  dbFSNoiseFloor: number;
  strobesActive: number;
  strobeAry: StrobeEvt[];
}

export const ThreshEvtDefault: ThreshEvt = {
  analysisActive: false,
  dBFSSignal: -100,
  dbFSThreshold: -100,
  dbFSNoiseFloor: -100,
  strobesActive: 0,
  strobeAry: new Array<StrobeEvt>(0),
};
export interface NoteEvt {
  note: string;
  octave: number;
  frequency: number;
  centsError: number;
  iirLockFreq: number;
}

export const NoteEvtDefault: NoteEvt = {
  note: '',
  octave: 0,
  frequency: 440,
  centsError: 0,
  iirLockFreq: 0,
};
