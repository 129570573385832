import React from 'react';
import {Dialog, DialogTitle} from '@material-ui/core';

import {ParamKnob, KnobParamRange} from './param_knob';
import '../../../../App.css';
export interface KnobScreenProps {
  title: string;
  isOpen: boolean;
  left: number; // x when visible
  width: number; // height when visible
  top: number; // top when visible
  height: number; // height when visible
  onClose?: (value: number) => void;
  val: KnobParamRange;
}

export function KnobScreen(props: KnobScreenProps) {
  /* Only show the children if open or animating */
  return props.isOpen ? (
    <Dialog open={props.isOpen} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
      <ParamKnob
        onClose={(val: number) => {
          if (props.onClose) {
            props.onClose(val);
          }
        }}
        val={props.val}
      />
      <div className="Small" style={{flexDirection: 'row', flex: 0.1}}></div>
    </Dialog>
  ) : (
    <></>
  );
}
