import { dbg } from './sharedSrc/src/debug/debug';

export type AudioChunkerCallback = (pArr: Float32Array) => void;
export class AudioChunker {
  outputArray: Float32Array;
  inputIndex: number = 0;
  outputIndex: number = 0;
  decimation = 1;

  callback: AudioChunkerCallback = (pArr: Float32Array) => {
    dbg.log('Placholder');
  };

  constructor(
    outputBufSize: number = 256,
    decimationFactor: number = 1,
    callback?: AudioChunkerCallback,
  ) {
    this.outputArray = new Float32Array(outputBufSize).fill(0);
    this.decimation = decimationFactor <= 1 ? 1 : decimationFactor;
    this.callback = callback ? callback : this.callback;
    this.reset();
  }

  reInit(
    outputBufSize: number = 256,
    decimationFactor: number = 1,
    callback?: AudioChunkerCallback,
  ) {
    this.outputArray = new Float32Array(outputBufSize).fill(0);
    this.decimation = decimationFactor <= 1 ? 1 : decimationFactor;
    this.callback = callback ? callback : this.callback;
    this.reset();
  }

  reset() {
    this.inputIndex = 0;
    this.outputIndex = 0;
  }

  chunkData(dataToDecimate: Float32Array) {
    let done = false;

    do {
      while (
        this.inputIndex < dataToDecimate.length &&
        this.outputIndex < this.outputArray.length
      ) {
        this.outputArray[this.outputIndex++] = dataToDecimate[this.inputIndex];
        this.inputIndex += this.decimation;
      }
      if (this.outputIndex >= this.outputArray.length) {
        this.callback(this.outputArray);
        this.outputIndex -= this.outputArray.length;
      }
      if (this.inputIndex >= dataToDecimate.length) {
        this.inputIndex -= dataToDecimate.length;
        done = true;
      }
    } while (!done);
  }
}
