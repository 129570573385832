import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export interface IconButtonProps {
  icon: any;
  label: any;
  className?: any;
  onClick: () => void;
}

export default function IconButtonWithLabel(props: IconButtonProps) {
  const classes = useStyles();
  const { ...other } = props;

  return (
    <IconButton classes={{ label: classes.iconButtonLabel }} {...other}>
      {props.icon}
      {props.label}
    </IconButton>
  );
}
