import { BottomNavigationAction } from '@material-ui/core';
import React from 'react';

export interface BottomNavigationActionWithDisableProps {
  disabled?: boolean;
  label: string;
  icon?: any;
}

export const BottomNavigationActionWithDisable = (
  props: BottomNavigationActionWithDisableProps,
) => {
  return (
    <BottomNavigationAction
      {...props}
      disabled={!!props.disabled}
      label={!props.disabled ? props.label : undefined}
      icon={!props.disabled ? props.icon : undefined}
    ></BottomNavigationAction>
  );
};
