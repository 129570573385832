import React, { useEffect, useState, useCallback, useRef, MutableRefObject } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import * as Midi from '../sharedSrc/src/headers/midi';
import { ListItem, Dialog, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  NoteElement,
  SubInstrumentElement,
  TuningElement,
  ElementBaseHelper,
  InstrumentAsMap,
  copyTuning,
  stringifyTuning,
  findSubInstrument,
  findTuning,
} from '../sharedSrc/src/instruments/instrument_defs';
import { findInstrumentInDefaultMapping } from '../sharedSrc/src/instruments/note_mapping';

import { EditNoteDialog } from './edit_note';
import { dbg, isDebug } from '../sharedSrc/src/debug/debug';
import { RootState } from '@abstractions/redux_inst';
import { MySnackbar } from '@abstractions/use_snackbar_help';
import { deepEqual } from '../sharedSrc/src/utils/utils';
import { MIN_WINDOW_WIDTH, useComponentSize, useWindowSize } from '../hooks/use_window_size';
import { TuningEditorBottomRow } from './tuning_editor_bottom_row';
import { TuningEditorNoteJsx, TuningInfo } from './tuning_editor_note_jsx';
import { MyDialogTitle } from '../components/my_dialog_title';
import assert from 'assert';
import { useTuningNameChooser } from './choose_new_tuning_name';
import { Assignment } from '@material-ui/icons';
import IconButtonWithLabel from '../components/icon_button_with_label';
import '../App.css';
const NOTE_LIST_ITEM_HEIGHT = 60;
const NOTE_LIST_HEIGHT_SHRINK = 320;
const NOTE_LIST_HEIGHT_SHRINK_MIN = 200;
/*
//-----------------------------------------------------------------------------
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    placeholder: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      zIndex: -10,
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  });
});
*/

export interface EditTuningProps {
  chosenTuning: TuningElement;
  onTuningUpdated: (updatedTuning?: TuningElement) => void;
  onDeleteTuningClick: (deleteTuning: TuningElement) => void;
  onTuningAdded: (tuning: null | TuningElement) => void;
}

export interface EditTuningPropsInner extends EditTuningProps {
  onLocallyEdited: (tuning: TuningElement, isLocallyModified: boolean) => void;
}

/**
 *    +----                      ---------
 *    -----                      o -------
 *      +----                    o -------
 *      +----                    o -------
 */
function TuningEditorInner(props: EditTuningPropsInner) {
  // const classes = useStyles();
  const [tuningCopy, setTuningCopy] = useState<TuningElement | null>(null);
  const [tuningBackup, setTuningBackup] = useState<TuningElement | null>(null);
  const [restoreFromBackup, setRestoreFromBackup] = useState(false);
  const [incomingTuning, setIncomingTuning] = useState<TuningElement | null>(null);
  const [handleIncomingTuning, setHandleIncomingTuning] = useState(false);
  const [noteBeingEdited, setNoteBeingEdited] = useState<{
    note: NoteElement;
    isNewNote: boolean;
  } | null>(null);
  const [tuningParent, setTuningParent] = useState<SubInstrumentElement | undefined>(undefined);
  const [isDirty, setIsDirty] = useState(false);
  const [currentHelpString, setCurrentHelpString] = useState('');
  const [choosingSaveAsName, setChoosingSaveAsName] = useState(false);

  useEffect(() => {
    // Run this any time props change
    const t = !!props.chosenTuning ? props.chosenTuning : null;
    setIncomingTuning(t);
    setHandleIncomingTuning(true);
  }, [props]);

  useEffect(() => {
    // The flag has been set indicating change of props, see if anything needs to be done.
    if (handleIncomingTuning) {
      setHandleIncomingTuning(false);
      if (incomingTuning) {
        if (!deepEqual(incomingTuning, tuningBackup, 1)) {
          setTuningParent(incomingTuning.parent as undefined | SubInstrumentElement);
          const cp = copyTuning(
            incomingTuning,
            incomingTuning.parent as undefined | SubInstrumentElement,
            false,
          );

          setTuningBackup(cp);
          setRestoreFromBackup(true);
        }
      } else {
        setTuningParent(undefined);
        setTuningCopy(null);
      }
    }
  }, [handleIncomingTuning, incomingTuning, tuningBackup]);

  useEffect(() => {
    // Restores the tuning copy from backup
    if (!!tuningBackup && restoreFromBackup) {
      setRestoreFromBackup(false);
      setTuningCopy(copyTuning(tuningBackup));
      setIsDirty(false);
      props.onLocallyEdited(tuningBackup, false);
    }
  }, [restoreFromBackup, tuningBackup, props]);

  const dimensions = useWindowSize();

  dbg.log('rerendering TuningEditor()');

  const onMouseOverEdit = useCallback((element: NoteElement) => {
    setCurrentHelpString('Edit note ' + element.name);
  }, []);

  const onMouseOverDelete = useCallback((element: NoteElement) => {
    setCurrentHelpString('Delete note ' + element.name);
  }, []);

  const clearTheHelpString = useCallback(() => {
    setCurrentHelpString('');
  }, []);

  const onDeleteNoteClick = useCallback(
    (n: NoteElement) => {
      if (!!tuningCopy) {
        const cp = { ...tuningCopy };
        InstrumentAsMap.delete(cp, n.name);
        setTuningCopy(cp);

        setIsDirty(true);
        props.onLocallyEdited(tuningCopy, true);
      }
    },
    [tuningCopy, props],
  );

  const renderRow = useCallback(
    (_props: ListChildComponentProps) => {
      // Render one row of the tuning
      const { index, style } = _props;
      const dummyJsx = <ListItem key={-1} style={style} button />;

      if (!tuningCopy) {
        return dummyJsx;
      } else if (index < 0 || index >= tuningCopy.noteElement.length) {
        return dummyJsx;
      } else {
        const noteElement = tuningCopy.noteElement[index];
        const c = Midi.ParseNote(noteElement.name);
        const tweak = noteElement.cents ? parseFloat(noteElement.cents) : 0;
        const freq = c.invalidOctave ? 0 : Midi.NoteAndOctaveGetFreqFromNoteAndError(c, tweak, 440);
        const info: TuningInfo = {
          element: noteElement,
          noteAndOctave: c,
          cents: tweak,
          freq,
        };

        return (
          <TuningEditorNoteJsx
            style={style}
            key={info.element.name}
            thekey={info.element.name}
            onEditNoteClick={(_element: NoteElement) => {
              setNoteBeingEdited({ note: _element, isNewNote: false });
            }}
            onDeleteNoteClick={onDeleteNoteClick}
            onMouseOverEdit={onMouseOverEdit}
            onMouseOverDelete={onMouseOverDelete}
            onMouseOut={clearTheHelpString}
            info={info}
          ></TuningEditorNoteJsx>
        );
      }
    },
    [tuningCopy, onDeleteNoteClick, onMouseOverDelete, onMouseOverEdit, clearTheHelpString],
  );

  // Get reference frequency from state
  const refFreq = useSelector((state: RootState) => {
    return state.system.referenceFreq;
  });

  const dialogOuterContainerRef: MutableRefObject<any> = useRef();
  const containerSize = useComponentSize(dialogOuterContainerRef);
  const windowSize = useWindowSize();

  // Called when the note editor has been called.
  // This will set tuning updated, which will pass the new tuning back
  // to the main function.
  const onEditNoteCompleted = (noteElement?: NoteElement) => {
    const originalNoteBeingEdited = noteBeingEdited;

    // setChoosingInstrument(false);
    setNoteBeingEdited(null);
    if (!!noteElement && !!tuningCopy) {
      if (!!originalNoteBeingEdited) {
        // m.deleteChild(originalNoteBeingEdited.note.name);
        InstrumentAsMap.set(tuningCopy, noteElement, true, originalNoteBeingEdited.note.name);
      } else {
        InstrumentAsMap.set(tuningCopy, noteElement, true);
      }

      // copyNoteElement(noteElement, tuningCopy, true);
      setIsDirty(true);
      props.onLocallyEdited(tuningCopy, true);
    }
  };

  const heightShrink =
    windowSize.height < 600 ? NOTE_LIST_HEIGHT_SHRINK_MIN : NOTE_LIST_HEIGHT_SHRINK;

  const onApplyChanges = () => {
    const t = tuningCopy ? tuningCopy : undefined;

    if (!!t && isDirty) {
      t.userModified = true;
      props.onLocallyEdited(t, true);
    }
    props.onTuningUpdated(t);
  };

  const onAddNewNote = useCallback(() => {
    // Todo, add a note to the end of the list, then edit it.
    if (tuningCopy) {
      let c: Midi.NoteAndOctave = { note: 0, octave: 2 };
      if (tuningCopy.noteElement.length > 0) {
        const lastNote = tuningCopy.noteElement[tuningCopy.noteElement.length - 1];
        c = Midi.ParseNote(lastNote.name);
      }
      if (!c.invalidOctave) {
        c.note = (c.note + 1) % 12;
        c.octave = c.note === 0 ? c.octave + 1 : c.octave;
        tuningCopy.userModified = true;
        const n: NoteElement = {
          parent: tuningCopy,
          name: Midi.NoteIdxToString(c.note, c.octave),
        };
        tuningCopy.noteElement.push(n);
        setIsDirty(true);
        setNoteBeingEdited({ note: n, isNewNote: true });
      }
    }
  }, [tuningCopy]);

  const onResetToDefault = useCallback(() => {
    if (!!tuningCopy && tuningCopy.isDefaultTuning && !!tuningParent) {
      // Get the default tuning and restore fully.
      const s = new ElementBaseHelper(tuningParent);
      const tname = tuningCopy.name;
      const sname = s.getName();
      const iname = s.getParentName();
      if (tname.length && sname.length && iname.length) {
        const di = findInstrumentInDefaultMapping(iname);
        const ds = findSubInstrument(di, sname);
        const dt = findTuning(ds, tname);

        if (!!dt) {
          dt.isDefaultTuning = true;
          assert(!!!dt.userModified);
          setTuningBackup(copyTuning(dt));
          assert(dt.isDefaultTuning);
        }
      }
    }

    setRestoreFromBackup(true);
  }, [tuningCopy, tuningParent]);

  const onDeleteThisTuning = useCallback(() => {
    if (!!tuningCopy && !!tuningParent) {
      InstrumentAsMap.set(tuningParent, tuningCopy, true);
      props.onDeleteTuningClick(tuningCopy);
      // setPendingtuningCopy(null);
    }
  }, [tuningCopy, tuningParent, props]);

  const onSaveAsClick = () => {
    setChoosingSaveAsName(true);
  };

  const onChoseNewName = (tuning: null | TuningElement) => {
    setChoosingSaveAsName(false);
    if (!!tuning && !!tuningCopy && !!tuningParent) {
      const oldName = tuningCopy.name;
      tuningCopy.name = tuning.name;
      tuningCopy.userModified = true;
      const subInstrument = tuning.parent;
      if (!!subInstrument) {
        InstrumentAsMap.set(subInstrument, tuningCopy, true, oldName);
      }
      props.onTuningAdded(tuningCopy);
    }
  };

  const tuningNameChooser = useTuningNameChooser(choosingSaveAsName, onChoseNewName);

  return (
    <div style={{ width: '100%' }} ref={dialogOuterContainerRef}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: (containerSize.width - MIN_WINDOW_WIDTH) / 2 }}></div>
        <FixedSizeList
          height={dimensions.height - heightShrink}
          itemCount={tuningCopy ? tuningCopy.noteElement.length : 0}
          itemSize={NOTE_LIST_ITEM_HEIGHT}
          width={MIN_WINDOW_WIDTH}
        >
          {renderRow}
        </FixedSizeList>
      </div>

      <TuningEditorBottomRow
        onApplyChanges={onApplyChanges}
        onRevertTuning={onResetToDefault}
        onDeleteTuningClick={onDeleteThisTuning}
        onAddNoteClick={onAddNewNote}
        onSaveAsClick={onSaveAsClick}
        forceShowApplyButton={true}
        isDirty={isDirty}
        isDefaultTuning={tuningCopy?.isDefaultTuning}
        userModified={tuningCopy?.userModified}
      ></TuningEditorBottomRow>

      {noteBeingEdited && (
        <EditNoteDialog
          element={noteBeingEdited.note}
          open={noteBeingEdited !== null}
          refFreq={refFreq}
          forceShowApplyButton={true}
          onClose={onEditNoteCompleted}
        />
      )}
      <MySnackbar help={currentHelpString} onClose={clearTheHelpString}></MySnackbar>
      {tuningNameChooser.jsx}
    </div>
  );
}

const tuningEditorStyles = makeStyles((theme: Theme) => {
  return createStyles({
    sharedUrl: {
      margin: 0,
      padding: 0,
    },
    sharedUrlBorder: {
      padding: theme.spacing(2),
    },
    buttons: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      flexDirection: 'row',
    },
    button: {
      color: theme.palette.grey[500],
    },
  });
});

export function TuningEditor(props: EditTuningProps) {
  const classes = tuningEditorStyles();
  const [isLocallyModified, setIsLocallyModified] = useState(false);
  const [incomingTuning, setIncomingTuning] = useState<null | TuningElement>(null);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [sharingLink, setSharingLink] = useState('');
  const [snackbarText, setSnackbarText] = useState('');

  const onLocallyEdited = (tuning: TuningElement, modified: boolean) => {
    setIsLocallyModified(modified);
    setIncomingTuning({ ...tuning });
  };

  useEffect(() => {
    if (!!props.chosenTuning) {
      const e = new ElementBaseHelper(props.chosenTuning);
      const t = e.getName();
      const s = e.getParentName();
      const i = e.getGrandParentName();
      setTitle(i + '/' + s);
      setSubtitle(t);
    }
  }, [props.chosenTuning]);

  const isShareable = isLocallyModified || !!props.chosenTuning?.userModified;

  const onShareThisTuning = useCallback(() => {
    if (isShareable && !!incomingTuning) {
      const str = stringifyTuning(incomingTuning);
      const url = encodeURIComponent(str);
      const domain =
        isDebug && 'localhost' === window.location.hostname
          ? 'https://localhost:3000'
          : 'https://strobopro.se';
      const link = domain + '/share/urlified_' + url; // + '&text=' + encodeURIComponent('Share this instrument');
      setSharingLink(link);
    }
  }, [isShareable, incomingTuning]);

  const copyToClipboardTextRef: MutableRefObject<any> = useRef();

  const doCopyToClipboard = useCallback(() => {
    if (!!copyToClipboardTextRef.current) {
      copyToClipboardTextRef.current.select();
      document.execCommand('copy');
      setSnackbarText('Copied ' + title + '/' + subtitle + ' to clipboard');
    }
  }, [copyToClipboardTextRef, subtitle, title]);

  return sharingLink.length > 0 ? (
    <Dialog open={true} fullWidth={true} maxWidth={'xs'}>
      <MyDialogTitle
        title={'Share ' + title + '/' + subtitle}
        onClose={() => {
          setSharingLink('');
        }}
        id='simple-dialog-title'
      />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div className={classes.sharedUrlBorder} style={{ flex: 1 }}>
          <textarea
            defaultValue={sharingLink}
            className={classes.sharedUrl}
            style={{ width: '100%', height: 100 }}
            ref={copyToClipboardTextRef}
          ></textarea>
        </div>
        <IconButtonWithLabel
          className={classes.button}
          aria-label='copy'
          onClick={doCopyToClipboard}
          icon={<Assignment />}
          label={<Typography variant='caption'>Copy to clipboard</Typography>}
        ></IconButtonWithLabel>
      </div>
      <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
        Copy the link and send it to a friend ❤️
      </div>
      <MySnackbar
        onClose={() => {
          setSnackbarText('');
        }}
        help={snackbarText}
      ></MySnackbar>
    </Dialog>
  ) : (
    <Dialog open={true} fullWidth={true} maxWidth={'xs'}>
      <MyDialogTitle
        title={title + ':'}
        subtitle={subtitle}
        onShare={!!isShareable ? onShareThisTuning : undefined}
        onClose={() => {
          props.onTuningUpdated();
        }}
        id='simple-dialog-title'
      />
      <TuningEditorInner {...props} onLocallyEdited={onLocallyEdited} />
    </Dialog>
  );
}
