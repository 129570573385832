import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { dbg } from '../sharedSrc/src/debug/debug';
import { AudioState, AudioStateHolder } from '../sharedSrc/src/redux/redux';
import { useSpring } from 'react-spring/web';
export interface VuMeterProps {
  top: number;
  left: number;
  width: number;
  height: number;
}

const MINDB = -96;

const getY = (propsHeight: number, dB: number) => {
  let heightRatio = 1 - dB / MINDB;
  heightRatio = Math.min(1, heightRatio);
  heightRatio = Math.max(0, heightRatio);
  const height = propsHeight * heightRatio;
  return { height, top: propsHeight - height };
};

export const VuMeter = (props: VuMeterProps) => {
  const ref: MutableRefObject<any> = useRef();
  const [sigDb, setSigDb] = useState(MINDB);
  const [threshDb, setThreshDb] = useState(MINDB);

  const [barYDims, setBarYDims] = useState({ height: 0, top: props.top });
  const [threshYDims, setThreshYDims] = useState({ height: 0, top: props.top });

  const ThreshEvtCallback = useCallback((_timestampMs: number, state: AudioState) => {
    const dBFSSignal = Math.round(state.threshEvt.dBFSSignal);
    const dbFSThreshold = Math.round(state.threshEvt.dbFSThreshold);

    setSigDb(dBFSSignal);
    setThreshDb(dbFSThreshold);

  }, []);

  useEffect(() => {
    AudioStateHolder.inst().callbacks.subscribe(ThreshEvtCallback);
    dbg.log('Remounting VuMeter::ThreshEvtCallback');
    return () => {
      AudioStateHolder.inst().callbacks.unsubscribe(ThreshEvtCallback);
    };
  }, [ThreshEvtCallback]);

  useEffect(() => {
    const barY = getY(props.height, sigDb);
    if (barY.height !== barYDims.height || barY.top !== barYDims.top) {
      setBarYDims(barY);
    }
    const threshY = getY(props.height, threshDb);
    if (threshY.height !== threshYDims.height || threshY.top !== threshYDims.top) {
      setThreshYDims(threshY);
    }
  }, [
    props.height,
    sigDb,
    threshDb,
    barYDims.height,
    barYDims.top,
    threshYDims.top,
    threshYDims.height,
  ]);

  const animatedValues = useSpring({
    barYTop: barYDims.top,
    barYHeight: barYDims.height,
    threshYTop: threshYDims.top,
  });

  useEffect(() => {
    if (ref.current) {
      const ctx: CanvasRenderingContext2D = ref.current.getContext('2d');

      // Draw stuff here.
      const grd = ctx.createLinearGradient(props.left, props.top, props.width - 12, props.height);
      grd.addColorStop(0, 'red');
      grd.addColorStop(1, 'orange');

      // Fill with black
      ctx.fillStyle = 'black';
      ctx.fillRect(props.left, props.top, props.width, props.height);

      // Fill with gradient
      ctx.fillStyle = grd;
      ctx.fillRect(
        props.left,
        props.top + animatedValues.barYTop.get(),
        props.width,
        animatedValues.barYHeight.get(),
      );

      // Fill with white
      ctx.fillStyle = 'white';
      // ctx.strokeStyle = 'white';
      // ctx.lineWidth = 1;
      const topY = animatedValues.threshYTop.get();
      ctx.fillRect(props.left, props.top + topY, props.width, 1);
    }
  }, [props, animatedValues]);

  return <canvas ref={ref} width={props.width} height={props.height}></canvas>;
};
