// This file represents the internal mapping of instruments after parsing.
import * as Midi from '../headers/midi';
import {DefaultInstrumentsArray} from './default_instruments';

import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {
  ActionNames,
  AddInstrumentAction,
  Dispatch,
  getReferenceFreq,
  InfoNames,
  LockToNoteAction,
  ReduxActions,
  SetCurrentInstrumentAction,
  SetTuningAction,
} from '../redux/redux';
import {StroboProCpp} from '@abstractions/wrapper/js_wrapper';
import {Store} from '@abstractions/redux_inst';

import {getItemInMemories, TingsToStore} from '../async_storage';
import {dbg} from '../debug/debug';

import {
  copyInstrument,
  deSerializeTuning,
  ElementBase,
  getChildren,
  InstrumentElement,
  InstrumentGeneric,
  NoteElement,
  recursiveAddParents,
  serializeTuning,
  setDefaultFlags,
  SubInstrumentElement,
  TuningElement,
} from './instrument_defs';
import {arraysEqual} from '../utils/utils';

import {NormalNoteToDatunerNote} from '../headers/note_defs';

const TARGET_DECIMATION = 4;
const TARGET_MOTUNER_FS = 48000 / TARGET_DECIMATION;

export const NoteSymbol = {
  C: Midi.SHARP_NOTE_NAMES[0],
  Cs: Midi.SHARP_NOTE_NAMES[1],
  D: Midi.SHARP_NOTE_NAMES[2],
  Ds: Midi.SHARP_NOTE_NAMES[3],
  E: Midi.SHARP_NOTE_NAMES[4],
  F: Midi.SHARP_NOTE_NAMES[5],
  Fs: Midi.SHARP_NOTE_NAMES[6],
  G: Midi.SHARP_NOTE_NAMES[7],
  Gs: Midi.SHARP_NOTE_NAMES[8],
  A: Midi.SHARP_NOTE_NAMES[9],
  As: Midi.SHARP_NOTE_NAMES[10],
  B: Midi.SHARP_NOTE_NAMES[11],
};

export const NoteSymbolFlat = {
  C: Midi.FLAT_NOTE_NAMES[0],
  Cs: Midi.FLAT_NOTE_NAMES[1],
  D: Midi.FLAT_NOTE_NAMES[2],
  Ds: Midi.FLAT_NOTE_NAMES[3],
  E: Midi.FLAT_NOTE_NAMES[4],
  F: Midi.FLAT_NOTE_NAMES[5],
  Fs: Midi.FLAT_NOTE_NAMES[6],
  G: Midi.FLAT_NOTE_NAMES[7],
  Gs: Midi.FLAT_NOTE_NAMES[8],
  A: Midi.FLAT_NOTE_NAMES[9],
  As: Midi.FLAT_NOTE_NAMES[10],
  B: Midi.FLAT_NOTE_NAMES[11],
};

type NoteToFreqRel440Info = {
  note: number;
  octave: number;
  freqRel440: number;
  freq: number;
};

export function NoteToFreqRel440(
  noteElement: NoteElement,
): NoteToFreqRel440Info[] {
  const rval: NoteToFreqRel440Info[] = [];
  const currentTemperaments = new Array(12).fill(0);
  if (noteElement.frequency && noteElement.frequency.length > 0) {
    // If a frequency is specified, do NOT apply temperament
    const freq = parseFloat(noteElement.frequency);
    const note = Midi.GetNearestNoteAndError(freq);
    rval.push({
      note: note.note,
      octave: note.octave,
      freqRel440: freq / 440.0,
      freq,
    });
  } else if (noteElement.name && noteElement.name.length > 0) {
    // Else apply temperament based on the note symbol
    const c = Midi.ParseNote(noteElement.name);
    const tweakCents1 = currentTemperaments[c.note % 12];
    const tweakCents2 = noteElement.cents ? parseFloat(noteElement.cents) : 0;
    const tweak = tweakCents1 + tweakCents2;

    if (c.invalidOctave) {
      // Handle temperaments (no octave specified)
      for (let oct = 0; oct <= 8; oct++) {
        const c2 = {...c, octave: oct};
        const freq = Midi.NoteAndOctaveGetFreqFromNoteAndError(c2, tweak);
        if (freq !== undefined) {
          const newMapping: NoteToFreqRel440Info = {
            note: c2.note,
            octave: c2.octave,
            freqRel440: freq / 440.0,
            freq,
          };

          rval.push(newMapping);
        } else {
          console.warn('Invalid mapping:', c2);
        }
      }
    } else {
      // Handle a single note.
      const freq = Midi.NoteAndOctaveGetFreqFromNoteAndError(c, tweak);
      rval.push({
        note: c.note,
        octave: c.octave,
        freqRel440: freq / 440.0,
        freq,
      });
    }
  }
  return rval;
}

let __inst: NoteMappings | null = null;
export class NoteMappings {
  // SIngleton getter.
  static inst(): NoteMappings {
    if (null === __inst) {
      __inst = new NoteMappings();
    }
    return __inst;
  }

  // Helper function fetches the store and then does something for all instruments.
  forAllTuningElements(
    cb: (
      instrument: InstrumentElement,
      subinstrument: SubInstrumentElement,
      tuning: TuningElement,
    ) => void,
  ) {
    const store = Store.inst().store;
    const state = store.getState();
    state.system.allInstrumentsMap.forEach((instrument: InstrumentElement) => {
      instrument.subInstrument.forEach(
        (subinstrument: SubInstrumentElement) => {
          subinstrument.tuning.forEach((tuning: TuningElement) => {
            cb(instrument, subinstrument, tuning);
          });
        },
      );
    });
  }

  // Helper function fetches the store and then does something for all instruments.
  forAllTunings(
    cb: (instrument: string, subinstrument: string, tuning: string) => void,
  ) {
    const myCb = (
      instrument: InstrumentElement,
      subinstrument: SubInstrumentElement,
      tuning: TuningElement,
    ) => {
      cb(instrument.name, subinstrument.name, tuning.name);
    };

    this.forAllTuningElements(myCb);
  }

  getInstruments(): InstrumentElement[] {
    const rval: InstrumentElement[] = [];
    const store = Store.inst().store;
    const state = store.getState();

    state.system.allInstrumentsMap.forEach((instrument: InstrumentElement) => {
      rval.push(instrument);
    });
    return rval;
  }

  serializeModifiedTunings(): TuningElement[] {
    const jsonArr: TuningElement[] = [];
    // Remap the entire instruments tree but using only changed instruments.
    this.forAllTuningElements(
      (
        instrument: InstrumentElement,
        subinstrument: SubInstrumentElement,
        tuning: TuningElement,
      ) => {
        // Has the tuning been modified?
        if (!!tuning.userModified) {
          jsonArr.push(serializeTuning(instrument, subinstrument, tuning));
        }
      },
    );

    return jsonArr;
  }

  deSerializeModifiedTunings(jsonArr: TuningElement[]): InstrumentElement[] {
    const rval: InstrumentElement[] = [];
    jsonArr.forEach((reversedTuning: TuningElement) => {
      const instrument = deSerializeTuning(reversedTuning);
      if (!!instrument && instrument.name.length > 0) {
        rval.push(instrument);
      }
    });
    return rval;
  }

  // Simply calls a function.
  hi() {
    dbg.log('note_mapping::hello there');
  }

  load() {
    dbg.log('note_mapping::no, you load');
  }
}

export function forEachDefaultInstrument(
  cb: (inst: InstrumentElement) => void,
) {
  DefaultInstrumentsArray.forEach((inst: InstrumentElement) => {
    cb(inst);
  });
}

export function forEachSubInstrument(
  inst: InstrumentElement,
  cb: (s: SubInstrumentElement) => void,
) {
  inst.subInstrument.forEach((s: SubInstrumentElement) => {
    cb(s);
  });
}

export function forEachTuning(
  s: SubInstrumentElement,
  cb: (t: TuningElement) => void,
) {
  s.tuning.forEach((t: TuningElement) => {
    cb(t);
  });
}

export function forEachNote(t: TuningElement, cb: (n: NoteElement) => void) {
  t.noteElement.forEach((n: NoteElement) => {
    cb(n);
  });
}

export function findInstrumentInDefaultMapping(
  name: string,
): null | InstrumentElement {
  if (!name || name.length === 0) return null;
  const element: undefined | InstrumentElement = DefaultInstrumentsArray.find(
    (value: ElementBase) => {
      return value.name === name;
    },
  );
  return element ? element : null;
}

function doSomethingAfterAppInit(args: any[]): string {
  // Load from xml.
  // setTimeout(() => {
  if (true) {
    const store = Store.inst().store;

    forEachDefaultInstrument((inst: InstrumentElement) => {
      dbg.log(
        'Adding instrument ' +
          inst.name +
          ' with ' +
          inst.subInstrument.length +
          ' different types.',
      );
      const c = copyInstrument(inst);
      store.dispatch(Dispatch.addInstrument(c, true));
    });

    store.dispatch(Dispatch.info(InfoNames.DefaultInstrumentsLoadCompleted));
    // }, 500);
  }

  setTimeout(() => {
    getItemInMemories(TingsToStore.ReferenceFrequency).then(
      (s: null | string) => {
        getItemInMemories(TingsToStore.Transposition).then(
          (t: null | string) => {
            let refFreq = 440;
            let transposition = 0;
            if (s) {
              refFreq = parseFloat(s);
            }
            if (t) {
              transposition = Math.round(parseFloat(t));
            }
            StroboProCpp.inst()._tunerPromise?.then(() => {
              try {
                Store.inst().store.dispatch(Dispatch.setRefFreq(refFreq));
                Store.inst().store.dispatch(
                  Dispatch.setTransposition(transposition),
                );
              } catch (e: any) {
                dbg.log(
                  'Got error when dispatching ',
                  JSON.stringify(e, null, 2),
                );
              }
            });
          },
        );
      },
    );
  }, 1000);
  NoteMappings.inst().load();
  return 'didSomethingAfterAppInit';
}

function* onAppInit(actionAny: any, ..._otherArgs: any[]) {
  yield call(doSomethingAfterAppInit, actionAny);
}

function* onAppInitEffect() {
  yield takeEvery(ActionNames.InitAction, onAppInit);
  yield takeEvery(ActionNames.ResetInstrumentsAction, onAppInit);
}

// -----------------------------------------------------------------------------
// START Filters to run BEFORE saving state.
// -----------------------------------------------------------------------------
export function NoteMappingMiddleWare(_store: any) {
  return (next: any) => {
    return (actionAny: any /*ReduxActions*/) => {
      const action: ReduxActions = actionAny;
      switch (action.type) {
        case 'AddInstrumentAction': {
          // Intercept the instrument being added and make it searchable from child to parent.
          const addInstrument: AddInstrumentAction = actionAny;
          const instrumentAny: any = addInstrument.instrument;
          recursiveAddParents(instrumentAny);
          if (addInstrument.isDefaultInstrument) {
            setDefaultFlags(addInstrument.instrument);
          }
          break;
        }
        default:
          break;
      }

      // continue processing this action
      return next(action);
    };
  };
}
// -----------------------------------------------------------------------------
// END Filters to run BEFORE saving state.
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// START Filters to run AFTER saving state.
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// SetInstrument -----
function* onSetInstrumentUpdate(_actionAny: any) {
  const action: SetCurrentInstrumentAction = _actionAny;

  // TODO: Fetch and update the mapping in the native layer.
  dbg.log('Here is where we update the frequency mapping.');

  const store = Store.inst().store;
  const state = store.getState();

  const instrument = state.system.allInstrumentsMap.get(action.instrumentName);
  if (instrument) {
    const subInstruments = getChildren(instrument);
    if (subInstruments) {
      const subInstrument = subInstruments.find((value: InstrumentGeneric) => {
        return value.name === action.subInstrumentName;
      }, action.subInstrumentName);
      if (subInstrument) {
        const tunings = getChildren(subInstrument);
        if (tunings) {
          const tuning = tunings.find((value: InstrumentGeneric) => {
            return value.name === action.tuningName;
          }, action.tuningName);
          if (tuning) {
            const t: TuningElement = tuning as TuningElement;
            store.dispatch(Dispatch.setTuning(t));
          }
        }
      }
    }
  } else {
    // No instrument, go to chromatic.
    store.dispatch(Dispatch.setTuning(null));
  }

  yield 0;
}

function* onSetInstrumentEffect() {
  yield takeLatest(
    ActionNames.SetCurrentInstrumentAction,
    onSetInstrumentUpdate,
  );
}

// END SetInstrument -----
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
export function ApplyTuningToTuner(
  tuning: null | TuningElement,
  overrideToChromatic: boolean = false,
) {
  const freqs: number[] = [];
  const octaves: number[] = [];
  const notes: number[] = [];
  const tags: number[] = [];
  let doCommit = false;

  const currentState = Store.inst().store.getState();

  const refFreq = getReferenceFreq(
    currentState.system.transposition,
    currentState.system.referenceFreq,
  );

  if (tuning && !overrideToChromatic) {
    const _children = getChildren(tuning);
    if (_children) {
      if (true) {
        doCommit = true;
        const children: NoteElement[] = _children as NoteElement[];
        const relFreqs: NoteToFreqRel440Info[] = [];
        children.forEach((value: NoteElement) => {
          const infoArr = NoteToFreqRel440(value);
          infoArr.forEach((thisInfo: NoteToFreqRel440Info) => {
            if (thisInfo.freq < TARGET_MOTUNER_FS / 2) {
              relFreqs.push(thisInfo);
            }
          });
        });
        relFreqs.sort((a: NoteToFreqRel440Info, b: NoteToFreqRel440Info) => {
          return a.freqRel440 - b.freqRel440;
        });
        relFreqs.forEach((info: NoteToFreqRel440Info) => {
          const relFreq = info.freqRel440;
          const da = NormalNoteToDatunerNote(info.note, info.octave);
          freqs.push(relFreq * refFreq);
          octaves.push(da.o);
          notes.push(da.n);
          tags.push(Math.floor(relFreq * refFreq * 1000));
        });
        doCommit = true;
      }
    }
  } else {
    const {minFreq, maxFreq} = Store.inst().store.getState().system;
    const first: Midi.GetNoteErrorResult = Midi.GetNearestNoteAndError(minFreq);
    const last: Midi.GetNoteErrorResult = Midi.GetNearestNoteAndError(maxFreq);
    const octave0 = first.octave;

    for (let o = octave0; o <= last.octave; o++) {
      const startN = o === octave0 ? first.note : 0;
      for (let n = startN; n < 12; n++) {
        const c: Midi.NoteAndOctave = {
          note: n,
          octave: o,
        };
        const freq = Midi.NoteAndOctaveGetFreqFromNoteAndError(c, 0, refFreq);
        if (freq < TARGET_MOTUNER_FS / 2) {
          const da = NormalNoteToDatunerNote(n, o);
          freqs.push(freq);
          octaves.push(da.o);
          notes.push(da.n);
          tags.push(Math.floor(freq * 1000));
        }
      }
    }
    doCommit = true;
  }

  if (doCommit) {
    const currentNotesList = currentState.system.activeNotesList;
    if (arraysEqual(freqs, currentNotesList.freqsAry)) {
      if (arraysEqual(notes, currentNotesList.notesAry)) {
        if (arraysEqual(tags, currentNotesList.tagsAry)) {
          if (arraysEqual(octaves, currentNotesList.octavesAry)) {
            doCommit = false;
          }
        }
      }
    }
  }

  if (doCommit) {
    const freqsAry: Float32Array = Float32Array.from(freqs);
    const notesAry: Int32Array = Int32Array.from(notes);
    const octavesAry: Int32Array = Int32Array.from(octaves);
    const tagsAry: Int32Array = Int32Array.from(tags);

    let minFreq = 41;
    freqs.forEach((f: number, index: number) => {
      if (index === 0) {
        minFreq = f;
      } else {
        minFreq = Math.min(f, minFreq);
      }
    });
    const cutoffFreqHz = minFreq - 10;
    let cutoffFreqAnalHz = Math.max(10, cutoffFreqHz);
    let cutoffFreqHpfHz = Math.max(10, cutoffFreqHz);
    dbg.log('StroboProCpp.inst().WrapperUpdateNotes()::freqs:', freqsAry);

    // Todo: Why do we leave this in? We can drop these to lines and it should still work
    if (false) {
      cutoffFreqAnalHz = Math.min(cutoffFreqAnalHz, 55);
      cutoffFreqHpfHz = Math.min(cutoffFreqHpfHz, 41);
    }

    StroboProCpp.inst().WrapperUpdateNotes(
      freqsAry,
      notesAry,
      octavesAry,
      tagsAry,
    );
    dbg.log(
      'StroboProCpp.inst().WrapperSetMinFreqs(' +
        cutoffFreqHpfHz.toFixed(2) +
        ',' +
        cutoffFreqAnalHz.toFixed(2),
    );
    StroboProCpp.inst().WrapperSetMinFreqs(cutoffFreqHpfHz, cutoffFreqAnalHz);
    dbg.log('Store.inst().store.dispatch()');
    Store.inst().store.dispatch(
      Dispatch.setActiveNotesList(freqs, octaves, notes, tags),
    );
  }
}

// -----------------------------------------------------------------------------
function* onTuningChosen(actionAny: any, ...otherArgs: any[]) {
  const _action: ReduxActions = actionAny;
  const action: SetTuningAction = _action as SetTuningAction;
  const tuning = action.tuning;

  ApplyTuningToTuner(tuning);

  yield put({type: InfoNames.TuningChosen});
}

// -----------------------------------------------------------------------------
function* onTuningChosenEffect() {
  yield takeEvery(ActionNames.SetTuningAction, onTuningChosen);
}
// END TuningChosen -----
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// SetTemperament -----
function* onSetTemperamentUpdateAfter(_actionAny: any) {
  // TODO: Fetch and update the mapping in the native layer.
  dbg.log('temperaments::Here is where we update the temperaments');

  // Check if async storage is loaded

  const store = Store.inst().store;
  const state = store.getState();
  if (state.system.asyncStorageLoaded) {
    const tuning = state.system.currentTuning
      ? state.system.currentTuning
      : null;

    yield put(Dispatch.setTuning(tuning));
  }
}

function* onSetRefFreqOrTransAction(actionAny: any, ..._otherArgs: any[]) {
  yield call(onSetTemperamentUpdateAfter, actionAny);
}

function* onReferenceFrequencyEffect() {
  yield takeEvery(
    ActionNames.SetReferenceFreqAction,
    onSetRefFreqOrTransAction,
  );
}

function* onSetTranspositionEffect() {
  yield takeEvery(
    ActionNames.SetTranspositionAction,
    onSetRefFreqOrTransAction,
  );
}

// END SetTemperament -----
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// onLockToNoteAction -----
function* onLockToNoteAction(_actionAny: any) {
  const lockToThisNote = (_lockToThisNoteAny: any) => {
    const a: LockToNoteAction = _lockToThisNoteAny;
    const fc = a.lockedNote ? a.lockedNote.freq : 0;
    const alsoLockAnalysis = a.alsoLockAnalysis ? true : false;

    StroboProCpp.inst().WrapperLockToFreq(fc, 200, 3, alsoLockAnalysis);
  };

  // yield put(Dispatch.setTuning(tuning));
  yield call(lockToThisNote, _actionAny);
}

function* onLockToNoteEffect() {
  yield takeEvery(ActionNames.LockToNoteAction, onLockToNoteAction);
}

// END onLockToNoteAction -----
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// END Filters to run BEFORE saving state.
// -----------------------------------------------------------------------------

// Side effects
export const NoteMappingSideEffects = [
  onAppInitEffect,
  onSetInstrumentEffect,
  onTuningChosenEffect,
  onLockToNoteEffect,
  onReferenceFrequencyEffect,
  onSetTranspositionEffect,
];
