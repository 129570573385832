export const it = {
  it: {
    translation: {
      s_About: "Strobopro è un'app Strobe Tuner per Android, iOS e Web.\n\n",
      s_AboutThisApp: 'Informazioni su questa app',
      s_Cancel: 'Annulla',
      s_Choose: 'Scegli',
      s_CloseSettings: 'Chiudi impostazioni',
      s_CookieConsent:
        "Questo sito web utilizza i cookie per migliorare l'esperienza dell'utente. Non utilizziamo i cookie per memorizzare informazioni personali. Cliccando su Accetta, acconsenti all'utilizzo dei cookie su questo sito web.",
      s_Contact: 'Contatto',
      s_CurrentlyOn: 'Attualmente acceso',
      s_CurrentlyOff: 'Attualmente spento',
      s_DataCollectionPolicy: 'Politica di raccolta dati',
      s_EmailTemplate:
        'Suggerimento amichevole su StroboPro&body=Ciao sviluppatore, ho alcuni feedback per te...',
      s_EmailTheDeveloper: "Invia un'email allo sviluppatore",
      s_FFTSize: 'Dimensione FFT',
      s_GetTuningsFromStroboPro: 'Ottieni accordature da StroboPro.se',
      s_GlowEffects: 'Effetti luminosi',
      s_HelpAboutPro:
        'Hai la versione Premium. Grazie per il tuo supporto! 🙏\n\n',
      s_HelpAdvancedSettings:
        'Funzionalità avanzate come la dimensione FFT utilizzata per la rilevazione delle note, la sensibilità, il tipo di stroboscopio, ecc.',
      s_HelpEstimatedCents:
        'Il valore approssimativo dei centesimi è la differenza tra la frequenza approssimata e la nota più vicina.\n\nIn caso di dubbio, guarda gli stroboscopi stessi!\n\n(<_<)',
      s_HelpFftSize:
        'Una dimensione FFT maggiore offre risultati più accurati ma consuma più CPU.\n\n' +
        'Una dimensione FFT inferiore è più veloce ma meno accurata.\n\n' +
        'La FFT viene utilizzata per determinare quale nota viene riprodotta.\n\n' +
        'Se il tuo dispositivo ha difficoltà a decifrare le note corrette, prova a cambiare la dimensione FFT.\n\n' +
        'Il valore predefinito è ',
      s_HelpGlow:
        "Gli effetti luminosi aggiungono un tocco di vivacità, ma possono rallentare l'app su dispositivi più vecchi.",
      s_HelpInstrument: "Scegli uno strumento e un'accordatura dalla lista.",
      s_HelpInstrumentPro:
        "\n\nSe non trovi il tuo strumento o l'accordatura, puoi aggiungere o personalizzare le accordature nell'app web, strobopro.se.\n\nDopo aver effettuato l'accesso, questa app scaricherà i tuoi strumenti personalizzati e le tue accordature da strobopro.se.",
      s_HelpNoteLock:
        "Per quei momenti in cui il tuner non riesce a capire quale nota stai suonando, puoi utilizzare il blocco delle note.\n\nIl blocco delle note cercherà di bloccare il tuner alla nota che selezioni.\n\nCiò bloccherà gli stroboscopi su una frequenza specifica.\n\nTieni presente che rileverà questa frequenza da qualsiasi input a causa del filtraggio molto specifico per bloccare la nota.\n\nFai clic sull'icona del lucchetto a sinistra del blocco delle note per chiuderlo di nuovo.",
      s_HelpRating:
        'Se ti piace, considera di darci una bella valutazione sullo store, aiuta davvero!\n\n⭐️⭐️⭐️⭐️⭐️',
      s_HelpReferenceFrequency:
        'La frequenza di riferimento è la frequenza della nota A4.\n\nIl valore predefinito è 440Hz, ma puoi cambiarlo a 432Hz o a qualsiasi altro valore.',
      s_HelpSensitivity:
        'Riduci la sensibilità se il tuo dispositivo impiega troppo tempo inattivo.\n\nAumenta la sensibilità se il tuo dispositivo rileva sempre e reagisce al rumore di fondo.\n\nIl valore predefinito è 9 dB.',
      s_HelpStatusCents:
        "Questo mostra l'errore approssimativo in centesimi, derivato dagli stroboscopi.\n\nIn caso di dubbio, guarda gli stroboscopi stessi!\n\n(<_<)",
      s_HelpStatusFrequency:
        "Questo mostra la frequenza approssimativa, derivata dagli stroboscopi.\n\nViene mediata e può essere un po' capricciosa.",
      s_HelpStrobeDisplayType:
        'La modalità di visualizzazione della stroboscopica è migliore per i dispositivi più lenti, poiché viene controllata la velocità della stroboscopica e quindi le stroboscopiche vengono animate utilizzando la stessa velocità a qualsiasi frame rate il tuo dispositivo può gestire.\n\nLa modalità di posizione è più simile al funzionamento di un vero strobo tuner, in cui viene campionata la posizione corrente e quindi la stroboscopica viene spostata in quella posizione, ma funziona solo su dispositivi più veloci.',
      s_HelpTransposition:
        "La trasposizione è utile per gli strumenti accordati a un'altra tonalità rispetto a A440.\n\nEsempio: clarinetti in si bemolle, in cui suonare una nota scritta C suona un si bemolle.\n\nC->Bb convertirebbe una nota rilevata in un si bemolle visualizzato.",
      s_Instructions: 'Istruzioni',
      s_listening: 'in ascolto',
      s_Login: 'Accedi',
      s_Logout: 'Esci',
      s_LogoutToClearSettings: 'Esci per cancellare le impostazioni',
      s_LongPressItem: 'Premi a lungo su un elemento per ottenere aiuto',
      s_NavigateToAdvanced: 'Vai al menu delle impostazioni avanzate',
      s_NoneChromatic: 'Nessuno (Cromatico)',
      s_Off: 'Spento',
      s_On: 'Acceso',
      s_Position: 'Posizione',
      s_PrivacyPolicy: 'Informativa sulla privacy',
      s_Reset: 'Reset',
      s_START: 'INIZIA',
      s_SettingsHint: 'Vai alla schermata delle impostazioni',
      s_StrobeDisplayType: 'Tipo di visualizzazione stroboscopica',
      s_TapToChange: 'Tocca per cambiare',
      s_TapToChangeTo: 'Tocca per cambiare a ',
      s_TitleAboutStrobopro: 'Informazioni su Strobopro',
      s_TitleAddInstrument: 'Aggiungi strumento',
      s_TitleAdvanced: 'Avanzate',
      s_TitleChooseTuning: 'Scegli accordatura',
      s_TitleHelp: 'Aiuto',
      s_TitleInstrument: 'Strumento',
      s_TitleNoteLock: 'Blocco delle note',
      s_TitlePaywall: 'Paywall',
      s_TitleReferenceFrequencyHz: 'Frequenza di riferimento (Hz)',
      s_HintReferenceFrequencyHz:
        'Tocca per cambiare la frequenza di riferimento',
      s_TitleSensitivity: 'Sensibilità',
      s_TitleSettings: 'Impostazioni',
      s_TitleSignIn: 'Accedi',
      s_TitleStatusCents: 'Centesimi stroboscopica',
      s_TitleStatusFrequency: 'Frequenza stroboscopica',
      s_TitleTransposition: 'Trasposizione',
      s_TitleTuner: 'Tuner',
      s_UpgradeToPremium: 'Passa a Premium',
      s_Velocity: 'Velocità',
      s_HintSettings: 'Vai alla schermata delle impostazioni',
      s_HelpFrequencyDisp: 'Aiuto - Frequenza',
      s_HelpCents: 'Aiuto - Centesimi',
      s_TitleEstimatedCents: 'Centesimi stimati',
      s_HintNoteItem: 'Questa è una delle note nella mappatura',
      s_TitlePreviousNote: 'Nota precedente',
      s_HelpPreviousNote: 'Seleziona la nota precedente',
      s_TitleNextNote: 'Nota successiva',
      s_HelpNextNote: 'Seleziona la nota successiva',
      s_TitleMicrophone: 'Sensibilità del microfono',
      s_HelpMicrophone: 'Tocca per cambiare la sensibilità del microfono',
      s_TitleNoteLockToggle: 'Interruttore Blocco Nota',

      s_SignInToDeleteData: 'Accedi per eliminare i tuoi dati',
      s_DeleteDataAndSignOut: 'Elimina dati e esci',
      s_SignInToSync: 'Accedi per abilitare la sincronizzazione.',
      s_AreYouSureDeleteData: 'Sei sicuro di voler eliminare i tuoi dati?',
      s_LogOutToDisableSync: 'Esci per disabilitare la sincronizzazione?',
      s_YesDeleteDataAndLogOut: 'Sì, elimina i miei dati e esci',
      s_YesLogOut: 'Sì, esci',

      s_GooglePlayWarning:
        "Stai per eliminare tutti i dati memorizzati da Applicaudia AB per l'app Strobopro.\n" +
        "Per procedere, effettua l'accesso per garantire che tu possieda i dati da eliminare.\n" +
        'Tutti i tuoi dati verranno eliminati, comprendenti\n\n' +
        '\t- preferenze degli strumenti,\n' +
        '\t- accordature e impostazioni personalizzate,\n' +
        '\t- ID dispositivo e impostazioni uniche del dispositivo,\n' +
        '\t- ID utente,\n' +
        '\t- e timestamp.\n' +
        "\nNessun dato verrà conservato dopo l'eliminazione.",
    },
  },
};
