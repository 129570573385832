import {InstrumentElement} from './instrument_defs';

export const DefaultInstrumentsArray: InstrumentElement[] = [
  {
    name: 'Guitar',
    subInstrument: [
      {
        name: '6-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'E2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'True Temperament Open String',
            noteElement: [
              {
                cents: '-2',
                name: 'E2',
              },
              {
                cents: '0',
                name: 'A2',
              },
              {
                cents: '2',
                name: 'D3',
              },
              {
                cents: '4',
                name: 'G3',
              },
              {
                cents: '-1',
                name: 'B3',
              },
              {
                cents: '-1',
                name: 'E4',
              },
            ],
          },
          {
            name: 'True Temperament Drop-D',
            noteElement: [
              {
                cents: '2',
                name: 'D2',
              },
              {
                cents: '0',
                name: 'A2',
              },
              {
                cents: '2',
                name: 'D3',
              },
              {
                cents: '4',
                name: 'G3',
              },
              {
                cents: '-1',
                name: 'B3',
              },
              {
                cents: '-1',
                name: 'E4',
              },
            ],
          },
          {
            name: 'Drop-D',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-A',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'C#3',
              },
              {
                name: 'A3',
              },
              {
                name: 'C#4',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-C',
            noteElement: [
              {
                name: 'C2',
              },
              {
                name: 'G2',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-D',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'F#3',
              },
              {
                name: 'A3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open-D7',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'F#3',
              },
              {
                name: 'C4',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open-Dsus',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'A3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open-E',
            noteElement: [
              {
                name: 'E2',
              },
              {
                name: 'B2',
              },
              {
                name: 'E3',
              },
              {
                name: 'G#3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-Em',
            noteElement: [
              {
                name: 'E2',
              },
              {
                name: 'B2',
              },
              {
                name: 'E3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-Fm7',
            noteElement: [
              {
                name: 'F2',
              },
              {
                name: 'G#2',
              },
              {
                name: 'C3',
              },
              {
                name: 'F#3',
              },
              {
                name: 'G#3',
              },
              {
                name: 'F#4',
              },
            ],
          },
          {
            name: 'Open-G',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'G2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open-G6',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'G2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open-Gm',
            noteElement: [
              {
                name: 'D2',
              },
              {
                name: 'G2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'A#3',
              },
              {
                name: 'D4',
              },
            ],
          },
        ],
      },
      {
        name: '7-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'B1',
              },
              {
                name: 'E2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'True Temperament Open String',
            noteElement: [
              {
                name: 'B1',
                cents: '-4',
              },
              {
                name: 'E2',
                cents: '-2',
              },
              {
                name: 'A2',
                cents: '0',
              },
              {
                name: 'D3',
                cents: '2',
              },
              {
                name: 'G3',
                cents: '4',
              },
              {
                name: 'B3',
                cents: '-1',
              },
              {
                name: 'E4',
                cents: '-1',
              },
            ],
          },
        ],
      },
      {
        name: '8-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'F#1',
              },
              {
                name: 'B1',
              },
              {
                name: 'E2',
              },
              {
                name: 'A2',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'True Temperament Open String',
            noteElement: [
              {
                name: 'F#1',
                cents: '-8',
              },
              {
                name: 'B1',
                cents: '-4',
              },
              {
                name: 'E2',
                cents: '-2',
              },
              {
                name: 'A2',
                cents: '0',
              },
              {
                name: 'D3',
                cents: '2',
              },
              {
                name: 'G3',
                cents: '4',
              },
              {
                name: 'B3',
                cents: '-1',
              },
              {
                name: 'E4',
                cents: '-1',
              },
            ],
          },
        ],
      },
      {
        name: '12-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'E2',
                symbol: '12',
              },
              {
                name: 'E3',
                symbol: '11',
              },
              {
                name: 'A2',
                symbol: '10',
              },
              {
                name: 'A3',
                symbol: '9',
              },
              {
                name: 'D3',
                symbol: '8',
              },
              {
                name: 'D4',
                symbol: '7',
              },
              {
                name: 'G3',
                symbol: '6',
              },
              {
                name: 'G4',
                symbol: '5',
              },
              {
                name: 'B3',
                symbol: '4+3',
              },
              {
                name: 'E4',
                symbol: '2+1',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Bass Guitar',
    subInstrument: [
      {
        name: '4-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'E1',
                symbol: '4',
                superscript: 'E1',
                subscript: '41.20',
              },
              {
                name: 'A1',
                symbol: '3',
                superscript: 'A1',
                subscript: '55.00',
              },
              {
                name: 'D2',
                symbol: '2',
                superscript: 'D2',
                subscript: '73.42',
              },
              {
                name: 'G2',
                symbol: '1',
                superscript: 'G2',
                subscript: '98.00',
              },
            ],
          },

          {
            name: 'Adler SubContra',
            noteElement: [
              {
                name: 'C#0',
                subscript: '17.32391',
              },
              {
                name: 'F#0',
                subscript: '23.12465',
              },
              {
                name: 'B0',
                subscript: '30.87',
              },
              {
                name: 'E1',
                subscript: '41.20',
              },
            ],
          },
        ],
      },
      {
        name: '5-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'B0',
                symbol: '5',
                superscript: 'B0',
                subscript: '30.87',
              },
              {
                name: 'E1',
                symbol: '4',
                superscript: 'E1',
                subscript: '41.20',
              },
              {
                name: 'A1',
                symbol: '3',
                superscript: 'A1',
                subscript: '55.00',
              },
              {
                name: 'D2',
                symbol: '2',
                superscript: 'D2',
                subscript: '73.42',
              },
              {
                name: 'G2',
                symbol: '1',
                superscript: 'G2',
                subscript: '98.00',
              },
            ],
          },
          {
            name: 'Adler SubContra w/B.ext',
            noteElement: [
              {
                name: 'B-1',
                subscript: '15.434',
              },
              {
                name: 'C#0',
                subscript: '17.324',
              },
              {
                name: 'F#0',
                subscript: '23.125',
              },
              {
                name: 'B0',
                subscript: '30.87',
              },
              {
                name: 'E1',
                subscript: '41.20',
              },
            ],
          },
        ],
      },
      {
        name: '6-String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'B0',
                symbol: '6',
                superscript: 'B0',
                subscript: '30.87',
              },
              {
                name: 'E1',
                symbol: '5',
                superscript: 'E1',
                subscript: '41.20',
              },
              {
                name: 'A1',
                symbol: '4',
                superscript: 'A1',
                subscript: '55.00',
              },
              {
                name: 'D2',
                symbol: '3',
                superscript: 'D2',
                subscript: '73.42',
              },
              {
                name: 'G2',
                symbol: '2',
                superscript: 'G2',
                subscript: '98.00',
              },
              {
                name: 'C3',
                symbol: '1',
                superscript: 'C3',
                subscript: '130.81',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Ukulele',
    subInstrument: [
      {
        name: '4-String',
        tuning: [
          {
            name: 'A4-D4-F#4-B4',
            noteElement: [
              {
                name: 'A4',
              },
              {
                name: 'D4',
              },
              {
                name: 'F#4',
              },
              {
                name: 'B4',
              },
            ],
          },
          {
            name: 'G4-C4-E4-A4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C4',
              },
              {
                name: 'E4',
              },
              {
                name: 'A4',
              },
            ],
          },
          {
            name: 'G3-C4-E4-A4',
            noteElement: [
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'E4',
              },
              {
                name: 'A4',
              },
            ],
          },
          {
            name: 'D4-G3-B3-E4',
            noteElement: [
              {
                name: 'D4',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'E4',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Piano',
    subInstrument: [
      {
        name: '88-Key',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'A0',
                superscript: '1',
              },
              {
                name: 'A#0',
                superscript: '2',
              },
              {
                name: 'B0',
                superscript: '3',
              },
              {
                name: 'C1',
                superscript: '4',
              },
              {
                name: 'C#1',
                superscript: '5',
              },
              {
                name: 'D1',
                superscript: '6',
              },
              {
                name: 'D#1',
                superscript: '7',
              },
              {
                name: 'E1',
                superscript: '8',
              },
              {
                name: 'F1',
                superscript: '9',
              },
              {
                name: 'F#1',
                superscript: '10',
              },
              {
                name: 'G1',
                superscript: '11',
              },
              {
                name: 'G#1',
                superscript: '12',
              },
              {
                name: 'A1',
                superscript: '13',
              },
              {
                name: 'A#1',
                superscript: '14',
              },
              {
                name: 'B1',
                superscript: '15',
              },
              {
                name: 'C2',
                superscript: '16',
              },
              {
                name: 'C#2',
                superscript: '17',
              },
              {
                name: 'D2',
                superscript: '18',
              },
              {
                name: 'D#2',
                superscript: '19',
              },
              {
                name: 'E2',
                superscript: '20',
              },
              {
                name: 'F2',
                superscript: '21',
              },
              {
                name: 'F#2',
                superscript: '22',
              },
              {
                name: 'G2',
                superscript: '23',
              },
              {
                name: 'G#2',
                superscript: '24',
              },
              {
                name: 'A2',
                superscript: '25',
              },
              {
                name: 'A#2',
                superscript: '26',
              },
              {
                name: 'B2',
                superscript: '27',
              },
              {
                name: 'C3',
                superscript: '28',
              },
              {
                name: 'C#3',
                superscript: '29',
              },
              {
                name: 'D3',
                superscript: '30',
              },
              {
                name: 'D#3',
                superscript: '31',
              },
              {
                name: 'E3',
                superscript: '32',
              },
              {
                name: 'F3',
                superscript: '33',
              },
              {
                name: 'F#3',
                superscript: '34',
              },
              {
                name: 'G3',
                superscript: '35',
              },
              {
                name: 'G#3',
                superscript: '36',
              },
              {
                name: 'A3',
                superscript: '37',
              },
              {
                name: 'A#3',
                superscript: '38',
              },
              {
                name: 'B3',
                superscript: '39',
              },
              {
                name: 'C4',
                superscript: '40',
              },
              {
                name: 'C#4',
                superscript: '41',
              },
              {
                name: 'D4',
                superscript: '42',
              },
              {
                name: 'D#4',
                superscript: '43',
              },
              {
                name: 'E4',
                superscript: '44',
              },
              {
                name: 'F4',
                superscript: '45',
              },
              {
                name: 'F#4',
                superscript: '46',
              },
              {
                name: 'G4',
                superscript: '47',
              },
              {
                name: 'G#4',
                superscript: '48',
              },
              {
                name: 'A4',
                superscript: '49',
              },
              {
                name: 'A#4',
                superscript: '50',
              },
              {
                name: 'B4',
                superscript: '51',
              },
              {
                name: 'C5',
                superscript: '52',
              },
              {
                name: 'C#5',
                superscript: '53',
              },
              {
                name: 'D5',
                superscript: '54',
              },
              {
                name: 'D#5',
                superscript: '55',
              },
              {
                name: 'E5',
                superscript: '56',
              },
              {
                name: 'F5',
                superscript: '57',
              },
              {
                name: 'F#5',
                superscript: '58',
              },
              {
                name: 'G5',
                superscript: '59',
              },
              {
                name: 'G#5',
                superscript: '60',
              },
              {
                name: 'A5',
                superscript: '61',
              },
              {
                name: 'A#5',
                superscript: '62',
              },
              {
                name: 'B5',
                superscript: '63',
              },
              {
                name: 'C6',
                superscript: '64',
              },
              {
                name: 'C#6',
                superscript: '65',
              },
              {
                name: 'D6',
                superscript: '66',
              },
              {
                name: 'D#6',
                superscript: '67',
              },
              {
                name: 'E6',
                superscript: '68',
              },
              {
                name: 'F6',
                superscript: '69',
              },
              {
                name: 'F#6',
                superscript: '70',
              },
              {
                name: 'G6',
                superscript: '71',
              },
              {
                name: 'G#6',
                superscript: '72',
              },
              {
                name: 'A6',
                superscript: '73',
              },
              {
                name: 'A#6',
                superscript: '74',
              },
              {
                name: 'B6',
                superscript: '75',
              },
              {
                name: 'C7',
                superscript: '76',
              },
              {
                name: 'C#7',
                superscript: '77',
              },
              {
                name: 'D7',
                superscript: '78',
              },
              {
                name: 'D#7',
                superscript: '79',
              },
              {
                name: 'E7',
                superscript: '80',
              },
              {
                name: 'F7',
                superscript: '81',
              },
              {
                name: 'F#7',
                superscript: '82',
              },
              {
                name: 'G7',
                superscript: '83',
              },
              {
                name: 'G#7',
                superscript: '84',
              },
              {
                name: 'A7',
                superscript: '85',
              },
              {
                name: 'A#7',
                superscript: '86',
              },
              {
                name: 'B7',
                superscript: '87',
              },
              {
                name: 'C8',
                superscript: '88',
              },
            ],
          },
          {
            name: 'Piano (average full stretch)',
            noteElement: [
              {name: 'A0', cents: '-18'},
              {name: 'A#0', cents: '-17.2'},
              {name: 'B0', cents: '-16.4'},
              {name: 'C1', cents: '-15.6'},
              {name: 'C#1', cents: '-14.8'},
              {name: 'D1', cents: '-14'},
              {name: 'D#1', cents: '-13.2'},
              {name: 'E1', cents: '-12.4'},
              {name: 'F1', cents: '-11.6'},
              {name: 'F#1', cents: '-10.8'},
              {name: 'G1', cents: '-10'},
              {name: 'G#1', cents: '-9.2'},
              {name: 'A1', cents: '-8.4'},
              {name: 'A#1', cents: '-8'},
              {name: 'B1', cents: '-7.6'},
              {name: 'C2', cents: '-7.2'},
              {name: 'C#2', cents: '-6.8'},
              {name: 'D2', cents: '-6.4'},
              {name: 'D#2', cents: '-6'},
              {name: 'E2', cents: '-5.6'},
              {name: 'F2', cents: '-5.2'},
              {name: 'F#2', cents: '-4.8'},
              {name: 'G2', cents: '-4.4'},
              {name: 'G#2', cents: '-4'},
              {name: 'A2', cents: '-3.6'},
              {name: 'A#2', cents: '-3.4'},
              {name: 'B2', cents: '-3.2'},
              {name: 'C3', cents: '-3'},
              {name: 'C#3', cents: '-2.8'},
              {name: 'D3', cents: '-2.6'},
              {name: 'D#3', cents: '-2.4'},
              {name: 'E3', cents: '-2.2'},
              {name: 'F3', cents: '-2'},
              {name: 'F#3', cents: '-1.8'},
              {name: 'G3', cents: '-1.6'},
              {name: 'G#3', cents: '-1.4'},
              {name: 'A3', cents: '-1.2'},
              {name: 'A#3', cents: '-1.1'},
              {name: 'B3', cents: '-1'},
              {name: 'C4', cents: '-0.9'},
              {name: 'C#4', cents: '-0.8'},
              {name: 'D4', cents: '-0.7'},
              {name: 'D#4', cents: '-0.6'},
              {name: 'E4', cents: '-0.5'},
              {name: 'F4', cents: '-0.4'},
              {name: 'F#4', cents: '-0.3'},
              {name: 'G4', cents: '-0.2'},
              {name: 'G#4', cents: '-0.1'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0'},
              {name: 'B4', cents: '0'},
              {name: 'C5', cents: '0'},
              {name: 'C#5', cents: '0.1'},
              {name: 'D5', cents: '0.2'},
              {name: 'D#5', cents: '0.3'},
              {name: 'E5', cents: '0.4'},
              {name: 'F5', cents: '0.5'},
              {name: 'F#5', cents: '0.6'},
              {name: 'G5', cents: '0.7'},
              {name: 'G#5', cents: '0.8'},
              {name: 'A5', cents: '0.9'},
              {name: 'A#5', cents: '1'},
              {name: 'B5', cents: '1.1'},
              {name: 'C6', cents: '1.2'},
              {name: 'C#6', cents: '1.4'},
              {name: 'D6', cents: '1.6'},
              {name: 'D#6', cents: '1.8'},
              {name: 'E6', cents: '2'},
              {name: 'F6', cents: '2.2'},
              {name: 'F#6', cents: '2.4'},
              {name: 'G6', cents: '2.6'},
              {name: 'G#6', cents: '2.8'},
              {name: 'A6', cents: '3'},
              {name: 'A#6', cents: '3.2'},
              {name: 'B6', cents: '3.4'},
              {name: 'C7', cents: '3.6'},
              {name: 'C#7', cents: '4'},
              {name: 'D7', cents: '4.4'},
              {name: 'D#7', cents: '4.8'},
              {name: 'E7', cents: '5.2'},
              {name: 'F7', cents: '5.6'},
              {name: 'F#7', cents: '6'},
              {name: 'G7', cents: '6.4'},
              {name: 'G#7', cents: '6.8'},
              {name: 'A7', cents: '7.2'},
              {name: 'A#7', cents: '7.6'},
              {name: 'B7', cents: '8'},
              {name: 'C8', cents: '8.4'},
            ],
          },
          {
            name: 'Spinet (<100 cm)',
            noteElement: [
              {name: 'A0', cents: '-33'},
              {name: 'A#0', cents: '-31.2'},
              {name: 'B0', cents: '-29.5'},
              {name: 'C1', cents: '-27.9'},
              {name: 'C#1', cents: '-26.4'},
              {name: 'D1', cents: '-25.1'},
              {name: 'D#1', cents: '-23.9'},
              {name: 'E1', cents: '-22.8'},
              {name: 'F1', cents: '-21.8'},
              {name: 'F#1', cents: '-20.9'},
              {name: 'G1', cents: '-20'},
              {name: 'G#1', cents: '-19.2'},
              {name: 'A1', cents: '-18.4'},
              {name: 'A#1', cents: '-17.7'},
              {name: 'B1', cents: '-17'},
              {name: 'C2', cents: '-16.4'},
              {name: 'C#2', cents: '-15.8'},
              {name: 'D2', cents: '-15.2'},
              {name: 'D#2', cents: '-14.6'},
              {name: 'E2', cents: '-14'},
              {name: 'F2', cents: '-13.5'},
              {name: 'F#2', cents: '-13'},
              {name: 'G2', cents: '-12.5'},
              {name: 'G#2', cents: '-12'},
              {name: 'A2', cents: '-11.5'},
              {name: 'A#2', cents: '-11'},
              {name: 'B2', cents: '-10.5'},
              {name: 'C3', cents: '-10'},
              {name: 'C#3', cents: '-9.5'},
              {name: 'D3', cents: '-9'},
              {name: 'D#3', cents: '-8.6'},
              {name: 'E3', cents: '-8.2'},
              {name: 'F3', cents: '-7.8'},
              {name: 'F#3', cents: '-7.4'},
              {name: 'G3', cents: '-7'},
              {name: 'G#3', cents: '-6.5'},
              {name: 'A3', cents: '-6'},
              {name: 'A#3', cents: '-5.5'},
              {name: 'B3', cents: '-5'},
              {name: 'C4', cents: '-4.5'},
              {name: 'C#4', cents: '-4'},
              {name: 'D4', cents: '-3.5'},
              {name: 'D#4', cents: '-3'},
              {name: 'E4', cents: '-2.5'},
              {name: 'F4', cents: '-2'},
              {name: 'F#4', cents: '-1.5'},
              {name: 'G4', cents: '-1'},
              {name: 'G#4', cents: '-0.5'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0.5'},
              {name: 'B4', cents: '1'},
              {name: 'C5', cents: '1.5'},
              {name: 'C#5', cents: '2'},
              {name: 'D5', cents: '2.5'},
              {name: 'D#5', cents: '3'},
              {name: 'E5', cents: '3.5'},
              {name: 'F5', cents: '4'},
              {name: 'F#5', cents: '4.6'},
              {name: 'G5', cents: '5.2'},
              {name: 'G#5', cents: '5.8'},
              {name: 'A5', cents: '6.4'},
              {name: 'A#5', cents: '7.1'},
              {name: 'B5', cents: '7.8'},
              {name: 'C6', cents: '8.5'},
              {name: 'C#6', cents: '9.3'},
              {name: 'D6', cents: '10.1'},
              {name: 'D#6', cents: '11'},
              {name: 'E6', cents: '11.9'},
              {name: 'F6', cents: '12.9'},
              {name: 'F#6', cents: '14'},
              {name: 'G6', cents: '15.2'},
              {name: 'G#6', cents: '16.5'},
              {name: 'A6', cents: '17.9'},
              {name: 'A#6', cents: '19.4'},
              {name: 'B6', cents: '21'},
              {name: 'C7', cents: '22.7'},
              {name: 'C#7', cents: '24.5'},
              {name: 'D7', cents: '26.4'},
              {name: 'D#7', cents: '28.5'},
              {name: 'E7', cents: '30.7'},
              {name: 'F7', cents: '33'},
              {name: 'F#7', cents: '35.4'},
              {name: 'G7', cents: '37.9'},
              {name: 'G#7', cents: '40.5'},
              {name: 'A7', cents: '43.2'},
              {name: 'A#7', cents: '46'},
              {name: 'B7', cents: '48.9'},
              {name: 'C8', cents: '49'},
            ],
          },
          {
            name: 'Console (100-130 cm)',
            noteElement: [
              {name: 'A0', cents: '-30.8'},
              {name: 'A#0', cents: '-28.9'},
              {name: 'B0', cents: '-27.1'},
              {name: 'C1', cents: '-25.4'},
              {name: 'C#1', cents: '-23.8'},
              {name: 'D1', cents: '-22.3'},
              {name: 'D#1', cents: '-20.9'},
              {name: 'E1', cents: '-19.6'},
              {name: 'F1', cents: '-18.4'},
              {name: 'F#1', cents: '-17.2'},
              {name: 'G1', cents: '-16.1'},
              {name: 'G#1', cents: '-15'},
              {name: 'A1', cents: '-14'},
              {name: 'A#1', cents: '-13'},
              {name: 'B1', cents: '-12.1'},
              {name: 'C2', cents: '-11.2'},
              {name: 'C#2', cents: '-10.4'},
              {name: 'D2', cents: '-9.6'},
              {name: 'D#2', cents: '-8.9'},
              {name: 'E2', cents: '-8.2'},
              {name: 'F2', cents: '-7.5'},
              {name: 'F#2', cents: '-6.9'},
              {name: 'G2', cents: '-6.3'},
              {name: 'G#2', cents: '-5.7'},
              {name: 'A2', cents: '-5.2'},
              {name: 'A#2', cents: '-4.7'},
              {name: 'B2', cents: '-4.2'},
              {name: 'C3', cents: '-3.8'},
              {name: 'C#3', cents: '-3.4'},
              {name: 'D3', cents: '-3'},
              {name: 'D#3', cents: '-2.7'},
              {name: 'E3', cents: '-2.4'},
              {name: 'F3', cents: '-2.1'},
              {name: 'F#3', cents: '-1.9'},
              {name: 'G3', cents: '-1.7'},
              {name: 'G#3', cents: '-1.5'},
              {name: 'A3', cents: '-1.4'},
              {name: 'A#3', cents: '-1.3'},
              {name: 'B3', cents: '-1.2'},
              {name: 'C4', cents: '-1.1'},
              {name: 'C#4', cents: '-1.1'},
              {name: 'D4', cents: '-1.1'},
              {name: 'D#4', cents: '-1'},
              {name: 'E4', cents: '-0.9'},
              {name: 'F4', cents: '-0.8'},
              {name: 'F#4', cents: '-0.7'},
              {name: 'G4', cents: '-0.5'},
              {name: 'G#4', cents: '-0.3'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0.3'},
              {name: 'B4', cents: '0.6'},
              {name: 'C5', cents: '0.9'},
              {name: 'C#5', cents: '1.3'},
              {name: 'D5', cents: '1.7'},
              {name: 'D#5', cents: '2.1'},
              {name: 'E5', cents: '2.5'},
              {name: 'F5', cents: '3'},
              {name: 'F#5', cents: '3.5'},
              {name: 'G5', cents: '4'},
              {name: 'G#5', cents: '4.5'},
              {name: 'A5', cents: '5.1'},
              {name: 'A#5', cents: '5.7'},
              {name: 'B5', cents: '6.4'},
              {name: 'C6', cents: '7.1'},
              {name: 'C#6', cents: '7.8'},
              {name: 'D6', cents: '8.5'},
              {name: 'D#6', cents: '9.3'},
              {name: 'E6', cents: '10.1'},
              {name: 'F6', cents: '10.9'},
              {name: 'F#6', cents: '11.7'},
              {name: 'G6', cents: '12.6'},
              {name: 'G#6', cents: '13.5'},
              {name: 'A6', cents: '14.4'},
              {name: 'A#6', cents: '15.3'},
              {name: 'B6', cents: '16.2'},
              {name: 'C7', cents: '17.1'},
              {name: 'C#7', cents: '18.1'},
              {name: 'D7', cents: '19.1'},
              {name: 'D#7', cents: '20.2'},
              {name: 'E7', cents: '21.3'},
              {name: 'F7', cents: '22.5'},
              {name: 'F#7', cents: '23.8'},
              {name: 'G7', cents: '25.2'},
              {name: 'G#7', cents: '26.7'},
              {name: 'A7', cents: '28.3'},
              {name: 'A#7', cents: '30'},
              {name: 'B7', cents: '31.8'},
              {name: 'C8', cents: '33.7'},
            ],
          },
          {
            name: 'Upright vertical (>130 cm)',
            noteElement: [
              {name: 'A0', cents: '-25'},
              {name: 'A#0', cents: '-23.1'},
              {name: 'B0', cents: '-21.4'},
              {name: 'C1', cents: '-19.9'},
              {name: 'C#1', cents: '-18.5'},
              {name: 'D1', cents: '-17.2'},
              {name: 'D#1', cents: '-16'},
              {name: 'E1', cents: '-14.9'},
              {name: 'F1', cents: '-13.9'},
              {name: 'F#1', cents: '-12.9'},
              {name: 'G1', cents: '-12'},
              {name: 'G#1', cents: '-11.1'},
              {name: 'A1', cents: '-10.3'},
              {name: 'A#1', cents: '-9.5'},
              {name: 'B1', cents: '-8.8'},
              {name: 'C2', cents: '-8.1'},
              {name: 'C#2', cents: '-7.5'},
              {name: 'D2', cents: '-6.9'},
              {name: 'D#2', cents: '-6.3'},
              {name: 'E2', cents: '-5.8'},
              {name: 'F2', cents: '-5.4'},
              {name: 'F#2', cents: '-5.1'},
              {name: 'G2', cents: '-4.8'},
              {name: 'G#2', cents: '-4.5'},
              {name: 'A2', cents: '-4.2'},
              {name: 'A#2', cents: '-3.9'},
              {name: 'B2', cents: '-3.6'},
              {name: 'C3', cents: '-3.3'},
              {name: 'C#3', cents: '-3.1'},
              {name: 'D3', cents: '-2.9'},
              {name: 'D#3', cents: '-2.7'},
              {name: 'E3', cents: '-2.5'},
              {name: 'F3', cents: '-2.3'},
              {name: 'F#3', cents: '-2.1'},
              {name: 'G3', cents: '-1.9'},
              {name: 'G#3', cents: '-1.7'},
              {name: 'A3', cents: '-1.5'},
              {name: 'A#3', cents: '-1.3'},
              {name: 'B3', cents: '-1.2'},
              {name: 'C4', cents: '-1.1'},
              {name: 'C#4', cents: '-1'},
              {name: 'D4', cents: '-0.95'},
              {name: 'D#4', cents: '-0.9'},
              {name: 'E4', cents: '-0.8'},
              {name: 'F4', cents: '-0.7'},
              {name: 'F#4', cents: '-0.6'},
              {name: 'G4', cents: '-0.4'},
              {name: 'G#4', cents: '-0.2'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0.3'},
              {name: 'B4', cents: '0.6'},
              {name: 'C5', cents: '0.9'},
              {name: 'C#5', cents: '1.2'},
              {name: 'D5', cents: '1.5'},
              {name: 'D#5', cents: '1.9'},
              {name: 'E5', cents: '2.3'},
              {name: 'F5', cents: '2.7'},
              {name: 'F#5', cents: '3.1'},
              {name: 'G5', cents: '3.6'},
              {name: 'G#5', cents: '4.1'},
              {name: 'A5', cents: '4.6'},
              {name: 'A#5', cents: '5.1'},
              {name: 'B5', cents: '5.7'},
              {name: 'C6', cents: '6.3'},
              {name: 'C#6', cents: '6.9'},
              {name: 'D6', cents: '7.5'},
              {name: 'D#6', cents: '8.1'},
              {name: 'E6', cents: '8.8'},
              {name: 'F6', cents: '9.5'},
              {name: 'F#6', cents: '10.2'},
              {name: 'G6', cents: '10.9'},
              {name: 'G#6', cents: '11.7'},
              {name: 'A6', cents: '12.5'},
              {name: 'A#6', cents: '13.3'},
              {name: 'B6', cents: '14.1'},
              {name: 'C7', cents: '15'},
              {name: 'C#7', cents: '15.9'},
              {name: 'D7', cents: '16.8'},
              {name: 'D#7', cents: '17.8'},
              {name: 'E7', cents: '18.8'},
              {name: 'F7', cents: '19.9'},
              {name: 'F#7', cents: '21.1'},
              {name: 'G7', cents: '22.4'},
              {name: 'G#7', cents: '23.8'},
              {name: 'A7', cents: '25.3'},
              {name: 'A#7', cents: '26.9'},
              {name: 'B7', cents: '28.6'},
              {name: 'C8', cents: '30.4'},
            ],
          },
          {
            name: 'Small grand (<1,8 m)',
            noteElement: [
              {name: 'A0', cents: '-20'},
              {name: 'A#0', cents: '-18.7'},
              {name: 'B0', cents: '-17.5'},
              {name: 'C1', cents: '-16.3'},
              {name: 'C#1', cents: '-15.2'},
              {name: 'D1', cents: '-14.1'},
              {name: 'D#1', cents: '-13.1'},
              {name: 'E1', cents: '-12.1'},
              {name: 'F1', cents: '-11.2'},
              {name: 'F#1', cents: '-10.3'},
              {name: 'G1', cents: '-9.5'},
              {name: 'G#1', cents: '-8.7'},
              {name: 'A1', cents: '-8'},
              {name: 'A#1', cents: '-7.3'},
              {name: 'B1', cents: '-6.6'},
              {name: 'C2', cents: '-6'},
              {name: 'C#2', cents: '-5.4'},
              {name: 'D2', cents: '-4.8'},
              {name: 'D#2', cents: '-4.3'},
              {name: 'E2', cents: '-3.8'},
              {name: 'F2', cents: '-3.3'},
              {name: 'F#2', cents: '-2.9'},
              {name: 'G2', cents: '-2.5'},
              {name: 'G#2', cents: '-2.1'},
              {name: 'A2', cents: '-1.8'},
              {name: 'A#2', cents: '-1.5'},
              {name: 'B2', cents: '-1.2'},
              {name: 'C3', cents: '-1'},
              {name: 'C#3', cents: '-0.8'},
              {name: 'D3', cents: '-0.6'},
              {name: 'D#3', cents: '-0.4'},
              {name: 'E3', cents: '-0.2'},
              {name: 'F3', cents: '-0.1'},
              {name: 'F#3', cents: '0'},
              {name: 'G3', cents: '0'},
              {name: 'G#3', cents: '0'},
              {name: 'A3', cents: '0'},
              {name: 'A#3', cents: '0'},
              {name: 'B3', cents: '0'},
              {name: 'C4', cents: '0'},
              {name: 'C#4', cents: '0'},
              {name: 'D4', cents: '0'},
              {name: 'D#4', cents: '0'},
              {name: 'E4', cents: '0'},
              {name: 'F4', cents: '0'},
              {name: 'F#4', cents: '0'},
              {name: 'G4', cents: '0'},
              {name: 'G#4', cents: '0'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0'},
              {name: 'B4', cents: '0'},
              {name: 'C5', cents: '0'},
              {name: 'C#5', cents: '0'},
              {name: 'D5', cents: '0'},
              {name: 'D#5', cents: '0'},
              {name: 'E5', cents: '0.1'},
              {name: 'F5', cents: '0.2'},
              {name: 'F#5', cents: '0.4'},
              {name: 'G5', cents: '0.6'},
              {name: 'G#5', cents: '0.8'},
              {name: 'A5', cents: '1.1'},
              {name: 'A#5', cents: '1.5'},
              {name: 'B5', cents: '2'},
              {name: 'C6', cents: '2.5'},
              {name: 'C#6', cents: '3'},
              {name: 'D6', cents: '3.6'},
              {name: 'D#6', cents: '4.2'},
              {name: 'E6', cents: '4.9'},
              {name: 'F6', cents: '5.6'},
              {name: 'F#6', cents: '6.4'},
              {name: 'G6', cents: '7.2'},
              {name: 'G#6', cents: '8.1'},
              {name: 'A6', cents: '9'},
              {name: 'A#6', cents: '10'},
              {name: 'B6', cents: '11'},
              {name: 'C7', cents: '12.1'},
              {name: 'C#7', cents: '13.2'},
              {name: 'D7', cents: '14.4'},
              {name: 'D#7', cents: '15.6'},
              {name: 'E7', cents: '16.9'},
              {name: 'F7', cents: '18.2'},
              {name: 'F#7', cents: '19.6'},
              {name: 'G7', cents: '21'},
              {name: 'G#7', cents: '22.5'},
              {name: 'A7', cents: '24'},
              {name: 'A#7', cents: '25.6'},
              {name: 'B7', cents: '27.2'},
              {name: 'C8', cents: '28.9'},
            ],
          },
          {
            name: 'Studio grand (1,8-2,4 m)',
            noteElement: [
              {name: 'A0', cents: '-13.1'},
              {name: 'A#0', cents: '-11.9'},
              {name: 'B0', cents: '-10.8'},
              {name: 'C1', cents: '-9.8'},
              {name: 'C#1', cents: '-8.9'},
              {name: 'D1', cents: '-8.1'},
              {name: 'D#1', cents: '-7.4'},
              {name: 'E1', cents: '-6.8'},
              {name: 'F1', cents: '-6.2'},
              {name: 'F#1', cents: '-5.6'},
              {name: 'G1', cents: '-5.1'},
              {name: 'G#1', cents: '-4.6'},
              {name: 'A1', cents: '-4.1'},
              {name: 'A#1', cents: '-3.6'},
              {name: 'B1', cents: '-3.2'},
              {name: 'C2', cents: '-2.8'},
              {name: 'C#2', cents: '-2.4'},
              {name: 'D2', cents: '-2'},
              {name: 'D#2', cents: '-1.6'},
              {name: 'E2', cents: '-1.4'},
              {name: 'F2', cents: '-1.1'},
              {name: 'F#2', cents: '-0.8'},
              {name: 'G2', cents: '-0.6'},
              {name: 'G#2', cents: '-0.4'},
              {name: 'A2', cents: '-0.3'},
              {name: 'A#2', cents: '-0.2'},
              {name: 'B2', cents: '-0.1'},
              {name: 'C3', cents: '0'},
              {name: 'C#3', cents: '0'},
              {name: 'D3', cents: '0'},
              {name: 'D#3', cents: '0'},
              {name: 'E3', cents: '0'},
              {name: 'F3', cents: '0'},
              {name: 'F#3', cents: '0'},
              {name: 'G3', cents: '0'},
              {name: 'G#3', cents: '0'},
              {name: 'A3', cents: '0'},
              {name: 'A#3', cents: '0'},
              {name: 'B3', cents: '0'},
              {name: 'C4', cents: '0'},
              {name: 'C#4', cents: '0'},
              {name: 'D4', cents: '0'},
              {name: 'D#4', cents: '0'},
              {name: 'E4', cents: '0'},
              {name: 'F4', cents: '0'},
              {name: 'F#4', cents: '0'},
              {name: 'G4', cents: '0'},
              {name: 'G#4', cents: '0'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0'},
              {name: 'B4', cents: '0'},
              {name: 'C5', cents: '0'},
              {name: 'C#5', cents: '0'},
              {name: 'D5', cents: '0'},
              {name: 'D#5', cents: '0'},
              {name: 'E5', cents: '0'},
              {name: 'F5', cents: '0'},
              {name: 'F#5', cents: '0'},
              {name: 'G5', cents: '0.1'},
              {name: 'G#5', cents: '0.2'},
              {name: 'A5', cents: '0.3'},
              {name: 'A#5', cents: '0.4'},
              {name: 'B5', cents: '0.6'},
              {name: 'C6', cents: '0.8'},
              {name: 'C#6', cents: '1.1'},
              {name: 'D6', cents: '1.4'},
              {name: 'D#6', cents: '1.7'},
              {name: 'E6', cents: '2'},
              {name: 'F6', cents: '2.4'},
              {name: 'F#6', cents: '2.8'},
              {name: 'G6', cents: '3.2'},
              {name: 'G#6', cents: '3.6'},
              {name: 'A6', cents: '4.1'},
              {name: 'A#6', cents: '4.6'},
              {name: 'B6', cents: '5.1'},
              {name: 'C7', cents: '5.6'},
              {name: 'C#7', cents: '6.1'},
              {name: 'D7', cents: '6.7'},
              {name: 'D#7', cents: '7.3'},
              {name: 'E7', cents: '8.1'},
              {name: 'F7', cents: '8.9'},
              {name: 'F#7', cents: '9.9'},
              {name: 'G7', cents: '11'},
              {name: 'G#7', cents: '12.2'},
              {name: 'A7', cents: '13.5'},
              {name: 'A#7', cents: '14.9'},
              {name: 'B7', cents: '16.5'},
              {name: 'C8', cents: '18.3'},
            ],
          },
          {
            name: 'Concert grand (>2,4 m)',
            noteElement: [
              {name: 'A0', cents: '-6.5'},
              {name: 'A#0', cents: '-6'},
              {name: 'B0', cents: '-5.5'},
              {name: 'C1', cents: '-5'},
              {name: 'C#1', cents: '-4.6'},
              {name: 'D1', cents: '-4.2'},
              {name: 'D#1', cents: '-3.8'},
              {name: 'E1', cents: '-3.4'},
              {name: 'F1', cents: '-3.1'},
              {name: 'F#1', cents: '-2.8'},
              {name: 'G1', cents: '-2.5'},
              {name: 'G#1', cents: '-2.2'},
              {name: 'A1', cents: '-1.9'},
              {name: 'A#1', cents: '-1.6'},
              {name: 'B1', cents: '-1.4'},
              {name: 'C2', cents: '-1.2'},
              {name: 'C#2', cents: '-1'},
              {name: 'D2', cents: '-0.8'},
              {name: 'D#2', cents: '-0.6'},
              {name: 'E2', cents: '-0.4'},
              {name: 'F2', cents: '-0.3'},
              {name: 'F#2', cents: '-0.2'},
              {name: 'G2', cents: '-0.1'},
              {name: 'G#2', cents: '0'},
              {name: 'A2', cents: '0'},
              {name: 'A#2', cents: '0'},
              {name: 'B2', cents: '0'},
              {name: 'C3', cents: '0'},
              {name: 'C#3', cents: '0'},
              {name: 'D3', cents: '0'},
              {name: 'D#3', cents: '0'},
              {name: 'E3', cents: '0'},
              {name: 'F3', cents: '0'},
              {name: 'F#3', cents: '0'},
              {name: 'G3', cents: '0'},
              {name: 'G#3', cents: '0'},
              {name: 'A3', cents: '0'},
              {name: 'A#3', cents: '0'},
              {name: 'B3', cents: '0'},
              {name: 'C4', cents: '0'},
              {name: 'C#4', cents: '0'},
              {name: 'D4', cents: '0'},
              {name: 'D#4', cents: '0'},
              {name: 'E4', cents: '0'},
              {name: 'F4', cents: '0'},
              {name: 'F#4', cents: '0'},
              {name: 'G4', cents: '0'},
              {name: 'G#4', cents: '0'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0'},
              {name: 'B4', cents: '0'},
              {name: 'C5', cents: '0'},
              {name: 'C#5', cents: '0'},
              {name: 'D5', cents: '0'},
              {name: 'D#5', cents: '0'},
              {name: 'E5', cents: '0'},
              {name: 'F5', cents: '0'},
              {name: 'F#5', cents: '0'},
              {name: 'G5', cents: '0'},
              {name: 'G#5', cents: '0'},
              {name: 'A5', cents: '0'},
              {name: 'A#5', cents: '0'},
              {name: 'B5', cents: '0'},
              {name: 'C6', cents: '0.1'},
              {name: 'C#6', cents: '0.2'},
              {name: 'D6', cents: '0.3'},
              {name: 'D#6', cents: '0.5'},
              {name: 'E6', cents: '0.7'},
              {name: 'F6', cents: '0.9'},
              {name: 'F#6', cents: '1.2'},
              {name: 'G6', cents: '1.5'},
              {name: 'G#6', cents: '1.8'},
              {name: 'A6', cents: '2.2'},
              {name: 'A#6', cents: '2.6'},
              {name: 'B6', cents: '3'},
              {name: 'C7', cents: '3.5'},
              {name: 'C#7', cents: '4'},
              {name: 'D7', cents: '4.5'},
              {name: 'D#7', cents: '5.1'},
              {name: 'E7', cents: '5.7'},
              {name: 'F7', cents: '6.3'},
              {name: 'F#7', cents: '7'},
              {name: 'G7', cents: '7.7'},
              {name: 'G#7', cents: '8.4'},
              {name: 'A7', cents: '9.2'},
              {name: 'A#7', cents: '10'},
              {name: 'B7', cents: '10.9'},
              {name: 'C8', cents: '11.8'},
            ],
          },
          {
            name: 'SoftStretch [-12 +10]',
            noteElement: [
              {name: 'A0', cents: '-12'},
              {name: 'A#0', cents: '-11.75'},
              {name: 'B0', cents: '-11.5'},
              {name: 'C1', cents: '-11.25'},
              {name: 'C#1', cents: '-11'},
              {name: 'D1', cents: '-10.75'},
              {name: 'D#1', cents: '-10.5'},
              {name: 'E1', cents: '-10.25'},
              {name: 'F1', cents: '-10'},
              {name: 'F#1', cents: '-9.75'},
              {name: 'G1', cents: '-9.5'},
              {name: 'G#1', cents: '-9.25'},
              {name: 'A1', cents: '-9'},
              {name: 'A#1', cents: '-8.75'},
              {name: 'B1', cents: '-8.5'},
              {name: 'C2', cents: '-8.25'},
              {name: 'C#2', cents: '-8'},
              {name: 'D2', cents: '-7.75'},
              {name: 'D#2', cents: '-7.5'},
              {name: 'E2', cents: '-7.25'},
              {name: 'F2', cents: '-7'},
              {name: 'F#2', cents: '-6.75'},
              {name: 'G2', cents: '-6.5'},
              {name: 'G#2', cents: '-6.25'},
              {name: 'A2', cents: '-6'},
              {name: 'A#2', cents: '-5.75'},
              {name: 'B2', cents: '-5.5'},
              {name: 'C3', cents: '-5.25'},
              {name: 'C#3', cents: '-5'},
              {name: 'D3', cents: '-4.75'},
              {name: 'D#3', cents: '-4.5'},
              {name: 'E3', cents: '-4.25'},
              {name: 'F3', cents: '-4'},
              {name: 'F#3', cents: '-3.75'},
              {name: 'G3', cents: '-3.5'},
              {name: 'G#3', cents: '-3.25'},
              {name: 'A3', cents: '-3'},
              {name: 'A#3', cents: '-2.75'},
              {name: 'B3', cents: '-2.5'},
              {name: 'C4', cents: '-2.25'},
              {name: 'C#4', cents: '-2'},
              {name: 'D4', cents: '-1.75'},
              {name: 'D#4', cents: '-1.5'},
              {name: 'E4', cents: '-1.25'},
              {name: 'F4', cents: '-1'},
              {name: 'F#4', cents: '-0.75'},
              {name: 'G4', cents: '-0.5'},
              {name: 'G#4', cents: '-0.25'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0.25'},
              {name: 'B4', cents: '0.5'},
              {name: 'C5', cents: '0.75'},
              {name: 'C#5', cents: '1'},
              {name: 'D5', cents: '1.25'},
              {name: 'D#5', cents: '1.5'},
              {name: 'E5', cents: '1.75'},
              {name: 'F5', cents: '2'},
              {name: 'F#5', cents: '2.25'},
              {name: 'G5', cents: '2.5'},
              {name: 'G#5', cents: '2.75'},
              {name: 'A5', cents: '3'},
              {name: 'A#5', cents: '3.25'},
              {name: 'B5', cents: '3.5'},
              {name: 'C6', cents: '3.75'},
              {name: 'C#6', cents: '4'},
              {name: 'D6', cents: '4.25'},
              {name: 'D#6', cents: '4.5'},
              {name: 'E6', cents: '4.75'},
              {name: 'F6', cents: '5'},
              {name: 'F#6', cents: '5.25'},
              {name: 'G6', cents: '5.5'},
              {name: 'G#6', cents: '5.75'},
              {name: 'A6', cents: '6'},
              {name: 'A#6', cents: '6.25'},
              {name: 'B6', cents: '6.5'},
              {name: 'C7', cents: '6.75'},
              {name: 'C#7', cents: '7'},
              {name: 'D7', cents: '7.25'},
              {name: 'D#7', cents: '7.5'},
              {name: 'E7', cents: '7.75'},
              {name: 'F7', cents: '8'},
              {name: 'F#7', cents: '8.25'},
              {name: 'G7', cents: '8.5'},
              {name: 'G#7', cents: '8.75'},
              {name: 'A7', cents: '9'},
              {name: 'A#7', cents: '9.25'},
              {name: 'B7', cents: '9.5'},
              {name: 'C8', cents: '9.75'},
            ],
          },
          {
            name: 'MeanStretch [-18 +15]',
            noteElement: [
              {name: 'A0', cents: '-18'},
              {name: 'A#0', cents: '-17.63'},
              {name: 'B0', cents: '-17.25'},
              {name: 'C1', cents: '-16.88'},
              {name: 'C#1', cents: '-16.5'},
              {name: 'D1', cents: '-16.13'},
              {name: 'D#1', cents: '-15.75'},
              {name: 'E1', cents: '-15.38'},
              {name: 'F1', cents: '-15'},
              {name: 'F#1', cents: '-14.63'},
              {name: 'G1', cents: '-14.25'},
              {name: 'G#1', cents: '-13.88'},
              {name: 'A1', cents: '-13.5'},
              {name: 'A#1', cents: '-13.13'},
              {name: 'B1', cents: '-12.75'},
              {name: 'C2', cents: '-12.38'},
              {name: 'C#2', cents: '-12'},
              {name: 'D2', cents: '-11.63'},
              {name: 'D#2', cents: '-11.25'},
              {name: 'E2', cents: '-10.88'},
              {name: 'F2', cents: '-10.5'},
              {name: 'F#2', cents: '-10.13'},
              {name: 'G2', cents: '-9.75'},
              {name: 'G#2', cents: '-9.38'},
              {name: 'A2', cents: '-9'},
              {name: 'A#2', cents: '-8.63'},
              {name: 'B2', cents: '-8.25'},
              {name: 'C3', cents: '-7.88'},
              {name: 'C#3', cents: '-7.5'},
              {name: 'D3', cents: '-7.13'},
              {name: 'D#3', cents: '-6.75'},
              {name: 'E3', cents: '-6.38'},
              {name: 'F3', cents: '-6'},
              {name: 'F#3', cents: '-5.63'},
              {name: 'G3', cents: '-5.25'},
              {name: 'G#3', cents: '-4.88'},
              {name: 'A3', cents: '-4.5'},
              {name: 'A#3', cents: '-4.13'},
              {name: 'B3', cents: '-3.75'},
              {name: 'C4', cents: '-3.38'},
              {name: 'C#4', cents: '-3'},
              {name: 'D4', cents: '-2.63'},
              {name: 'D#4', cents: '-2.25'},
              {name: 'E4', cents: '-1.88'},
              {name: 'F4', cents: '-1.5'},
              {name: 'F#4', cents: '-1.13'},
              {name: 'G4', cents: '-0.75'},
              {name: 'G#4', cents: '-0.38'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0.38'},
              {name: 'B4', cents: '0.75'},
              {name: 'C5', cents: '1.13'},
              {name: 'C#5', cents: '1.5'},
              {name: 'D5', cents: '1.88'},
              {name: 'D#5', cents: '2.25'},
              {name: 'E5', cents: '2.63'},
              {name: 'F5', cents: '3'},
              {name: 'F#5', cents: '3.38'},
              {name: 'G5', cents: '3.75'},
              {name: 'G#5', cents: '4.13'},
              {name: 'A5', cents: '4.5'},
              {name: 'A#5', cents: '4.88'},
              {name: 'B5', cents: '5.25'},
              {name: 'C6', cents: '5.63'},
              {name: 'C#6', cents: '6'},
              {name: 'D6', cents: '6.38'},
              {name: 'D#6', cents: '6.75'},
              {name: 'E6', cents: '7.13'},
              {name: 'F6', cents: '7.5'},
              {name: 'F#6', cents: '7.88'},
              {name: 'G6', cents: '8.25'},
              {name: 'G#6', cents: '8.63'},
              {name: 'A6', cents: '9'},
              {name: 'A#6', cents: '9.38'},
              {name: 'B6', cents: '9.75'},
              {name: 'C7', cents: '10.13'},
              {name: 'C#7', cents: '10.5'},
              {name: 'D7', cents: '10.88'},
              {name: 'D#7', cents: '11.25'},
              {name: 'E7', cents: '11.63'},
              {name: 'F7', cents: '12'},
              {name: 'F#7', cents: '12.38'},
              {name: 'G7', cents: '12.75'},
              {name: 'G#7', cents: '13.13'},
              {name: 'A7', cents: '13.5'},
              {name: 'A#7', cents: '13.88'},
              {name: 'B7', cents: '14.25'},
              {name: 'C8', cents: '14.63'},
            ],
          },
          {
            name: 'StrongStretch [-24 +20]',
            noteElement: [
              {name: 'A0', cents: '-24'},
              {name: 'A#0', cents: '-23.5'},
              {name: 'B0', cents: '-23'},
              {name: 'C1', cents: '-22.5'},
              {name: 'C#1', cents: '-22'},
              {name: 'D1', cents: '-21.5'},
              {name: 'D#1', cents: '-21'},
              {name: 'E1', cents: '-20.5'},
              {name: 'F1', cents: '-20'},
              {name: 'F#1', cents: '-19.5'},
              {name: 'G1', cents: '-19'},
              {name: 'G#1', cents: '-18.5'},
              {name: 'A1', cents: '-18'},
              {name: 'A#1', cents: '-17.5'},
              {name: 'B1', cents: '-17'},
              {name: 'C2', cents: '-16.5'},
              {name: 'C#2', cents: '-16'},
              {name: 'D2', cents: '-15.5'},
              {name: 'D#2', cents: '-15'},
              {name: 'E2', cents: '-14.5'},
              {name: 'F2', cents: '-14'},
              {name: 'F#2', cents: '-13.5'},
              {name: 'G2', cents: '-13'},
              {name: 'G#2', cents: '-12.5'},
              {name: 'A2', cents: '-12'},
              {name: 'A#2', cents: '-11.5'},
              {name: 'B2', cents: '-11'},
              {name: 'C3', cents: '-10.5'},
              {name: 'C#3', cents: '-10'},
              {name: 'D3', cents: '-9.5'},
              {name: 'D#3', cents: '-9'},
              {name: 'E3', cents: '-8.5'},
              {name: 'F3', cents: '-8'},
              {name: 'F#3', cents: '-7.5'},
              {name: 'G3', cents: '-7'},
              {name: 'G#3', cents: '-6.5'},
              {name: 'A3', cents: '-6'},
              {name: 'A#3', cents: '-5.5'},
              {name: 'B3', cents: '-5'},
              {name: 'C4', cents: '-4.5'},
              {name: 'C#4', cents: '-4'},
              {name: 'D4', cents: '-3.5'},
              {name: 'D#4', cents: '-3'},
              {name: 'E4', cents: '-2.5'},
              {name: 'F4', cents: '-2'},
              {name: 'F#4', cents: '-1.5'},
              {name: 'G4', cents: '-1'},
              {name: 'G#4', cents: '-0.5'},
              {name: 'A4', cents: '0'},
              {name: 'A#4', cents: '0.5'},
              {name: 'B4', cents: '1'},
              {name: 'C5', cents: '1.5'},
              {name: 'C#5', cents: '2'},
              {name: 'D5', cents: '2.5'},
              {name: 'D#5', cents: '3'},
              {name: 'E5', cents: '3.5'},
              {name: 'F5', cents: '4'},
              {name: 'F#5', cents: '4.5'},
              {name: 'G5', cents: '5'},
              {name: 'G#5', cents: '5.5'},
              {name: 'A5', cents: '6'},
              {name: 'A#5', cents: '6.5'},
              {name: 'B5', cents: '7'},
              {name: 'C6', cents: '7.5'},
              {name: 'C#6', cents: '8'},
              {name: 'D6', cents: '8.5'},
              {name: 'D#6', cents: '9'},
              {name: 'E6', cents: '9.5'},
              {name: 'F6', cents: '10'},
              {name: 'F#6', cents: '10.5'},
              {name: 'G6', cents: '11'},
              {name: 'G#6', cents: '11.5'},
              {name: 'A6', cents: '12'},
              {name: 'A#6', cents: '12.5'},
              {name: 'B6', cents: '13'},
              {name: 'C7', cents: '13.5'},
              {name: 'C#7', cents: '14'},
              {name: 'D7', cents: '14.5'},
              {name: 'D#7', cents: '15'},
              {name: 'E7', cents: '15.5'},
              {name: 'F7', cents: '16'},
              {name: 'F#7', cents: '16.5'},
              {name: 'G7', cents: '17'},
              {name: 'G#7', cents: '17.5'},
              {name: 'A7', cents: '18'},
              {name: 'A#7', cents: '18.5'},
              {name: 'B7', cents: '19'},
              {name: 'C8', cents: '19.5'},
            ],
          },

          {
            name: 'Stretch (Fender-Rhodes)',
            noteElement: [
              {
                name: 'A0',
                cents: '-20',
                superscript: '1',
              },
              {
                name: 'A#0',
                cents: '-19',
                superscript: '2',
              },
              {
                name: 'B0',
                cents: '-18',
                superscript: '3',
              },
              {
                name: 'C1',
                cents: '-17',
                superscript: '4',
              },
              {
                name: 'C#1',
                cents: '-16',
                superscript: '5',
              },
              {
                name: 'D1',
                cents: '-15',
                superscript: '6',
              },
              {
                name: 'D#1',
                cents: '-14',
                superscript: '7',
              },
              {
                name: 'E1',
                cents: '-13',
                superscript: '8',
              },
              {
                name: 'F1',
                cents: '-12',
                superscript: '9',
              },
              {
                name: 'F#1',
                cents: '-11',
                superscript: '10',
              },
              {
                name: 'G1',
                cents: '-10',
                superscript: '11',
              },
              {
                name: 'G#1',
                cents: '-9',
                superscript: '12',
              },
              {
                name: 'A1',
                cents: '-8',
                superscript: '13',
              },
              {
                name: 'A#1',
                cents: '-7',
                superscript: '14',
              },
              {
                name: 'B1',
                cents: '-6',
                superscript: '15',
              },
              {
                name: 'C2',
                cents: '-6',
                superscript: '16',
              },
              {
                name: 'C#2',
                cents: '-5',
                superscript: '17',
              },
              {
                name: 'D2',
                cents: '-5',
                superscript: '18',
              },
              {
                name: 'D#2',
                cents: '-4',
                superscript: '19',
              },
              {
                name: 'E2',
                cents: '-4',
                superscript: '20',
              },
              {
                name: 'F2',
                cents: '-4',
                superscript: '21',
              },
              {
                name: 'F#2',
                cents: '-4',
                superscript: '22',
              },
              {
                name: 'G2',
                cents: '-4',
                superscript: '23',
              },
              {
                name: 'G#2',
                cents: '-3',
                superscript: '24',
              },
              {
                name: 'A2',
                cents: '-3',
                superscript: '25',
              },
              {
                name: 'A#2',
                cents: '-3',
                superscript: '26',
              },
              {
                name: 'B2',
                cents: '-3',
                superscript: '27',
              },
              {
                name: 'C3',
                cents: '-3',
                superscript: '28',
              },
              {
                name: 'C#3',
                cents: '-3',
                superscript: '29',
              },
              {
                name: 'D3',
                cents: '-3',
                superscript: '30',
              },
              {
                name: 'D#3',
                cents: '-3',
                superscript: '31',
              },
              {
                name: 'E3',
                cents: '-3',
                superscript: '32',
              },
              {
                name: 'F3',
                cents: '-3',
                superscript: '33',
              },
              {
                name: 'F#3',
                cents: '-2.5',
                superscript: '34',
              },
              {
                name: 'G3',
                cents: '-2.5',
                superscript: '35',
              },
              {
                name: 'G#3',
                cents: '-2',
                superscript: '36',
              },
              {
                name: 'A3',
                cents: '-2',
                superscript: '37',
              },
              {
                name: 'A#3',
                cents: '-2',
                superscript: '38',
              },
              {
                name: 'B3',
                cents: '-2',
                superscript: '39',
              },
              {
                name: 'C4',
                cents: '-2',
                superscript: '40',
              },
              {
                name: 'C#4',
                cents: '-2',
                superscript: '41',
              },
              {
                name: 'D4',
                cents: '-2',
                superscript: '42',
              },
              {
                name: 'D#4',
                cents: '-1.5',
                superscript: '43',
              },
              {
                name: 'E4',
                cents: '-1',
                superscript: '44',
              },
              {
                name: 'F4',
                cents: '-1',
                superscript: '45',
              },
              {
                name: 'F#4',
                cents: '-1',
                superscript: '46',
              },
              {
                name: 'G4',
                cents: '-1',
                superscript: '47',
              },
              {
                name: 'G#4',
                cents: '-1',
                superscript: '48',
              },
              {
                name: 'A4',
                cents: '0',
                superscript: '49',
              },
              {
                name: 'A#4',
                cents: '0',
                superscript: '50',
              },
              {
                name: 'B4',
                cents: '0',
                superscript: '51',
              },
              {
                name: 'C5',
                cents: '0',
                superscript: '52',
              },
              {
                name: 'C#5',
                cents: '0',
                superscript: '53',
              },
              {
                name: 'D5',
                cents: '0',
                superscript: '54',
              },
              {
                name: 'D#5',
                cents: '1',
                superscript: '55',
              },
              {
                name: 'E5',
                cents: '1',
                superscript: '56',
              },
              {
                name: 'F5',
                cents: '1',
                superscript: '57',
              },
              {
                name: 'F#5',
                cents: '1',
                superscript: '58',
              },
              {
                name: 'G5',
                cents: '1',
                superscript: '59',
              },
              {
                name: 'G#5',
                cents: '2',
                superscript: '60',
              },
              {
                name: 'A5',
                cents: '2',
                superscript: '61',
              },
              {
                name: 'A#5',
                cents: '2',
                superscript: '62',
              },
              {
                name: 'B5',
                cents: '2',
                superscript: '63',
              },
              {
                name: 'C6',
                cents: '2',
                superscript: '64',
              },
              {
                name: 'C#6',
                cents: '3',
                superscript: '65',
              },
              {
                name: 'D6',
                cents: '3',
                superscript: '66',
              },
              {
                name: 'D#6',
                cents: '3',
                superscript: '67',
              },
              {
                name: 'E6',
                cents: '4',
                superscript: '68',
              },
              {
                name: 'F6',
                cents: '4',
                superscript: '69',
              },
              {
                name: 'F#6',
                cents: '5',
                superscript: '70',
              },
              {
                name: 'G6',
                cents: '5',
                superscript: '71',
              },
              {
                name: 'G#6',
                cents: '6',
                superscript: '72',
              },
              {
                name: 'A6',
                cents: '6',
                superscript: '73',
              },
              {
                name: 'A#6',
                cents: '7',
                superscript: '74',
              },
              {
                name: 'B6',
                cents: '8',
                superscript: '75',
              },
              {
                name: 'C7',
                cents: '9',
                superscript: '76',
              },
              {
                name: 'C#7',
                cents: '10',
                superscript: '77',
              },
              {
                name: 'D7',
                cents: '11',
                superscript: '78',
              },
              {
                name: 'D#7',
                cents: '12',
                superscript: '79',
              },
              {
                name: 'E7',
                cents: '13',
                superscript: '80',
              },
              {
                name: 'F7',
                cents: '15',
                superscript: '81',
              },
              {
                name: 'F#7',
                cents: '17',
                superscript: '82',
              },
              {
                name: 'G7',
                cents: '19',
                superscript: '83',
              },
              {
                name: 'G#7',
                cents: '21',
                superscript: '84',
              },
              {
                name: 'A7',
                cents: '23',
                superscript: '85',
              },
              {
                name: 'A#7',
                cents: '25',
                superscript: '86',
              },
              {
                name: 'B7',
                cents: '27',
                superscript: '87',
              },
              {
                name: 'C8',
                cents: '30',
                superscript: '88',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Violin',
    subInstrument: [
      {
        name: '4 String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'G3',
                symbol: 'G',
                subscript: '3',
              },
              {
                name: 'D4',
                symbol: 'D',
                subscript: '4',
              },
              {
                name: 'A4',
                symbol: 'A',
                subscript: '4',
              },
              {
                name: 'E5',
                symbol: 'E',
                subscript: '5',
              },
            ],
          },
          {
            name: 'Just Intonation',
            noteElement: [
              {
                name: 'G3',
                cents: '-3.9',
              },
              {
                name: 'D4',
                cents: '-2.0',
              },
              {
                name: 'A4',
                cents: '0',
              },
              {
                name: 'E5',
                cents: '0',
              },
            ],
          },
        ],
      },

      {
        name: '5 String',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'C3',
                symbol: 'C',
                subscript: '3',
              },
              {
                name: 'G3',
                symbol: 'G',
                subscript: '3',
              },
              {
                name: 'D4',
                symbol: 'D',
                subscript: '4',
              },
              {
                name: 'A4',
                symbol: 'A',
                subscript: '4',
              },
              {
                name: 'E5',
                symbol: 'E',
                subscript: '5',
              },
            ],
          },
          {
            name: 'Just Intonation',
            noteElement: [
              {
                name: 'C3',
                cents: '-5.9',
              },
              {
                name: 'G3',
                cents: '-3.9',
              },
              {
                name: 'D4',
                cents: '-2.0',
              },
              {
                name: 'A4',
              },
              {
                name: 'E5',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Banjo',
    subInstrument: [
      {
        name: '4-String',
        tuning: [
          {
            name: 'Tenor C3 G3 D4 A4',
            noteElement: [
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'D4',
              },
              {
                name: 'A4',
              },
            ],
          },
          {
            name: 'Irish Tenor G2 D3 A3 E4',
            noteElement: [
              {
                name: 'G2',
              },
              {
                name: 'D3',
              },
              {
                name: 'A3',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Plectrum C3 G3 B3 D4',
            noteElement: [
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'D4',
              },
            ],
          },
        ],
      },
      {
        name: '5-String',
        tuning: [
          {
            name: 'G G4-D3-G3-B3-D4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'C G4-C3-G3-B3-D4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'B3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'D A4-D3-F#3-A3-D4',
            noteElement: [
              {
                name: 'A4',
              },
              {
                name: 'D3',
              },
              {
                name: 'F#3',
              },
              {
                name: 'A3',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Double C G4-C3-G3-C4-D4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'D4',
              },
            ],
          },
          {
            name: 'Open C G4-C3-G3-C4-E4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'E4',
              },
            ],
          },
          {
            name: 'Open Cm G4-C3-G3-C4-Eb4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'C3',
              },
              {
                name: 'G3',
              },
              {
                name: 'C4',
              },
              {
                name: 'F#4',
              },
            ],
          },
          {
            name: 'Open Gm G4-D3-G3-Bb3-D4',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'D3',
              },
              {
                name: 'G3',
              },
              {
                name: 'Bb3',
              },
              {
                name: 'D4',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Mandolin',
    subInstrument: [
      {
        name: 'Mandolin',
        tuning: [
          {
            name: 'Standard',
            noteElement: [
              {
                name: 'G4',
              },
              {
                name: 'D4',
              },
              {
                name: 'A4',
              },
              {
                name: 'E5',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Upright Bass',
    subInstrument: [
      {
        name: '4-String',
        tuning: [
          {
            name: 'E1-A1-D2-G2',
            noteElement: [
              {
                name: 'E1',
                symbol: '4',
                subscript: '41.20',
              },
              {
                name: 'A1',
                symbol: '3',
                subscript: '55.00',
              },
              {
                name: 'D2',
                symbol: '2',
                subscript: '73.42',
              },
              {
                name: 'G2',
                symbol: '1',
                subscript: '98.00',
              },
            ],
          },
          {
            name: 'F#1-B1-E2-A2',
            noteElement: [
              {
                name: 'F#1',
                symbol: '4',
              },
              {
                name: 'B1',
                symbol: '3',
              },
              {
                name: 'E2',
                symbol: '2',
              },
              {
                name: 'A2',
                symbol: '1',
              },
            ],
          },
          {
            name: 'C1-G1-D2-A2',
            noteElement: [
              {
                name: 'C1',
                symbol: '4',
                subscript: '32.70',
              },
              {
                name: 'G1',
                symbol: '3',
                subscript: '49.00',
              },
              {
                name: 'D2',
                symbol: '2',
                subscript: '73.42',
              },
              {
                name: 'A2',
                symbol: '1',
                subscript: '110.00',
              },
            ],
          },
          {
            name: 'C1-G1-D2-A2',
            noteElement: [
              {
                name: 'C1',
                symbol: '4',
                superscript: 'C1',
                subscript: '32.70',
              },
              {
                name: 'G1',
                symbol: '3',
                superscript: 'G1',
                subscript: '49.00',
              },
              {
                name: 'D2',
                symbol: '2',
                superscript: 'D2',
                subscript: '73.42',
              },
              {
                name: 'A2',
                symbol: '1',
                superscript: 'A2',
                subscript: '110.00',
              },
            ],
          },
        ],
      },
      {
        name: '5-String',
        tuning: [
          {
            name: 'C1-G1-D2-A2-E3',
            noteElement: [
              {
                name: 'C1',
                symbol: '5',
                subscript: '32.70',
              },
              {
                name: 'G1',
                symbol: '4',
                subscript: '49.00',
              },
              {
                name: 'D2',
                symbol: '3',
                subscript: '73.42',
              },
              {
                name: 'A2',
                symbol: '3',
                subscript: '110.00',
              },
              {
                name: 'E3',
                symbol: '1',
                subscript: '164.81',
              },
            ],
          },
        ],
      },
      {
        name: '6-String',
        tuning: [
          {
            name: 'B0-E1-A1-D2-G2-C3',
            noteElement: [
              {
                name: 'B0',
                symbol: '6',
                superscript: 'B0',
                subscript: '30.87',
              },
              {
                name: 'E1',
                symbol: '5',
                superscript: 'E1',
                subscript: '41.20',
              },
              {
                name: 'A1',
                symbol: '4',
                superscript: 'A1',
                subscript: '55.00',
              },
              {
                name: 'D2',
                symbol: '3',
                superscript: 'D2',
                subscript: '73.42',
              },
              {
                name: 'G2',
                symbol: '2',
                superscript: 'G2',
                subscript: '98.00',
              },
              {
                name: 'C3',
                symbol: '1',
                superscript: 'C3',
                subscript: '130.81',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Nyckelharpa',
    subInstrument: [
      {
        name: 'Olsson',
        tuning: [
          {
            name: 'Olsson',
            noteElement: [
              {
                cents: '-2.000',
                name: 'A',
              },
              {
                cents: '4.000',
                name: 'A#',
              },
              {
                cents: '-6.000',
                name: 'B',
              },
              {
                cents: '4.000',
                name: 'C',
              },
              {
                cents: '-2.000',
                name: 'C#',
              },
              {
                cents: '0.000',
                name: 'D',
              },
              {
                cents: '2.000',
                name: 'D#',
              },
              {
                cents: '-4.000',
                name: 'E',
              },
              {
                cents: '6.000',
                name: 'F',
              },
              {
                cents: '-4.000',
                name: 'F#',
              },
              {
                cents: '2.000',
                name: 'G',
              },
              {
                cents: '0.000',
                name: 'G#',
              },
            ],
          },
        ],
      },
      {
        name: 'Soeren',
        tuning: [
          {
            name: 'Soeren 1',
            noteElement: [
              {
                cents: '0.000',
                name: 'A',
              },
              {
                cents: '2.000',
                name: 'A#',
              },
              {
                cents: '2.000',
                name: 'B',
              },
              {
                cents: '2.000',
                name: 'C',
              },
              {
                cents: '-3.000',
                name: 'C#',
              },
              {
                cents: '0.000',
                name: 'D',
              },
              {
                cents: '-4.000',
                name: 'D#',
              },
              {
                cents: '-4.000',
                name: 'E',
              },
              {
                cents: '1.000',
                name: 'F',
              },
              {
                cents: '1.000',
                name: 'F#',
              },
              {
                cents: '2.000',
                name: 'G',
              },
              {
                cents: '2.000',
                name: 'G#',
              },
            ],
          },
          {
            name: 'Soeren 2',
            noteElement: [
              {
                cents: '-2.000',
                name: 'A',
              },
              {
                cents: '0.000',
                name: 'A#',
              },
              {
                cents: '0.000',
                name: 'B',
              },
              {
                cents: '0.000',
                name: 'C',
              },
              {
                cents: '-5.000',
                name: 'C#',
              },
              {
                cents: '-2.000',
                name: 'D',
              },
              {
                cents: '-6.000',
                name: 'D#',
              },
              {
                cents: '-6.000',
                name: 'E',
              },
              {
                cents: '-1.000',
                name: 'F',
              },
              {
                cents: '-1.000',
                name: 'F#',
              },
              {
                cents: '0.000',
                name: 'G',
              },
              {
                cents: '0.000',
                name: 'G#',
              },
            ],
          },
        ],
      },
    ],
  },
];
