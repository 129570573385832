import React from 'react';
import { useSpring as useSpringGl, animated as animGl } from 'react-spring/three';

import { Text as TextD } from '@react-three/drei';
import { IDLE_COLOR } from './gl-params';

// Create an AnimatedText component from drei's text component.
const AnimatedTextGl = animGl(TextD);

// -----------------------------------------------------------------------------
type NoteProps = {
  text?: string;
  small?: boolean;
  up?: boolean;
  isAnalyzing: boolean;
  textColor: string;
};

const YPOS_3D_STUFF = 20;
const NTGL_FONT_SIZE_SCALED_UP = 75;
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
export function NoteTextGl(nprops: NoteProps) {
  const { small, up, isAnalyzing, textColor } = nprops;

  const fontSize = small ? NTGL_FONT_SIZE_SCALED_UP / 10 : NTGL_FONT_SIZE_SCALED_UP / 5;
  const anchorX = small ? 'left' : 'right';
  const anchorY = !small ? 'middle' : up ? 'bottom' : 'top';

  const spring = useSpringGl({
    opacity: isAnalyzing ? 1 : 0.001,
    color: isAnalyzing ? textColor : IDLE_COLOR,
  });

  return (
    <animGl.mesh>
      <AnimatedTextGl
        color={spring.color}
        position={[0, YPOS_3D_STUFF, 0]}
        textAlign='center'
        fontSize={fontSize}
        anchorX={anchorX}
        anchorY={anchorY}
        attach='geometry'
      >
        {nprops.text}
      </AnimatedTextGl>
      <animGl.meshPhysicalMaterial
        color={spring.color}
        transparent={true}
        reflectivity={0.9}
        flatShading={false}
        roughness={1}
        clearcoat={0.8}
        opacity={spring.opacity}
      />
    </animGl.mesh>
  );
}
