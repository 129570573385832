import React, { CSSProperties } from 'react';
import { Typography } from '@material-ui/core';
export type NoteViewProps = {
  color?: string;
  note: string;
  big?: boolean;
  style?: any;
};

export function NoteView(props: NoteViewProps) {
  const color = props.color ? props.color : 'lightgrey';
  const style: CSSProperties = { textAlign: 'center', color, ...props.style };
  const variant = props.big ? 'h4' : 'body1';
  return (
    <Typography variant={variant} style={style} gutterBottom>
      {props.note}
    </Typography>
  );
}
