import React, { useEffect, useState } from 'react';
import { ListItem, ListItemText, makeStyles, createStyles, Theme } from '@material-ui/core';
import * as Midi from './sharedSrc/src/headers/midi';
import { useSpring, animated as anim } from 'react-spring/web';
import { useSelector } from 'react-redux';
import { dbg } from './sharedSrc/src/debug/debug';
import { RootState } from '@abstractions/redux_inst';
import { arraysEqual, roundFrequencyStr } from './sharedSrc/src/utils/utils';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { ReduxSpecificNote } from './sharedSrc/src/redux/redux';
import { DatunerNoteToNormalNote } from './sharedSrc/src/headers/note_defs';

export const RH_DRAWER_SIZE = 100;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listDiv: {
      width: RH_DRAWER_SIZE,
      margin: 0,
      padding: 0,
      paddingTop: 40,
      paddingBottom: 40,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    animatedContainerDiv: {
      margin: 0,
      padding: 0,
      backgroundColor: 'transparent',
    },
  }),
);

export interface RhNoteSelectorSelection extends ReduxSpecificNote {
  index: number;
}

// Required properties for the notes list.
export interface NotesListProps {
  maxHeight: number;
  style?: any;
  noteSelected?: (selected: null | RhNoteSelectorSelection) => void;
}

// -----------------------------------------------------------------------------
// Allows for a note to be selected, then issues a redux action when the note is selected.
export const RhNoteSelector = (noteListProps: NotesListProps) => {
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState<null | RhNoteSelectorSelection>(null);
  const [lastFreqsAry, setLastFreqsAry] = useState<number[]>([]);

  const { notesAry, octavesAry, tagsAry, freqsAry } = useSelector((state: RootState) => {
    if (!arraysEqual(lastFreqsAry, state.system.activeNotesList.freqsAry)) {
      return { ...state.system.activeNotesList };
    } else {
      return state.system.activeNotesList;
    }
  });

  function renderRow(props: ListChildComponentProps) {
    // eslint-disable-next-line
    const { index, style } = props;
    if (index < 0 || index >= freqsAry.length) {
      return <ListItem key={-1} style={style} button />;
    } else {
      const isSelected = !!selectedItem && selectedItem.index === index;
      const _n = notesAry[index];
      const _o = octavesAry[index];
      const { n, o } = DatunerNoteToNormalNote(_n, _o);

      const nstr = Midi.getCOffsetName(n);
      const ostr = o.toString();
      const freq = freqsAry[index];

      return (
        <ListItem
          button
          selected={isSelected}
          style={style}
          key={index}
          onClick={() => {
            if (isSelected) {
              if (selectedItem !== null) {
                setSelectedItem(null);
                if (noteListProps.noteSelected) {
                  noteListProps.noteSelected(null);
                }
              }
            } else {
              const item: RhNoteSelectorSelection = {
                index,
                freq: freqsAry[index],
                note: notesAry[index],
                octave: octavesAry[index],
                tag: tagsAry[index],
              };
              if (selectedItem !== item) {
                setSelectedItem(item);
                if (noteListProps.noteSelected) {
                  noteListProps.noteSelected(item);
                }
              }
            }
          }}
        >
          <ListItemText primary={nstr + ' ' + ostr} secondary={roundFrequencyStr(freq) + 'Hz'} />
        </ListItem>
      );
    }
  }

  useEffect(() => {
    if (
      octavesAry.length === freqsAry.length &&
      freqsAry.length === notesAry.length &&
      tagsAry.length === freqsAry.length
    ) {
      if (!arraysEqual(freqsAry, lastFreqsAry)) {
        setLastFreqsAry(freqsAry);
        if (selectedItem !== null) {
          if (noteListProps.noteSelected) {
            noteListProps.noteSelected(null);
          }
          setSelectedItem(null);
        }
      } else {
        dbg.log('Ignoring new freqs. Same as the old freqs.');
      }
    } else {
      dbg.log("Not setting freqs array because lengths don't match.");
    }
  }, [notesAry, octavesAry, freqsAry, tagsAry, lastFreqsAry, noteListProps, selectedItem]);

  return (
    <div
      className={classes.listDiv}
      style={{
        ...noteListProps.style,
        height: noteListProps.maxHeight,
      }}
    >
      <FixedSizeList
        height={noteListProps.maxHeight}
        width={RH_DRAWER_SIZE}
        itemSize={46}
        itemCount={lastFreqsAry.length}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
};

// -----------------------------------------------------------------------------
const AnimRhNoteSelector = anim(RhNoteSelector);

// -----------------------------------------------------------------------------
export interface AnimatedRhNoteSelectorProps extends NotesListProps {
  notesListOpen: boolean;
  height: number;
  left: number;
}

// -----------------------------------------------------------------------------
export const AnimatedRhNoteSelector = (props: AnimatedRhNoteSelectorProps) => {
  const classes = useStyles();
  const [notesListIsAnimating, setNotesListIsAnimating] = useState(false);
  const [notesListCurrentlyOpen, setNotesListCurrentlyOpen] = useState(false);

  useEffect(() => {
    if (props.notesListOpen !== notesListCurrentlyOpen) {
      setNotesListCurrentlyOpen(props.notesListOpen);
    }
  }, [props, notesListCurrentlyOpen]);

  const animatedHeight: any = useSpring({
    from: props.notesListOpen ? { height: 0 } : { height: props.height },
    to: !props.notesListOpen ? { height: 0 } : { height: props.height },

    onStart: () => {
      setNotesListIsAnimating(true);
    },
    onRest: () => {
      setNotesListIsAnimating(false);
    },
  });

  return notesListCurrentlyOpen || notesListIsAnimating ? (
    <div
      className={classes.animatedContainerDiv}
      style={{
        top: 100,
        zIndex: 2,
        position: 'absolute',
        width: RH_DRAWER_SIZE,
        left: props.left,
      }}
    >
      <AnimRhNoteSelector {...props} maxHeight={animatedHeight.height} />
    </div>
  ) : (
    <></>
  );
};
// -----------------------------------------------------------------------------
