export const en = {
  en: {
    translation: {
      s_About: 'Strobopro is a Strobe Tuner App for Android, iOS, and Web.\n\n',
      s_AboutThisApp: 'About this app',
      s_Cancel: 'Cancel',
      s_Choose: 'Choose',
      s_CloseSettings: 'Close settings',
      s_CookieConsent:
        'This website uses cookies to enhance the user experience. We do not use cookies to store personal information. By clicking Accept, you consent to the use of cookies on this website.',
      s_Contact: 'Contact',
      s_CurrentlyOn: 'Currently on',
      s_CurrentlyOff: 'Currently off',
      s_DataCollectionPolicy: 'Data collection policy',
      s_EmailTemplate:
        'Friendly suggestion about StroboPro&body=Hi developer, I have some feedback for you...',
      s_EmailTheDeveloper: 'Email the developer',
      s_FFTSize: 'FFT Size',
      s_GetTuningsFromStroboPro: 'Get tunings from StroboPro.se',
      s_GlowEffects: 'Glow effects',
      s_HelpAboutPro:
        'You have the Premium version. Thank you for your support! ?\n\n',
      s_HelpAdvancedSettings:
        'Advanced features such as FFT size used for note detection, sensitivity, type of strobes, etc.',
      s_HelpEstimatedCents:
        'The estimated cents value is the difference between the estimated frequency and the nearest note.\n\nWhen in doubt, look at the strobes themselves!\n\n(<_<)',
      s_HelpFftSize:
        'Higher FFT Size has more accurate results but consumes more CPU.\n\n' +
        'Lower FFT Size is faster but less accurate.\n\n' +
        'The FFT is used to determine which note is playing.\n\n' +
        'If your device is having trouble deciphering the correct notes, try switching FFT size.\n\n' +
        'The default value is ',
      s_HelpGlow:
        'Glow effects add a bit of extra pizazz, but may slow down the app on older devices.',
      s_HelpInstrument: 'Choose an instrument and tuning from the list.',
      s_HelpInstrumentPro:
        '\n\nIf you cannot find your instrument or tuning, you may add or customize tunings in the web app, strobopro.se.\n\nAfter logging in, this app will download your custom instruments and tunings from strobopro.se.',
      s_HelpNoteLock:
        "For those times when the tuner just can't figure out which note you're playing, you can use the note locker.\n\nThe note locker will try to lock the tuner to the note you select.\n\nThis will lock the strobes to a specific frequency.\n\nPlease note that it will detect this frequency from nearly any input now due to the very specific filtering to lock to the note.\n\nClick the lock icon to the left of the note locker to close it again.",
      s_HelpRating:
        'If you like it, please consider giving us a nice rating on the store, it really helps!\n\n⭐️⭐️⭐️⭐️⭐️',
      s_HelpReferenceFrequency:
        'The reference frequency is the frequency of the A4 note.\n\nThe default is 440Hz, but you can change it to 432Hz or any other value.',
      s_HelpSensitivity:
        'Decrease sensitivity if your device is spending too much time idling.\n\nIncrease sensitivity if your device always picking up and reacting to background noise.\n\nThe default value is 9 dB.',
      s_HelpStatusCents:
        'This displays the estimated error in cents, derived from the strobes\n\nWhen in doubt, look at the strobes themselves!\n\n(<_<)',
      s_HelpStatusFrequency:
        'This displays the estimated frequency, derived from the strobes.\n\nIt is averaged, and can be a bit finicky.',
      s_HelpStrobeDisplayType:
        'Velocity mode is better for slower devices as current strobe velocity is checked and then the strobes are animated using that same velocity at whatever frame rate your device can handle.\n\nPosition mode is more like the way a real strobe tuner works, where current position is sampled and then the strobe is moved to that position, but this only works well on faster devices.',
      s_HelpTransposition:
        'Transposition is useful for instruments that are tuned to a different pitch than A440.\n\nExample: B-flat clarinets, where playing a written C sounds a B-flat.\n\nC->Bb would convert a detected C to a displayed Bb.',
      s_Instructions: 'Instructions',
      s_listening: 'listening',
      s_Login: 'Login',
      s_Logout: 'Logout',
      s_LogoutToClearSettings: 'Logout to clear settings',
      s_LongPressItem: 'Long press an item for help',
      s_NavigateToAdvanced: 'Navigate to advanced settings menu',
      s_NoneChromatic: 'None (Chromatic)',
      s_Off: 'Off',
      s_On: 'On',
      s_Position: 'Position',
      s_PrivacyPolicy: 'Privacy Policy',
      s_Reset: 'Reset',
      s_START: 'START',
      s_SettingsHint: 'Navigate to the settings screen',
      s_StrobeDisplayType: 'Strobe Display Type',
      s_TapToChange: 'Tap to change',
      s_TapToChangeTo: 'Tap to change to ',
      s_TitleAboutStrobopro: 'About Strobopro',
      s_TitleAddInstrument: 'Add Instrument',
      s_TitleAdvanced: 'Advanced',
      s_TitleChooseTuning: 'Choose Tuning',
      s_TitleHelp: 'Help',
      s_TitleInstrument: 'Instrument',
      s_TitleNoteLock: 'Note Lock',
      s_TitlePaywall: 'Paywall',
      s_TitleReferenceFrequencyHz: 'Reference Frequency (Hz)',
      s_HintReferenceFrequencyHz: 'Tap to change reference frequency',
      s_TitleSensitivity: 'Sensitivity',
      s_TitleSettings: 'Settings',
      s_TitleSignIn: 'SignIn',
      s_TitleStatusCents: 'Strobe Cents',
      s_TitleStatusFrequency: 'Strobe Frequency',
      s_TitleTransposition: 'Transposition',
      s_TitleTuner: 'Tuner',
      s_UpgradeToPremium: 'Upgrade to Premium',
      s_Velocity: 'Velocity',
      s_HintSettings: 'Navigate to the settings screen',
      s_HelpFrequencyDisp: 'Help - Frequency',
      s_HelpCents: 'Help - Cents',
      s_TitleEstimatedCents: 'Estimated Cents',
      s_HintNoteItem: 'This is one of the notes in the mapping',
      s_TitlePreviousNote: 'Previous Note',
      s_HelpPreviousNote: 'Selects the previous note',
      s_TitleNextNote: 'Next Note',
      s_HelpNextNote: 'Selects the next note',
      s_TitleMicrophone: 'Microphone sensitivity',
      s_HelpMicrophone: 'Tap to change microphone sensitivity',
      s_TitleNoteLockToggle: 'Note Lock Toggle',

      s_SignInToDeleteData: 'Login to delete your data',
      s_DeleteDataAndSignOut: 'Delete Data and Log Out',
      s_SignInToSync:
        'Sign in to enable synchronization of your settings and instruments.',
      s_AreYouSureDeleteData: 'Are you sure you want to delete your data?',
      s_LogOutToDisableSync:
        'Log out to disable synchronization of your settings and instruments?',
      s_YesDeleteDataAndLogOut: 'Yes, Delete My Data and Log Out',
      s_YesLogOut: 'Yes, Log Out',
      s_GooglePlayWarning:
        'You are about to delete all data stored by Applicaudia AB for the app Strobopro.\n' +
        'To proceed, please log in to ensure that you own the data to be deleted.\n' +
        'All of your data will be deleted, encompassing\n\n' +
        '\t- instrument preferences,\n' +
        '\t- custom tunings and settings,\n' +
        '\t- device IDs and device-unique settings,\n' +
        '\t- user IDs,\n' +
        '\t- and timestamps.\n' +
        '\nNo data will be retained after deletion.',
    },
  },
};
