export const sv = {
  sv: {
    translation: {
      s_About: 'Strobopro är en Strobe Tuner App för Android, iOS och Web.\n\n',
      s_AboutThisApp: 'Om appen',
      s_Cancel: 'Avbryt',
      s_Choose: 'Välj',
      s_CloseSettings: 'Stäng inställningar',
      s_CookieConsent:
        'Denna webbplats använder cookies för att förbättra användarupplevelsen. Vi använder inte cookies för att lagra personlig information. Genom att klicka på Acceptera samtycker du till användningen av cookies på denna webbplats.',
      s_Contact: 'Kontakt',
      s_CurrentlyOn: 'För närvarande på',
      s_CurrentlyOff: 'För närvarande av',
      s_DataCollectionPolicy: 'Dataskyddspolicy',
      s_EmailTemplate:
        'Vänligt förslag om StroboPro&body=Hej utvecklare, jag har några tips för dig...',
      s_EmailTheDeveloper: 'Kontakta utvecklaren',
      s_FFTSize: 'FFT-storlek',
      s_GetTuningsFromStroboPro: 'Hämta stämningar från StroboPro.se',
      s_GlowEffects: 'Glow-effekter',
      s_HelpAboutPro: 'Du har Premium-versionen. Tack för ditt stöd! 🙏\n\n',
      s_HelpAdvancedSettings:
        'Avancerade funktioner som FFT-storlek som används för tonupptäckt, känslighet, typ av strobes, etc.',
      s_HelpEstimatedCents:
        'Det uppskattade cents-värdet är skillnaden mellan den uppskattade frekvensen och den närmaste tonen.\n\nNär du är osäker, titta på strobes själva!\n\n(<_<)',
      s_HelpFftSize:
        'Högre FFT-storlek ger mer exakta resultat men förbrukar mer CPU.\n\n' +
        'Lägre FFT-storlek är snabbare men mindre exakt.\n\n' +
        'FFT används för att bestämma vilken ton som spelas.\n\n' +
        'Om din enhet har svårt att avgöra de korrekta tonerna, prova att byta FFT-storlek.\n\n' +
        'Standardvärdet är ',
      s_HelpGlow:
        'Glow-effekter lägger till lite extra pizzazz, men kan sakta ner appen på äldre mobiler.',
      s_HelpInstrument: 'Välj ett instrument och stämning från listan.',
      s_HelpInstrumentPro:
        '\n\nOm du inte hittar ditt instrument eller stämning kan du lägga till eller anpassa stämningar i webbappen, strobopro.se.\n\nEfter inloggning kommer denna app att ladda ner dina anpassade instrument och stämningar från strobopro.se.',
      s_HelpNoteLock:
        'För de gånger då stämapparaten inte kan avgöra vilken ton du spelar kan du använda notlåset.\n\nNotlåset kommer att försöka låsa stämapparaten till den valda tonen.\n\nDetta låser strobes till en specifik frekvens.\n\nObservera att den kommer att upptäcka denna frekvens från nästan vilken ingång som helst på grund av den mycket specifika filtreringen för att låsa till noten.\n\nKlicka på låsikonen till vänster om notlåset för att stänga det igen.',
      s_HelpRating:
        'Om du gillar den, överväg att ge oss en fin betyg i butiken, det hjälper verkligen!\n\n⭐️⭐️⭐️⭐️⭐️',
      s_HelpReferenceFrequency:
        'Referensfrekvensen är frekvensen för tonen A4.\n\nStandardvärdet är 440Hz, men du kan ändra det till 432Hz eller vilket annat värde som helst.',
      s_HelpSensitivity:
        'Minska känsligheten om din enhet spenderar för mycket tid på att vara inaktiv.\n\nÖka känsligheten om din enhet alltid plockar upp och reagerar på bakgrundsljud.\n\nStandardvärdet är 9 dB.',
      s_HelpStatusCents:
        'Detta visar det uppskattade felet i cents, härledd från strobes.\n\nNär du är osäker, titta på strobes själva!\n\n(<_<)',
      s_HelpStatusFrequency:
        'Detta visar den uppskattade frekvensen, härledd från strobes.\n\nDen är genomsnittlig och kan vara lite kinkig.',
      s_HelpStrobeDisplayType:
        'Velocity-läge är bättre för långsammare mobiler eftersom nuvarande strobehastighet kontrolleras och sedan animeras strobes med samma hastighet vid vilken bildfrekvens din enhet kan hantera.\n\nPositionsläge liknar mer hur en riktig strobe tuner fungerar, där nuvarande position är provtagen och sedan flyttas stroben till den positionen, men detta fungerar bara bra på snabbare mobiler.',
      s_HelpTransposition:
        'Transposition är användbart för instrument som är stämda till en annan tonhöjd än A440.\n\nExempel: Bb-klarinetter, där en spelad C låter som en Bb.\n\nC->Bb skulle konvertera en upptäckt C till en visad Bb.',
      s_Instructions: 'Instruktioner',
      s_listening: 'lyssnar',
      s_Login: 'Logga in',
      s_Logout: 'Logga ut',
      s_LogoutToClearSettings: 'Logga ut för att rensa inställningarna',
      s_LongPressItem: 'Långtryck på en artikel för hjälp',
      s_NavigateToAdvanced: 'Navigera till avancerade inställningsmenyn',
      s_NoneChromatic: 'Ingen (Kromatisk)',
      s_Off: 'Av',
      s_On: 'På',
      s_Reset: 'Återställ',
      s_Position: 'Position',
      s_PrivacyPolicy: 'Integritetspolicy',
      s_START: 'BÖRJA',
      s_SettingsHint: 'Navigera till inställningsskärmen',
      s_StrobeDisplayType: 'Stroboskoptyp',
      s_TapToChange: 'Tryck för att ändra',
      s_TapToChangeTo: 'Tryck för att ändra till ',
      s_TitleAboutStrobopro: 'Om Strobopro',
      s_TitleAddInstrument: 'Lägg till Instrument',
      s_TitleAdvanced: 'Avancerad',
      s_TitleChooseTuning: 'Välj stämning',
      s_TitleHelp: 'Hjälp',
      s_TitleInstrument: 'Instrument',
      s_TitleNoteLock: 'Lås not',
      s_TitlePaywall: 'Betalvägg',
      s_TitleReferenceFrequencyHz: 'Referens Frekvens (Hz)',
      s_HintReferenceFrequencyHz: 'Tryck för att ändra referensfrekvens',
      s_TitleSensitivity: 'Känslighet',
      s_TitleSettings: 'Inställningar',
      s_TitleSignIn: 'Logga in',
      s_TitleStatusCents: 'Strobe Cents',
      s_TitleStatusFrequency: 'Strobe Frekvens',
      s_TitleTransposition: 'Transponering',
      s_TitleTuner: 'Stämapparat',
      s_UpgradeToPremium: 'Uppgradera till premium',
      s_Velocity: 'Hastighet',
      s_HintSettings: 'Navigera till inställningsskärmen',
      s_HelpFrequencyDisp: 'Hjälp - Frekvens',
      s_HelpCents: 'Hjälp - Cents',
      s_TitleEstimatedCents: 'Uppskattade Cents',
      s_HintNoteItem: 'Detta är en av noterna i kartläggningen',
      s_TitlePreviousNote: 'Föregående Not',
      s_HelpPreviousNote: 'Väljer föregående not',
      s_TitleNextNote: 'Nästa Not',
      s_HelpNextNote: 'Väljer nästa not',
      s_TitleMicrophone: 'Mikrofon känslighet',
      s_HelpMicrophone: 'Tryck för att ändra mikrofonens känslighet',
      s_TitleNoteLockToggle: 'Not lås växling',

      s_SignInToDeleteData: 'Logga in för att radera dina data',
      s_DeleteDataAndSignOut: 'Radera data och logga ut',
      s_SignInToSync: 'Logga in för att aktivera synk.',
      s_AreYouSureDeleteData: 'Är du säker på att du vill radera dina data?',
      s_LogOutToDisableSync: 'Logga ut för att stänga av synk?',
      s_YesDeleteDataAndLogOut: 'Ja, radera mina data och logga ut',
      s_YesLogOut: 'Ja, logga ut',

      s_GooglePlayWarning:
        'Du är på väg att radera alla data som lagras av Applicaudia AB för appen Strobopro.\n' +
        'För att fortsätta, logga in för att säkerställa att du äger data som ska raderas.\n' +
        'All din data kommer att raderas, inklusive\n\n' +
        '\t- instrumentinställningar,\n' +
        '\t- anpassade stämningar och inställningar,\n' +
        '\t- enhets-ID och enhetsspecifika inställningar,\n' +
        '\t- användar-ID,\n' +
        '\t- och tidsstämplar.\n' +
        '\nIngen data kommer att behållas efter radering.',
    },
  },
};
