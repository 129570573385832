import React, { useEffect, useState, useCallback } from 'react';
import { useSpring, animated as anim, config } from 'react-spring/web';

import './knob2.css';

export interface KnobProps {
  degrees: number;
  numTicks: number;
  size: number;
  min: number;
  max: number;
  color: boolean;
  style?: any;
}
const ticksAngle = 270;
const ticksStart = 0 + (360 - ticksAngle) / 2;
const tickSize = 0.18;

// ----------------------------------------------------------------------------
export function Knob2(props: KnobProps) {
  const [currentDegrees, setCurrentDegrees] = useState(props.degrees);
  const outerSize = props.size;
  const innerSize = props.size * (1.0 - tickSize);

  const outerStyle = {
    width: outerSize,
    height: outerSize,
  };

  const [innerStyle, setInnerStyle] = useState({
    width: innerSize,
    height: innerSize,
  });

  useEffect(() => {
    setInnerStyle({ width: innerSize, height: innerSize });
  }, [innerSize]);

  useEffect(() => {
    if (props.degrees !== currentDegrees) {
      setCurrentDegrees(props.degrees);
    }
  }, [props.degrees, currentDegrees]);

  const animatedRotation = useSpring({
    to: { degrees: currentDegrees },
    config: config.stiff,
  });

  const renderTicksJsx = useCallback((): JSX.Element[] => {
    const rel = (props.max - props.min) / 270;
    const sizeDiff = outerSize - innerSize;
    const isActive = (currentValue: number, tickDegrees: number) => {
      const mul = tickDegrees * rel;
      const rval = mul <= currentValue ? ' active' : '';
      return rval;
    };

    let ticksJsx: JSX.Element[] = [];
    if (props.numTicks) {
      const ticks = [];
      {
        const incr = 270 / props.numTicks;
        const size = innerSize / 2 + sizeDiff / 2;

        for (let _deg = 0; _deg <= 270; _deg += incr) {
          const visibleDeg = ticksStart + _deg;
          const tick = {
            deg: _deg,
            tickStyle: {
              height: size,
              left: size - 1,
              top: size + 2,
              transform: 'rotate(' + visibleDeg + 'deg)',
              transformOrigin: 'top',
            },
          };
          ticks.push(tick);
        }
      }

      ticksJsx = ticks.map((tick, i) => {
        return (
          <div
            key={i}
            className={'tick' + isActive(currentDegrees, tick.deg)}
            style={tick.tickStyle}
          />
        );
      });
    }
    return ticksJsx;
  }, [props.numTicks, props.max, props.min, innerSize, outerSize, currentDegrees]);

  return (
    <div className='knob view'>
      <div className='ticks' style={outerStyle}>
        {renderTicksJsx()}
      </div>

      <div className='knob' style={innerStyle}>
        <div className='knob outer' style={innerStyle}>
          {
            <anim.div
              className='knob inner'
              style={{
                ...innerStyle,
                transform: animatedRotation.degrees.to((degrees: number) => {
                  return 'rotate(' + degrees + 'deg)';
                }),
              }}
            >
              <div className='grip' />
            </anim.div>
          }
        </div>
      </div>
    </div>
  );
}
