// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/database';

import {dbg} from '@common/debug/debug';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD3Rg2v30ZN_yHyBZJegUCJtt75OVPf9B8',
  authDomain: 'strobo-pro.firebaseapp.com',
  projectId: 'strobo-pro',
  storageBucket: 'strobo-pro.appspot.com',
  messagingSenderId: '587356447474',
  appId: '1:587356447474:web:7290c567363805170ee66b',
  measurementId: 'G-73ZRM4HG2H',
};

export type DataSnapshot = firebase.database.DataSnapshot;

// --------------------------------------------------------------------------
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const _db: firebase.database.Database = firebase
  .app()
  .database(
    'https://strobo-pro-default-rtdb.europe-west1.firebasedatabase.app',
  );

let _inst: null | FirebaseClass = null;

export class FirebaseClass {
  uid: string = '';

  static inst(): FirebaseClass {
    if (null === _inst) {
      _inst = new FirebaseClass();
    }
    return _inst;
  }

  db() {
    return _db;
  }

  initDatabase(uid: string) {
    this.uid = uid;
  }

  doLogout(): Promise<void> {
    return firebase.auth().signOut();
  }
  // ---
  hi() {
    dbg.log('firebase::hello');
  }

  onAuthStateChanged(cb: (user: firebase.User | null) => void) {
    firebase.auth().onAuthStateChanged(user => {
      cb(user);
    });
  }

  deleteAllUserData(uid: string): Promise<void> {
    if (uid.length === 0) {
      return Promise.resolve();
    }
    const db = FirebaseClass.inst().db();
    const p = new Promise<void>((resolve, _reject) => {
      dbg.log('FirebaseAsyncStorage::Deleting user ' + uid);
      db.ref()
        .child('/users_settings/' + uid)
        .remove()
        .then(() => {
          dbg.log('FirebaseAsyncStorage::Deleted user ' + uid + ' settings.');
          db.ref()
            .child('/device_settings/' + uid)
            .remove()
            .then(() => {
              db.ref()
                .child('/users_private/' + uid)
                .remove()
                .then(() => {
                  dbg.log(
                    'FirebaseAsyncStorage::Deleted user ' + uid + ' private.',
                  );
                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      resolve();
                    });
                })
                .catch((reason: any) => {
                  dbg.warn(
                    'FirebaseAsyncStorage::Error when deleting user ' +
                      uid +
                      ' private. ',
                    reason,
                  );
                })
                .finally(() => {
                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      resolve();
                    });
                });
            })
            .catch((reason: any) => {
              dbg.warn(
                'FirebaseAsyncStorage::Error when deleting user ' +
                  uid +
                  ' private. ',
                reason,
              );
            });
        })
        .catch((reason: any) => {
          dbg.warn(
            'FirebaseAsyncStorage::Error when deleting user ' +
              uid +
              ' settings. ',
            reason,
          );
        });
    });
    return p;
  }
}

export const FbPlatform = {
  OS: 'web',
};

export {firebase};
