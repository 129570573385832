import React, {useState} from 'react';
import {Container} from '@material-ui/core';
import {KnobScreen} from './knob_screen';
import {Dispatch} from '../../redux/redux';
import {RootState} from '@abstractions/redux_inst';
import {useDispatch, useSelector} from 'react-redux';
import {ChooseInstrumentScreen} from './choose_instrument';
import {ChooseTranspositionScreen} from './choose_transposition';
import {ChooseAudioInScreen} from './choose_audio_in';
import {SignInScreen} from './sign-in';
import {
  PersistentDrawerButtonEvents,
  PersistentDrawerLeftChild,
  PersistentDrawerLeftProps,
} from './persistent_drawer_left_child';
import '../../../../App.css';
import {dbg} from '../../debug/debug';
import {useHistory} from 'react-router-dom';

export interface TunerMenuProps extends PersistentDrawerLeftProps {
  changeNoteListState?: () => void;
}

export function PersistentDrawerLeft(props: TunerMenuProps) {
  const [choosingInstrument, setChoosingInstrument] = useState(false);
  const [choosingTransposition, setChoosingTransposition] = useState(false);
  const [choosingInputDevice, setChoosingInputDevice] = useState(false);
  const [_loggingIn, setLoggingIn] = useState(false);
  const [knobScreenTitle, setKnobScreenTitle] = useState('');
  const history = useHistory();
  const refFreq = useSelector((state: RootState) => {
    return state.system.referenceFreq;
  });

  // Display signin always
  const isDeletingData = window.location.href.includes('delete_me.html');
  const loggingIn = isDeletingData || _loggingIn;

  const dispatch = useDispatch();
  return (
    <>
      <PersistentDrawerLeftChild
        {...props}
        onPressItem={(itemIdx: number) => {
          switch (itemIdx) {
            case PersistentDrawerButtonEvents.REF_FREQ:
              // Reference frequency
              setKnobScreenTitle('Reference Frequency (Hz)');
              break;
            case PersistentDrawerButtonEvents.TRANSPOSITION:
              setChoosingTransposition(true);
              break;

            case PersistentDrawerButtonEvents.MICROPHONE:
              setChoosingInputDevice(true);
              break;

            case PersistentDrawerButtonEvents.INSTRUMENT:
              setChoosingInstrument(true);
              break;

            case PersistentDrawerButtonEvents.INSTRUMENT_EDITOR:
              history.push('/instruments');
              break;

            case PersistentDrawerButtonEvents.LOGIN_LOGOUT:
              dbg.log('Login');
              setLoggingIn(true);
              break;

            case PersistentDrawerButtonEvents.TOGGLE_NOTES_LIST:
              dbg.log('Toggling Notes List');
              if (props.changeNoteListState) {
                props.changeNoteListState();
              }
              break;

            case PersistentDrawerButtonEvents.EMAIL_SUPPORT:
              dbg.log('Emailing support');
              break;

            default:
              setKnobScreenTitle('');
              break;
          }
        }}
      >
        {props.children}
      </PersistentDrawerLeftChild>
      {choosingInstrument && (
        <ChooseInstrumentScreen
          open={choosingInstrument}
          onClose={() => {
            setChoosingInstrument(false);
          }}
        />
      )}
      {choosingTransposition && (
        <ChooseTranspositionScreen
          open={choosingTransposition}
          onClose={() => {
            setChoosingTransposition(false);
          }}
        />
      )}
      {choosingInputDevice && (
        <ChooseAudioInScreen
          open={choosingInputDevice}
          onClose={() => {
            setChoosingInputDevice(false);
          }}
        />
      )}
      {loggingIn && (
        <SignInScreen
          open={loggingIn}
          onClose={() => {
            setLoggingIn(false);
          }}
        />
      )}
      {knobScreenTitle.length > 0 && (
        <Container>
          <KnobScreen
            width={window.innerWidth}
            height={window.innerHeight}
            top={0}
            left={0}
            isOpen={knobScreenTitle.length > 0}
            onClose={(fHz: number) => {
              setKnobScreenTitle('');
              dispatch(Dispatch.setRefFreq(fHz));
            }}
            title={knobScreenTitle}
            val={{
              curr: refFreq,
              min: 220,
              max: 880,
              default: 440,
              rotations: 20,
            }}
          />
        </Container>
      )}
    </>
  );
}
