import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {Cancel as CancelIcon} from '@material-ui/icons';

import '../../../../App.css';
import {RootState} from '@abstractions/redux_inst';
import {
  BottomNavigation,
  BottomNavigationAction,
  DialogTitle,
  Dialog,
  Typography,
  Button,
} from '@material-ui/core';

import {dbg} from '@common/debug/debug';
import {firebase} from '@abstractions/firebase';
import {FirebaseAsyncStorage} from '@common/firebase_storage';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const appleProvider = new firebase.auth.OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

export type SignInProps = {
  open: boolean;
  onClose: () => void;
};

// -----------------------------------------------------------------------------
// This is the sign-in screen. It is shown when the user clicks on the
// "Sign In" button in the left-hand side menu.

export function SignInScreen(props: SignInProps) {
  const {onClose} = props;
  const isDeletingData = window.location.href.includes('delete_me.html');
  const [error, setError] = useState('');
  // Configure FirebaseUI.
  const uiConfig: firebaseui.auth.Config = {
    // Popup signin flow rather than redirect flow.
    popupMode: true,
    signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href)
      ? 'redirect'
      : 'popup',
    callbacks: {
      signInFailure: (err: any) => {
        dbg.err('signInFailure: with code, message:', err.code, err.message);
        setError(err.message);
        return Promise.resolve();
      },
      signInSuccessWithAuthResult: (
        // tslint:disable-next-line:no-any firebase dependency not available.
        authResult: any,
        redirectUrl?: string,
      ): boolean => {
        dbg.log('signInSuccessWithAuthResult: ', authResult, redirectUrl);
        if (onClose) {
          onClose();
        }
        return true;
      },

      uiShown: () => {
        dbg.log('uiShown.');
      },
    },
    privacyPolicyUrl: 'https://strobopro.se/privacy.html',
    tosUrl: 'https://strobopro.se/terms_of_service.html',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        requireDisplayName: true,
      },
      {
        provider: appleProvider.providerId,
        signInMethod: appleProvider.providerId,
        requireDisplayName: true,
      },
    ],
  };
  const {t} = useTranslation();

  const uid = useSelector((state: RootState) => {
    return state.system.firebaseUid;
  });
  const history = useHistory();
  const signin = isDeletingData ? t('s_SignInToDeleteData') : t('s_Login');
  const signout = isDeletingData ? t('s_DeleteDataAndSignOut') : t('s_Logout');
  const signInHelp = isDeletingData
    ? t('s_SignInToDeleteData')
    : t('s_SignInToSync');
  const signOutHelp = isDeletingData
    ? t('s_AreYouSureDeleteData')
    : t('s_LogOutToDisableSync');
  const logOutConfirm = isDeletingData
    ? t('s_YesDeleteDataAndLogOut')
    : t('s_YesLogOut');
  return (
    <Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="simple-dialog-title">
        {uid.length > 0 ? signout : signin}
      </DialogTitle>
      <div className="Settings" style={{flexDirection: 'column', flex: 1}}>
        {isDeletingData && (
          <Typography align="left" margin={10} paragraph style={{whiteSpace: 'pre-line'}}>
            {t('s_GooglePlayWarning')}
          </Typography>
        )}
        {uid.length === 0 && (
          <div>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
              uiCallback={(_uiCbData: firebaseui.auth.AuthUI) => {
                dbg.logObj('Got UI callback: ', _uiCbData); // , JSON.stringify(ui, null, 2));
              }}
            />

            <Typography gutterBottom>{signInHelp}</Typography>
          </div>
        )}
        {uid.length > 0 && (
          <div>
            <Typography gutterBottom>{signOutHelp}</Typography>
            <Button
              className={'MuiButton-containedSecondary'}
              style={{margin: 10, padding: 20}}
              onClick={() => {
                if (isDeletingData) {
                  dbg.log('DeleteMeScreen: onClick for user id ' + uid);
                  FirebaseAsyncStorage.inst()
                    .deleteAllUserData()
                    .then(() => {
                      history.push('/');
                      window.location.reload();
                    });
                } else {
                  firebase.auth().signOut();
                }
                if (onClose) {
                  onClose();
                }

              }}
            >
              <Typography variant="h5">{logOutConfirm}</Typography>
            </Button>
          </div>
        )}
        <Typography gutterBottom>{error}</Typography>
      </div>
      <div className="Small" style={{flexDirection: 'row', flex: 0.2}}></div>
      <BottomNavigation
        style={{width: '100%', paddingBottom: 10, paddingTop: 10}}
        onChange={(event: string, newValue: any) => {
          dbg.log('onChange: with event ' + event, newValue);
          switch (newValue) {
            default:
              if (onClose) {
                onClose();
              }
              if (isDeletingData) {
                history.push('/');
                window.location.reload();
              }
              break;
          }
        }}
        showLabels
        // className={classes.root}
      >
        <BottomNavigationAction label="Cancel" icon={<CancelIcon />} />
      </BottomNavigation>
      <div className="Small" style={{flexDirection: 'row', flex: 0.1}}></div>
    </Dialog>
  );
}
