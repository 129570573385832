import {Snackbar, Typography} from '@material-ui/core';
import React, {useState, useCallback, useMemo} from 'react';

export type MouseEvents = {
  onMouseOver: () => void;
  onFocus: () => void;
  onMouseLeave: () => void;
  onBlur: () => void;
};

/* tslint:disable class-name */
export interface useSnackbarHelpOut {
  // Call this to set the snackbar help viewer
  setHelp: (h: string) => void;
  // Clear the help
  clearHelp: () => void;
  // Show a snackbar somewhere on the screen
  Snackbar: JSX.Element;
  mouseEvents: {
    onMouseLeave: () => void;
  };
  mouseAndFocusEvents: (h?: string) => MouseEvents;
}

export type MySnackbarProps = {
  help: string;
  onClose: () => void;
  helpKey?: string;
};

export function MySnackbar(props: MySnackbarProps) {
  return (
    <Snackbar
      open={props.help.length > 0}
      onClose={props.onClose}
      key={props.helpKey ? props.helpKey : 'helpKey'}
      autoHideDuration={3000}>
      <Typography>{props.help}</Typography>
    </Snackbar>
  );
}

export default function useSnackbarHelp(
  defaultHelp: string = '',
): useSnackbarHelpOut {
  const [help, doSetHelp] = useState(defaultHelp);

  const clearHelp = useCallback(() => {
    doSetHelp('');
  }, []);

  const snackbar = useMemo(() => {
    return <MySnackbar help={help} onClose={clearHelp} />;
  }, [help, clearHelp]);

  const rval: useSnackbarHelpOut = useMemo(() => {
    return {
      // Call this to set the snackbar help viewer
      setHelp: (h: string) => {
        doSetHelp(h);
      },
      // Clear the help
      clearHelp,
      // Show a snackbar somewhere on the screen
      Snackbar: snackbar,
      mouseEvents: {
        onMouseLeave: clearHelp,
      },
      mouseAndFocusEvents: (h?: string): MouseEvents => {
        return {
          onMouseOver: () => {
            doSetHelp(h ? h : defaultHelp);
          },
          onFocus: () => {
            doSetHelp(h ? h : defaultHelp);
          },
          onMouseLeave: clearHelp,
          onBlur: clearHelp,
        };
      },
    };
  }, [defaultHelp, snackbar, clearHelp]);
  return rval;
}

export function getMouseEvents(
  str: string,
  _onMouseOver: (str: string) => void,
  _onMouseOut: () => void,
) {
  return {
    onMouseOver: () => {
      _onMouseOver(str);
    },
    onFocus: () => {
      _onMouseOver(str);
    },
    onMouseLeave: _onMouseOut,
    onBlur: _onMouseOut,
  };
}
