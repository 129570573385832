export class KalmanFilter {
  q: number; // process noise covariance
  r: number; // measurement noise covariance
  p: number; // estimation error covariance
  x: number; // value

  update(measurement: number) {
    this.p = this.p + this.q; // prediction uncertainty
    const k = this.p / (this.p + this.r); // kalman gain
    this.x = this.x + k * (measurement - this.x); // corrected value
    this.p = (1 - k) * this.p; // new uncertainty
    return this.x; // return value
  }

  reset(measurement: number) {
    // this.p = this.p + this.q; // prediction uncertainty
    // this.x = 0;
    this.x = measurement;
    this.p = 1;
    return this.x; // return value
  }

  constructor(
    q: number = 0.00001, // set higher for faster convergence
    r: number = 0.01, // set higher for faster convergence
    p: number = 1,
    x: number = 0,
  ) {
    this.q = q; // process noise covariance, is constant
    this.r = r; // measurement noise covariance, is constant
    this.p = p; // initial estimation error covariance
    this.x = x; // value
  }
}
