import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {
  GraphicEq,
  ChevronLeft,
  ChevronRight,
  Menu,
  TextRotateVertical,
  Straighten,
  EditOutlined,
  Help,
  Info,
  Mic,
  LockOpen,
  ExitToApp,
  MusicNote,
  Cancel,
  Email,
  SignalCellularAltOutlined,
  Delete,
} from '@material-ui/icons';
import {useDispatch, useSelector} from 'react-redux';
import {CHROMATIC, Dispatch} from '../../redux/redux';
import {transpositionStrings} from '../../headers/note_defs';
import {RootState} from '@abstractions/redux_inst';
import {useTranslation} from 'react-i18next';

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    titleBar: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),

      backgroundColor: 'rgba(1,1,1,0.3)',
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    drawerHeaderOverlap: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      justifyContent: 'space-around',
    },
    drawerHeaderBelowAppBar: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      ...theme.mixins.toolbar,
      justifyContent: 'space-around',
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(1),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

interface MailToProps {
  email: string;
  subject: string;
  body: string;
  children?: JSX.Element | JSX.Element[];
}

export interface PersistentDrawerLeftProps {
  children?: JSX.Element;
  placeContentUnderAppBar?: boolean;
  notesListIsOpen: boolean;
  showNotesListIcon: boolean;
}

export interface PersistentDrawerLeftPropsPrivate
  extends PersistentDrawerLeftProps {
  onPressItem: (itemIdx: number) => void;
}

export enum PersistentDrawerButtonEvents {
  REF_FREQ = 0,
  TRANSPOSITION = 1,
  INSTRUMENT = 2,
  INSTRUMENT_EDITOR = 3,
  MICROPHONE = 4,
  INSTRUCTIONS = 5,
  PRIVACY_POLICY = 6,
  LOGIN_LOGOUT = 7,
  TOGGLE_NOTES_LIST = 8,
  EMAIL_SUPPORT = 9,
}

type MyListItemProps = {
  primary: string;
  secondary: string;
  onClick?: () => void;
  children: JSX.Element | JSX.Element[];
};

function MyListItem(props: MyListItemProps) {
  return (
    <ListItem button key={props.primary} onClick={props.onClick}>
      <ListItemIcon>{props.children}</ListItemIcon>
      <ListItemText primary={props.primary} secondary={props.secondary} />
    </ListItem>
  );
}

function Mailto(props: MailToProps) {
  const {email, subject, body, children} = props;
  const params = [];
  if (subject) params.push([`subject=${encodeURIComponent(subject)}`]);
  if (body) params.push([`body=${encodeURIComponent(body)}`]);
  const paramString = params.length > 0 ? `?${params.join('&')}` : '';
  const mailtoLink = `mailto:${email}${paramString}`;

  return (
    <a
      href={mailtoLink}
      style={{
        textTransform: 'none',
        textDecoration: 'none',
        color: 'white',
      }}
    >
      {children}
    </a>
  );
}

export function PersistentDrawerLeftChild(
  props: PersistentDrawerLeftPropsPrivate,
) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [loginButtonText, setLoginButtonText] = useState('Login');
  const {t} = useTranslation();
  const [chosenOption, setChosenOption] = useState(-1);
  const uid = useSelector((state: RootState) => {
    return state.system.firebaseUid;
  });

  useEffect(() => {
    const txt = uid.length > 0 ? 'Logout' : 'Login';
    setLoginButtonText(txt);
  }, [uid]);

  const navigateToInstructions = () => {
    history.push('/help.html');
    window.location.reload();
  };

  const navigateToPrivacy = () => {
    history.push('/privacy.html');
    window.location.reload();
  };

  const navigateToDeleteMe = () => {
    history.push('/delete_me.html');
    window.location.reload();
  };

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // If chosenOption changes to >= 0, set it back to zero and then
  // setOpen(false)
  useEffect(() => {
    if (chosenOption >= 0) {
      const c = chosenOption;
      setChosenOption(-1);
      setOpen(false);
      props.onPressItem(c);
    }
  }, [chosenOption, props]);

  const refFreq = useSelector((state: RootState) => {
    return state.system.referenceFreq;
  });

  const showAmplitude = useSelector((state: RootState) => {
    return state.system.showAmplitude;
  });

  const transposition = useSelector((state: RootState) => {
    return transpositionStrings[state.system.transposition];
  });

  const audioInDeviceLabel = useSelector((state: RootState) => {
    return state.system.currentAudioDeviceLabel;
  });

  const instrumentName = useSelector((state: RootState) => {
    if (
      state.system.currentInstrumentName.length > 0 &&
      state.system.currentInstrumentName !== CHROMATIC &&
      state.system.currentTuningName.length > 0
    ) {
      return (
        state.system.currentInstrumentName +
        ', ' +
        state.system.currentTuningName
      );
    } else {
      return CHROMATIC;
    }
  });



  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Menu />
          </IconButton>
          <div className={classes.titleBar}>
            <Button
              style={{textTransform: 'none'}}
              onClick={() => {
                history.push('./');
              }}
            >
              <Typography variant="h6" noWrap>
                StroboPro
              </Typography>
            </Button>
            {props.showNotesListIcon && (
              <IconButton
                onClick={() => {
                  props.onPressItem(
                    PersistentDrawerButtonEvents.TOGGLE_NOTES_LIST,
                  );
                }}
              >
                {props.notesListIsOpen ? <Cancel /> : <MusicNote />}
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <MyListItem
            primary={t('s_TitleReferenceFrequencyHz')}
            secondary={refFreq + 'Hz'}
            onClick={() => {
              setChosenOption(PersistentDrawerButtonEvents.REF_FREQ);
            }}
          >
            <GraphicEq />
          </MyListItem>

          <MyListItem
            primary={'Transposition'}
            secondary={transposition}
            onClick={() => {
              setChosenOption(PersistentDrawerButtonEvents.TRANSPOSITION);
            }}
          >
            <TextRotateVertical />
          </MyListItem>

          <MyListItem
            primary={'Show input level'}
            secondary={showAmplitude ? 'On' : 'Off'}
            onClick={() => {
              dispatch(Dispatch.setShowAmplitudeAction(!showAmplitude));
            }}
          >
            <SignalCellularAltOutlined />
          </MyListItem>

          <Divider />
          <MyListItem
            primary={'Microphone Settings'}
            secondary={audioInDeviceLabel}
            onClick={() => {
              setChosenOption(PersistentDrawerButtonEvents.MICROPHONE);
            }}
          >
            <Mic />
          </MyListItem>

          <Divider />


          <MyListItem
            primary={'Choose Instrument'}
            secondary={instrumentName}
            onClick={() => {
              setChosenOption(PersistentDrawerButtonEvents.INSTRUMENT);
            }}
          >
            <Straighten />
          </MyListItem>

          <MyListItem
            primary={'Tuning Editor'}
            secondary={'Modify, add, remove instruments and tunings'}
            onClick={() => {
              setChosenOption(PersistentDrawerButtonEvents.INSTRUMENT_EDITOR);
            }}
          >
            <EditOutlined />
          </MyListItem>

          <Divider />

          <MyListItem
            primary={t('s_TitleHelp')}
            secondary={t('s_Instructions')}
            onClick={() => {
              setChosenOption(PersistentDrawerButtonEvents.INSTRUCTIONS);
              navigateToInstructions();
            }}
          >
            <Help />
          </MyListItem>

          <MyListItem
            primary={t('s_PrivacyPolicy')}
            secondary={''}
            onClick={() => {
              setChosenOption(PersistentDrawerButtonEvents.PRIVACY_POLICY);
              navigateToPrivacy();
            }}
          >
            <Info />
          </MyListItem>

          <Divider />
          <MyListItem
            primary={loginButtonText}
            secondary={''}
            onClick={() => {
              setChosenOption(PersistentDrawerButtonEvents.LOGIN_LOGOUT);
            }}
          >
            {uid.length === 0 ? <LockOpen /> : <ExitToApp />}
          </MyListItem>

          <Divider />
          <ListItem>
            <Mailto
              email="support@applicaudia.se"
              subject="Feedback on StroboPro"
              body="I would like to give you some feedback on StroboPro Online Strobe Tuner"
            >
              <MyListItem primary={'Email support'} secondary={''}>
                <Email />
              </MyListItem>
            </Mailto>
          </ListItem>

          {uid.length > 0 && (
            <ListItem>
              <MyListItem
                primary={'Delete my data'}
                secondary={''}
                onClick={() => {
                  navigateToDeleteMe();
                }}
              >
                <Delete />
              </MyListItem>
            </ListItem>
          )}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {!props.placeContentUnderAppBar ? (
          <div className={classes.drawerHeaderOverlap} />
        ) : (
          <div className={classes.drawerHeaderBelowAppBar} />
        )}
        {props.children}
      </main>
    </div>
  );
}
