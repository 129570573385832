import React, { useState, useCallback } from 'react';
import { useSpring, animated as anim } from 'react-spring/web';

export type AnimButtonProps = {
  style?: any;
  children?: any;
  onPress?: () => void;
};

const animDuration = 100;

export function AnimButton(props: AnimButtonProps) {
  const [hover, setIsHover] = useState(false);
  const animatedScale: any = useSpring({
    from: hover ? { scale: 1 } : { scale: 1.1 },
    to: hover ? { scale: 1.1 } : { scale: 1 },
    config: { duration: animDuration },
  });

  const hoverOn = useCallback(() => {
    setIsHover(true);
  }, []);

  const hoverOff = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <anim.div
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
      onClick={props.onPress}
      style={{
        ...props.style,
        transform: animatedScale.scale.to((scale: number) => {
          return `scale(${scale})`;
        }),
      }}
    >
      {props.children}
    </anim.div>
  );
}
