import React from 'react';
import { animated, useSpring } from 'react-spring/web';

export type BouncerProps = {
  amountY: number;
  loop?: boolean;
  children?: any;
  style?: any;
};

const doTranslate3d = (x: number, y: number): string => `translate3d(${x}px,${y}px,0)`;

// Returns an object that bounces up and down.
export const BouncyView = (bprops: BouncerProps) => {
  const loop = bprops.loop ? true : false;

  // you can only use hooks in functions, and they need to be declared in top-function scope.
  const bounce: any = useSpring({
    from: { radians: 0 }, // the start props - needs to be an object so the output can be referred to
    to: { radians: 2 * Math.PI },
    config: { duration: 3000 },
    loop,
  });

  return (
    // We animate the properties of the transform.
    <animated.div
      style={{
        ...bprops.style,
        transform: bounce.radians.to((radians: number) => {
          return doTranslate3d(0, bprops.amountY / 2 + bprops.amountY * Math.sin(radians));
        }),
      }}
    >
      {bprops.children}
    </animated.div>
  );
};
// <Mic style={{ color: 'white', width: 8, height: 8}} />
