import React, { useCallback } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { NoteElement } from '../sharedSrc/src/instruments/instrument_defs';
import * as Midi from '../sharedSrc/src/headers/midi';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';

// -----------------------------------------------------------------------------
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    noteRowDiv: {
      ...theme.shape,
      display: 'flex',
      flexDirection: 'row',
      marginVertical: theme.spacing(0.2),
      padding: theme.spacing(1),
      minWidth: 260,
      // padding: theme.spacing(0.2),
      // boxShadow: theme.shadows[2],
      backgroundColor: 'rgba(1.0,1.0,1.0,0.1)',
    },
  });
});

// ----------------------------------------------------------------------------
export type TuningInfo = {
  element: NoteElement;
  noteAndOctave: Midi.NoteAndOctave;
  cents: number;
  freq: number;
};

export type NoteJsxProps = {
  info: TuningInfo;
  thekey: string;
  style?: any;
  onEditNoteClick: (element: NoteElement) => void;
  onDeleteNoteClick: (element: NoteElement) => void;
  onMouseOverEdit?: (element: NoteElement) => void;
  onMouseOverDelete?: (element: NoteElement) => void;
  onMouseOut?: (element: NoteElement) => void;
};

// ----------------------------------------------------------------------------
/*
  ----------------------------------------
  | E2        + 0.0    (trash)   (pencil)
  | 82.41Hz   cents
  ----------------------------------------
*/
export function TuningEditorNoteJsx(props: NoteJsxProps) {
  const classes = useStyles();
  const info = props.info;

  const sign = info.cents < 0 ? '- ' : '+ ';
  const _cents: string = sign + Math.abs(info.cents).toFixed(1);

  const freq = info.freq ? info.freq.toFixed(2) + 'Hz' : '';

  const onMouseOverEdit = useCallback(() => {
    if (props.onMouseOverEdit) {
      props.onMouseOverEdit(info.element);
    }
  }, [props, info.element]);

  const onMouseOverDelete = useCallback(() => {
    if (props.onMouseOverDelete) {
      props.onMouseOverDelete(info.element);
    }
  }, [props, info.element]);

  const onMouseOut = useCallback(() => {
    if (props.onMouseOut) {
      props.onMouseOut(info.element);
    }
  }, [props, info.element]);

  return (
    <div className={classes.noteRowDiv} key={props.thekey} style={{ ...props.style }}>
      <ListItem
        style={{ margin: 0, padding: 0 }}
        button
        key={info.element.name}
        onClick={() => {
          props.onEditNoteClick(info.element);
        }}
        onMouseOver={onMouseOverEdit}
        onFocus={onMouseOverEdit}
        onMouseLeave={onMouseOut}
        onBlur={onMouseOut}
      >
        <ListItemText primary={props.info.element.name} secondary={freq} />
        <ListItemText primary={_cents} secondary={'cents'} />
      </ListItem>

      <IconButton
        onClick={() => {
          props.onDeleteNoteClick(info.element);
        }}
        onMouseOver={onMouseOverDelete}
        onFocus={onMouseOverDelete}
        onMouseLeave={onMouseOut}
        onBlur={onMouseOut}
      >
        <DeleteOutlined></DeleteOutlined>
      </IconButton>

      <IconButton
        onClick={() => {
          props.onEditNoteClick(info.element);
        }}
        onMouseOver={onMouseOverEdit}
        onFocus={onMouseOverEdit}
        onMouseLeave={onMouseOut}
        onBlur={onMouseOut}
      >
        <EditOutlined></EditOutlined>
      </IconButton>
    </div>
  );
}
