import {en} from './languages/en';
import {sv} from './languages/sv';
import {it} from './languages/it';
import {tr} from './languages/tr';

export const resources = {
  ...en,
  ...sv,
  ...it,
  ...tr,
};
