import React, { useEffect, useState } from 'react';

import { AddCircleOutline } from '@material-ui/icons';
import { fade, createStyles, makeStyles, Theme, Button, Typography } from '@material-ui/core';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';

import {
  InstrumentElement,
  SubInstrumentElement,
  TuningElement,
} from '@common/instruments/instrument_defs';

import { MinusSquare, PlusSquare, Dash, None } from '../components/svg_icons';
import useSnackbarHelp from '@abstractions/use_snackbar_help';

export const useStylesTree = makeStyles((theme: Theme) => ({
  root: {},
  expanded: {},
  selected: {},
  group: {
    paddingLeft: 4,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
  content: {},
  iconContainer: {
    margin: 1,
    border: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
  label: {
    textAlign: 'start', // Add this line
  },
}));

// -----------------------------------------------------------------------------
const MyStyledTreeItem: React.FC<TreeItemProps> = (
  props: React.PropsWithChildren<TreeItemProps>,
) => {
  const classes = useStylesTree();
  const key = props.nodeId;

  return <TreeItem {...props} key={key} classes={classes} />;
};

// -----------------------------------------------------------------------------
const useStylesTuningRow = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    tuningRow: {
      display: 'flex',
    },
    button: {
      textTransform: 'none',
    },
  });
});

type AddNewButtonProps = {
  buttonkey: any;
  onClick: () => void;
  onMouseOver?: () => void;
  onFocus?: () => void;
  onMouseLeave?: () => void;
  onBlur?: () => void;
};

// ----------------------------------------------------------------------------
function AddNewButton(props: AddNewButtonProps) {
  return (
    <Button
      style={{ textTransform: 'none' }}
      key={props.buttonkey}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
      onFocus={props.onFocus}
      onMouseLeave={props.onMouseLeave}
      onBlur={props.onBlur}
    >
      <AddCircleOutline />
      <Typography>Add New</Typography>
    </Button>
  );
}

interface TreeViewProps {
  allInstrumentsMap: Map<string, InstrumentElement>;
  forceTreeListUpdate?: number;
  chosenTuningName: string;
  onAddNewButtonClick: () => void;
  onTuningClick: (tuningElement: TuningElement) => void;
}

// ----------------------------------------------------------------------------
export function InstrumentsTreeView(props: TreeViewProps) {
  const classesTuningRow = useStylesTuningRow();
  const [treeItems, setTreeItems] = useState<JSX.Element[]>([]);
  const snackbarHelp = useSnackbarHelp();

  useEffect(() => {
    // Recursively add all of the instruments.
    let nodeId = 0;
    const instrumentsJsx: JSX.Element[] = [];
    props.allInstrumentsMap.forEach((instrument: InstrumentElement, _instrumentKey: string) => {
      const subInstrumentJsx: JSX.Element[] = [];
      instrument.subInstrument.forEach((subInstrument: SubInstrumentElement, _index: number) => {
        const tuningsJsx: JSX.Element[] = [];
        subInstrument.tuning.forEach((tuning: TuningElement, _i: number) => {
          let tuningName = tuning.name;
          if (tuning.userModified) {
            tuningName += ' *';
          }
          const endIcon = tuning.name === props.chosenTuningName ? <Dash /> : undefined;
          nodeId += 1;
          tuningsJsx.push(
            <div className={classesTuningRow.tuningRow} key={nodeId.toString()}>
              <Button
                className={classesTuningRow.button}
                onClick={() => {
                  props.onTuningClick(tuning);
                }}
              >
                <MyStyledTreeItem
                  key={nodeId.toString()}
                  nodeId={nodeId.toString()}
                  label={tuningName}
                  endIcon={endIcon}
                ></MyStyledTreeItem>
              </Button>
            </div>,
          );
        });

        nodeId += 1;
        subInstrumentJsx.push(
          <MyStyledTreeItem
            key={nodeId.toString()}
            nodeId={nodeId.toString()}
            label={subInstrument.name}
          >
            {tuningsJsx}
          </MyStyledTreeItem>,
        );
      });

      nodeId += 1;
      instrumentsJsx.push(
        <MyStyledTreeItem
          key={nodeId.toString()}
          nodeId={nodeId.toString()}
          label={instrument.name}
        >
          {subInstrumentJsx}
        </MyStyledTreeItem>,
      );
    });
    nodeId += 1;
    instrumentsJsx.push(
      <div
        key={nodeId.toString()}
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
      >
        <AddNewButton
          key={nodeId.toString()}
          buttonkey={nodeId.toString()}
          onClick={() => {
            props.onAddNewButtonClick();
          }}
          {...snackbarHelp.mouseAndFocusEvents('Add a new tuning')}
        />
      </div>,
    );
    setTreeItems(instrumentsJsx);
  }, [props, classesTuningRow.button, classesTuningRow.tuningRow, snackbarHelp]);

  return (
    <>
      <TreeView
        className={classesTuningRow.root}
        defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<None />}
      >
        {treeItems}
      </TreeView>
      {snackbarHelp.Snackbar}
    </>
  );
}
