export const Colors = {
  safeareaview: '#444444',
  background: 'rgb(0,0,0)',
  icons: 'rgb(0,0,0)',
  textInForeground: 'white',
  textOnLightBackground: 'black',
  lightGrayText: 'rgb(160,160,160)',
  in_tune: 'rgb(0,255,0)',
  orange: 'rgb(255,255,0)',
  idle: 'rgb(120,120,120)',
  mainColor: 'rgb(210,210,210)',
  vuBgStart: 'rgba(100,100,100,1.0)',
  vuBgEnd: 'rgba(100,100,100,0.2)',
  vuMin: 'rgb(210,210,210)',
  vuMiddle: 'rgb(150,150,150)',
  vuMax: 'rgb(60,60,60)',
  vuThresh: 'rgb(255,255,255)',
  centsErrorArc: 'rgba(100, 100, 100, 0.4)',
  overallErrorArc: 'rgb(30, 30, 30)',
  lightBackground: 'rgb(200, 200, 200)',
  controlRowBackground: 'rgb(10,10,10)',
  controlRowBorder: 'rgb(0,0,0)',
  buyPremium: '#007BFF',
  tuningChooserHinting: '#444444',
};

// returns a string indicating RGB based on 3 floating point numbers
export function getRGB(r: number, g: number, b: number): string {
  // convert the decimal to a value between 0-255
  const red = Math.min(255, Math.max(0, Math.round(r * 255)));
  const green = Math.min(255, Math.max(0, Math.round(g * 255)));
  const blue = Math.min(255, Math.max(0, Math.round(b * 255)));
  return 'rgb(' + red + ',' + green + ',' + blue + ')';
}

export const MIN_REL_AMP_STROKEWIDTH = 0.1; // Minimum relative amplitude stroke width
export const MIN_REL_AMP_COLOR = 0.1; // Minimum relative amplitude color

export const SPRINGCFG_COLOR = {
  mass: 0.5,
  damping: 20,
  stiffness: 100,
  overshootClamping: true,
};
