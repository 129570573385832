import {
  createStyles,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Close, Share } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    dialogTitleRoot: {
      margin: 0,
      padding: theme.spacing(2),
    },
    buttons: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      flexDirection: 'row',
    },
    button: {
      color: theme.palette.grey[500],
    },
  });
});

export interface MyDialogTitleProps {
  children?: any;
  title?: string;
  subtitle?: string;
  onClose?: () => void;
  onShare?: () => void; /// < If set, shows a share button, too
  other?: any;
  id: string;
}

export const MyDialogTitle = (props: MyDialogTitleProps) => {
  const classes = useStyles();

  const { onClose, onShare, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.dialogTitleRoot} {...other}>
      <div>
        <Typography variant='body1'>{props.title}</Typography>
        {!!props.subtitle && <Typography variant='body1'>{props.subtitle}</Typography>}
      </div>
      <div className={classes.buttons}>
        {onShare ? (
          <IconButton className={classes.button} aria-label='share' onClick={onShare}>
            <Share />
          </IconButton>
        ) : null}
        {onClose ? (
          <IconButton className={classes.button} aria-label='close' onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </div>
    </DialogTitle>
  );
};
