import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BottomNavigation, BottomNavigationAction, DialogTitle, Dialog } from '@material-ui/core';
import { Cancel as CancelIcon, DoneOutline } from '@material-ui/icons';

import {
  copyTuning,
  ElementBase,
  InstrumentAsMap,
  InstrumentElement,
  InstrumentElementDefault,
  NoteElement,
  NoteElementDefault,
  SubInstrumentElement,
  SubInstrumentElementDefault,
  TuningElement,
  TuningElementDefault,
} from '../sharedSrc/src/instruments/instrument_defs';
import { getChildren } from '../sharedSrc/src/instruments/instrument_defs';
import { RootState } from '@abstractions/redux_inst';
import { MyChooser } from '@abstractions/my_chooser';
import { dbg } from '../sharedSrc/src/debug/debug';
import useSnackbarHelp from '@abstractions/use_snackbar_help';
import { MyChooser_renderInstrument } from '@abstractions/choose_instrument';

import '../App.css';
import { BottomNavigationActionWithDisable } from '../bottom_navigation_button_with_disable';

export type ChooseInstrumentProps = {
  open: boolean;
  onClose: (tuning: null | TuningElement) => void;
};

// ----------------------------------------------------------------------------
export function ChooseTuningNameDialog(props: ChooseInstrumentProps) {
  const [originalInstrument, setOriginalInstrument] = useState<InstrumentElement | null>(null);
  const [instrumentName, setInstrumentName] = useState<string>('');
  const [subInstrumentName, setSubInstrumentName] = useState<string>('');

  const [doReset, setDoReset] = useState(false);
  const [instrumentNames, setInstrumentNames] = useState<string[]>([]);
  const [subInstrumentNames, setSubInstrumentNames] = useState<string[]>([]);
  const [tuningName, setTuningName] = useState<string>('');
  const instrumentsMap = useSelector((state: RootState) => {
    return state.system.allInstrumentsMap;
  });
  const numInstruments = useSelector((state: RootState) => {
    return state.system.allInstrumentsMap.size;
  });

  const help = useSnackbarHelp();

  // Called when the list of instruments changes size.
  useEffect(() => {
    dbg.log(
      'Handling new instrument mapping from size ' +
        numInstruments +
        ' to size ' +
        instrumentsMap.size,
    );
    setOriginalInstrument(null);
    setInstrumentName('');
    setSubInstrumentName('');

    setDoReset(false);
    setInstrumentNames([...instrumentsMap.keys()]);
  }, [instrumentsMap, numInstruments, doReset]);

  useEffect(() => {
    if (instrumentName.length > 0) {
      const ogInstrument = instrumentsMap.get(instrumentName);
      if (ogInstrument) {
        const instAny: InstrumentElement = ogInstrument as InstrumentElement;
        dbg.logObj('instrument = ', instAny);

        setOriginalInstrument(instAny);

        const arr = getChildren(instAny);
        if (arr) {
          setSubInstrumentNames(
            arr.map((v: ElementBase) => {
              return v.name;
            }),
          );
        }
      }
    }
    setSubInstrumentName('');
  }, [instrumentName, instrumentsMap]);

  // Called when an instrument is chosen.
  useEffect(() => {
    dbg.log('subInstrument = ', subInstrumentName);
    setTuningName('');
  }, [subInstrumentName]);

  const applyTuningName = useCallback(() => {
    // Only run if there is a tuning, instrument, and subinstrument
    if (tuningName.length > 0 && subInstrumentName.length > 0 && instrumentName.length > 0) {
      const newInstrument = InstrumentElementDefault(instrumentName);
      const newSubInstrument = SubInstrumentElementDefault(subInstrumentName, newInstrument);

      // Create the new tuning and push it onto the list of tunings.
      let newTuning = TuningElementDefault(tuningName, newSubInstrument);

      // Prepare for, and call, the close function
      // newTuning.noteElement[0].parent = newTuning;
      // newSubInstrument.tuning.push(newTuning);
      const newNote = NoteElementDefault('A4', newTuning);
      if (newNote.parent !== newTuning) {
        console.error('Something is wrong with NoteElementDefault');
      }

      if (!!originalInstrument) {
        const existingSubInstrument = InstrumentAsMap.get(originalInstrument, subInstrumentName);
        if (!!existingSubInstrument) {
          const subInst = existingSubInstrument as SubInstrumentElement;
          if (subInst.tuning.length > 0) {
            newTuning = copyTuning(subInst.tuning[0]);
            newTuning.name = tuningName;
            newTuning.userModified = true;
            newTuning.isDefaultTuning = false;
            newTuning.noteElement = newTuning.noteElement.map((noteElement: NoteElement) => {
              // Do not keep any cents offset.
              const n = NoteElementDefault(noteElement.name);
              n.parent = newTuning;
              return n;
            });
            InstrumentAsMap.set(newSubInstrument, newTuning);
          }
        }
      }

      props.onClose(newTuning);
    }
  }, [tuningName, instrumentName, originalInstrument, props, subInstrumentName]);

  return props.open ? (
    <Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id='simple-dialog-title'>Create Instrument Tuning</DialogTitle>
      <div className='Settings' style={{ flexDirection: 'column', flex: 1 }}>
        <div style={{ flexDirection: 'column', padding: 20, width: '100%' }}>
          <MyChooser
            freeSolo={true}
            options={instrumentNames}
            label={'Choose an instrument, or name a new one.'}
            onChange={(chosenString: null | string) => {
              dbg.log('Chose instrument ' + chosenString);
              setInstrumentName(!!chosenString ? chosenString : '');
            }}
            doRender={MyChooser_renderInstrument}
          />
        </div>
        {/** Show subinstrument when instrument length is not null */}
        {instrumentName.length > 0 && (
          <div style={{ flexDirection: 'column', padding: 20, width: '100%' }}>
            <MyChooser
              freeSolo={subInstrumentNames.length === 0}
              options={subInstrumentNames}
              label={
                subInstrumentNames.length === 0
                  ? 'Name a new instrument type'
                  : 'Choose an instrument type, or name a new one'
              }
              onChange={(chosenSubInstrumentString: null | string) => {
                dbg.log('Chose subinstrument ' + chosenSubInstrumentString);
                setSubInstrumentName(!!chosenSubInstrumentString ? chosenSubInstrumentString : '');
              }}
              doRender={MyChooser_renderInstrument}
            />
          </div>
        )}
        {/** Show tuning when instrument length is not null */}
        {instrumentName.length > 0 && subInstrumentName.length > 0 && (
          <div style={{ flexDirection: 'column', padding: 20, width: '100%' }}>
            <MyChooser
              onlyTextField={true}
              freeSolo={true}
              options={[]}
              label={'Name a new tuning'}
              onChange={(tuningNameString: null | string) => {
                dbg.log('Named tuning ' + tuningNameString);
                setTuningName(!!tuningNameString ? tuningNameString : '');
              }}
              doRender={MyChooser_renderInstrument}
            />
          </div>
        )}
      </div>

      <BottomNavigation
        style={{ width: '100%', paddingBottom: 10, paddingTop: 10 }}
        onChange={(_event, newValue) => {
          switch (newValue) {
            case 0:
              // Accept or cancel
              applyTuningName();
              break;
            case 1:
              // Cancel
              props.onClose(null);
              break;
            default:
              break;
          }
        }}
        showLabels
        // className={classes.root}
      >
        <BottomNavigationActionWithDisable
          disabled={tuningName.length === 0}
          label='Apply'
          icon={<DoneOutline />}
          {...help.mouseAndFocusEvents('Use this tuning name')}
        />

        <BottomNavigationAction
          label='Cancel'
          icon={<CancelIcon />}
          {...help.mouseAndFocusEvents('Exit without applying changes')}
        />
      </BottomNavigation>
      <div className='Small' style={{ flexDirection: 'row', flex: 0.1 }}></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      ></div>
      {help.Snackbar}
    </Dialog>
  ) : null;
}

export const useTuningNameChooser = (
  isOpen: boolean,
  onClose: (tuning: null | TuningElement) => void,
) => {
  return {
    jsx: isOpen ? <ChooseTuningNameDialog open={isOpen} onClose={onClose} /> : null,
  };
};
