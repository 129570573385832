import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AudioInputDeviceInfo, Dispatch} from '../../redux/redux';
import {RootState} from '@abstractions/redux_inst';
import {
  BottomNavigation,
  BottomNavigationAction,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import {
  Cancel as CancelIcon,
  DoneOutline as DoneOutlineIcon,
} from '@material-ui/icons';
import {dbg} from '../../debug/debug';
import {MyChooser} from './my_chooser';
import {MyChooser_renderInstrument} from './choose_instrument';
import useSnackbarHelp from './use_snackbar_help';
import '../../../../App.css';

export type ChooseAudioInProps = {
  open: boolean;
  onClose: () => void;
};

export function ChooseAudioInScreen(props: ChooseAudioInProps) {
  const [audioInStrings, setAudioInStrings] = useState<string[]>(['none']);

  const [chosenAudioDevice, setChosenAudioDevice] = useState<
    null | undefined | AudioInputDeviceInfo
  >(null);
  const [selectedAudioDeviceLabel, setSelectedAudioDeviceLabel] = useState('');

  const audioDevicesByDeviceIdMap = useSelector((state: RootState) => {
    return state.system.audioDevicesByDeviceId;
  });

  useEffect(() => {
    let newAudioInStrings: string[] = [];

    if (!!audioDevicesByDeviceIdMap && audioDevicesByDeviceIdMap.size > 0) {
      audioDevicesByDeviceIdMap.forEach((value: AudioInputDeviceInfo) => {
        if (value && value.label && value.label.length > 0) {
          newAudioInStrings.push(value.label);
        }
      });
    }
    newAudioInStrings =
      newAudioInStrings.length === 0
        ? ['none (cannot see any devices!)']
        : newAudioInStrings;

    setAudioInStrings(newAudioInStrings);
  }, [audioDevicesByDeviceIdMap]);

  const dispatch = useDispatch();
  const help = useSnackbarHelp();

  // Set the label appropriately depending on the chosen device.
  useEffect(() => {
    if (
      chosenAudioDevice &&
      chosenAudioDevice.label &&
      chosenAudioDevice.deviceId &&
      chosenAudioDevice.label.length
    ) {
      setSelectedAudioDeviceLabel(chosenAudioDevice.label);
    } else {
      setChosenAudioDevice(null);
      setSelectedAudioDeviceLabel('');
    }
  }, [chosenAudioDevice]);

  const showApplyButton = selectedAudioDeviceLabel.length > 0;

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="simple-dialog-title">
        Choose Audio Input Device
      </DialogTitle>
      <div className="Settings" style={{flexDirection: 'column', flex: 1}}>
        <div style={{flexDirection: 'column', padding: 20, width: '100%'}}>
          <MyChooser
            options={audioInStrings}
            label="Audio Input Device"
            onChange={(deviceLabel: null | string) => {
              dbg.log('Chose audioIn label:' + deviceLabel);
              if (deviceLabel) {
                const arr: AudioInputDeviceInfo[] = [
                  ...audioDevicesByDeviceIdMap.values(),
                ];
                const _chosenIndex = arr.find((dev: AudioInputDeviceInfo) => {
                  return dev.label === deviceLabel;
                });

                const chosenIndex = _chosenIndex ? _chosenIndex : null;
                setChosenAudioDevice(chosenIndex);
              }
            }}
            doRender={MyChooser_renderInstrument}
          />
        </div>
      </div>
      <div className="Small" style={{flexDirection: 'row', flex: 0.2}}></div>
      <BottomNavigation
        style={{width: '100%', paddingBottom: 10, paddingTop: 10}}
        onChange={(event, newValue) => {
          dbg.log('onChange: with event ' + event, newValue);
          switch (newValue) {
            case 0:
              if (showApplyButton) {
                // Accept
                const _devId: undefined | string = chosenAudioDevice?.deviceId
                  ? chosenAudioDevice.deviceId
                  : '';
                const devId = _devId ? _devId : '';
                if (devId.length > 0) {
                  dispatch(Dispatch.chooseAudioInputDevice(devId));
                }
              }
              props.onClose();
              break;
            case 1:
              // Cancel
              props.onClose();
              break;
            default:
              break;
          }
        }}
        showLabels
        // className={classes.root}
      >
        {showApplyButton && (
          <BottomNavigationAction
            label="Apply"
            icon={<DoneOutlineIcon />}
            {...help.mouseAndFocusEvents(
              'Choose device "' +
                (chosenAudioDevice ? chosenAudioDevice.label : '') +
                '"',
            )}
          />
        )}
        <BottomNavigationAction
          label="Cancel"
          icon={<CancelIcon />}
          {...help.mouseAndFocusEvents('Exit without choosing device')}
        />
      </BottomNavigation>
      <div className="Small" style={{flexDirection: 'row', flex: 0.1}}></div>
      {help.Snackbar}
    </Dialog>
  );
}
