import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { DoneOutline as DoneOutlineIcon } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';

import { PersistentDrawerLeft } from '@abstractions/persistent_drawer_left';

import { useHistory, useParams, withRouter } from 'react-router-dom';
import {
  SubInstrumentElement,
  TuningElement,
  findTuningInInstrumentMap,
  InstrumentAsMap,
  copyInstrumentsMap,
  makeInstrumentWithOnlyThisTuning,
  deUrlifyLinkToTuning,
} from '@common/instruments/instrument_defs';

import { dbg } from '@common/debug/debug';
import { ChooseTuningNameDialog } from './choose_new_tuning_name';
import { RootState } from '@abstractions/redux_inst';
import { Dispatch, InfoNames } from '@common/redux/redux';
import { FirebaseAsyncStorage } from '@common/firebase_storage';

import { TuningEditor } from './tuning_editor';

import assert from 'assert';
import { MIN_WINDOW_WIDTH } from 'src/hooks/use_window_size';
import { InstrumentsTreeView } from './instrument_tree_view';
import '../App.css';
// const debugBorders = { border: '1px solid white' };
const debugBorders = {};
/**
 *    +----                      ---------
 *    -----                      o -------
 *      +----                    o -------
 *      +----                    o -------
 */
function CreateInstrumentsScreen() {
  const dispatch = useDispatch();

  const allInstrumentsMapOriginal = useSelector((state: RootState) => {
    return state.system.allInstrumentsMap;
  });

  const [lastAllInstrumentsUpdatesCount, setLastAllInstrumentsUpdatesCount] = useState(-1);

  const allInstrumentsUpdatesCount = useSelector((state: RootState) => {
    return state.system.allInstrumentsUpdatesCount;
  });

  const [instrumentsCopy, setInstrumentsCopy] = useState(allInstrumentsMapOriginal);
  const [forceTuningUpdate, setForceTuningUpdate] = useState(0);
  const [queueSaveToFirebase, setQueueSaveToFirebase] = useState(0);

  useEffect(() => {
    if (allInstrumentsUpdatesCount !== lastAllInstrumentsUpdatesCount) {
      setInstrumentsCopy(copyInstrumentsMap(allInstrumentsMapOriginal, true));
      setLastAllInstrumentsUpdatesCount(allInstrumentsUpdatesCount);
      setForceTuningUpdate((fct) => fct + 1);
    }
  }, [
    allInstrumentsMapOriginal,
    allInstrumentsUpdatesCount,
    lastAllInstrumentsUpdatesCount,
    forceTuningUpdate,
  ]);

  const refFreq = useSelector((state: RootState) => {
    return state.system.referenceFreq;
  });
  const [chosenTuning, setChosenTuning] = useState<TuningElement | null>(null);
  const [pendingChosenTuning, setPendingChosenTuning] = useState<TuningElement | null>(null);
  const [showingTuning, setShowingTuning] = useState<TuningElement | null>(null);
  const [pendingIncomingTuning, setPendingIncomingTuning] = useState<null | TuningElement>(null);

  const [addingNewInstrument, setAddingNewInstrument] = useState(false);

  useEffect(() => {
    // Forces update of the tuning that is currently showing
    if (chosenTuning) {
      if (forceTuningUpdate || !showingTuning || showingTuning !== chosenTuning) {
        if (chosenTuning && chosenTuning.parent && chosenTuning.parent.parent) {
          setShowingTuning(chosenTuning);
        }
      }
    } else {
      setShowingTuning(null);
    }
  }, [chosenTuning, instrumentsCopy, refFreq, forceTuningUpdate, showingTuning]);

  useEffect(() => {
    // Any time queueSaveToFirebase gets set, will dispatch a save to NVM
    if (0 !== queueSaveToFirebase) {
      if (!FirebaseAsyncStorage.inst().isLoggedIn()) {
        alert('Your changes will not be saved unless you login.');
      } else {
        dispatch(Dispatch.info(InfoNames.QueueSaveInstrumentsToNvm));
      }
    }
  }, [queueSaveToFirebase, dispatch]);

  useEffect(() => {
    // If a new tuning is chosen (and becomes pending), update the chosenTuning
    if (!!pendingChosenTuning && pendingChosenTuning !== chosenTuning) {
      const tuningInCopy = findTuningInInstrumentMap(pendingChosenTuning, instrumentsCopy);
      if (!!tuningInCopy) {
        assert(!!tuningInCopy.parent);

        setPendingChosenTuning(null);
        setChosenTuning(tuningInCopy);
        setForceTuningUpdate((fct) => fct + 1);
      }
    }
  }, [pendingChosenTuning, chosenTuning, instrumentsCopy]);

  useEffect(() => {
    if (!!pendingIncomingTuning) {
      const onNewTuningAdded = (tuning: null | TuningElement) => {
        // Called when a new tuning name is chosen.
        if (!!tuning) {
          tuning.userModified = true;
          tuning.isDefaultTuning = false;
          const newInstrument = makeInstrumentWithOnlyThisTuning(tuning);
          if (newInstrument) {
            dispatch(Dispatch.addInstrument(newInstrument));
            setQueueSaveToFirebase((qstf) => qstf + 1);
          }
        }
        // Add the instrument, and then start editing it.
        setAddingNewInstrument(false);
        setPendingChosenTuning(tuning);
      };

      onNewTuningAdded(pendingIncomingTuning);
      setPendingIncomingTuning(null);
    }
  }, [pendingIncomingTuning, dispatch, setQueueSaveToFirebase]);

  // --------------------------
  // Start handling of incoming instruments
  const history = useHistory();
  const params: any = useParams();
  if (params.url) {
    if (!pendingIncomingTuning) {
      const u = params.url.toString();
      const inst = deUrlifyLinkToTuning(u);
      if (inst) {
        // Capture the instrument that was shared.
        setPendingIncomingTuning(inst);
        // Remove the URL
        history.replace('/instruments');
      }
    }
  }

  // End handling of incoming shared instruments
  // --------------------------

  dbg.log('rerendering CreateInstrumentsScreen() with choosingInstrumentName', addingNewInstrument);

  const onTuningUpdatedByTuningEditor = (updatedTuning?: TuningElement) => {
    // Called when the tuning editor has updated a tuning
    if (!!updatedTuning) {
      const subInstrument = chosenTuning?.parent;
      if (!!subInstrument) {
        InstrumentAsMap.set(subInstrument as SubInstrumentElement, updatedTuning, true);
        const i = makeInstrumentWithOnlyThisTuning(updatedTuning);
        dispatch(Dispatch.addInstrument(i));
        setQueueSaveToFirebase((qstf) => qstf + 1);

        // setForceTuningUpdate((forceTuningUpdate) => forceTuningUpdate + 1);
        // setForceTreeListUpdate((forceTreeListUpdate) => forceTreeListUpdate + 1);
      }
    }
    setPendingChosenTuning(null);
    setShowingTuning(null);
    setChosenTuning(null);
  };

  const onTuningDeletedByTuningEditor = (deleteTuning: TuningElement) => {
    // Called when the tuning editor has chosen to delete a tuning.
    dispatch(Dispatch.deleteTuning(deleteTuning));
    setQueueSaveToFirebase((qstf) => qstf + 1);
    setPendingChosenTuning(null);
    setShowingTuning(null);
    setChosenTuning(null);
  };

  // This workaround forces a screen resize. Without it, the windowDimensions don't get set
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => {
        if (prev < 4) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 750);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {count === 0 && <div></div>}
      {count !== 0 && (
        <div
          style={{
            width: windowDimensions.width - 50,
            height: windowDimensions.height - 50,
            ...debugBorders,
          }}
        >
          <PersistentDrawerLeft
            placeContentUnderAppBar={true}
            notesListIsOpen={false}
            showNotesListIcon={false}
          >
            <>
              <div
                style={{
                  padding: 10,
                  width: MIN_WINDOW_WIDTH,

                  ...debugBorders,
                }}
              >
                <InstrumentsTreeView
                  onAddNewButtonClick={() => {
                    setAddingNewInstrument(true);
                  }}
                  chosenTuningName={chosenTuning ? chosenTuning.name : ''}
                  allInstrumentsMap={instrumentsCopy}
                  onTuningClick={(tuning: TuningElement) => {
                    setPendingChosenTuning(tuning);
                  }}
                />
              </div>

              {!!showingTuning && (
                <TuningEditor
                  chosenTuning={showingTuning}
                  onTuningUpdated={onTuningUpdatedByTuningEditor}
                  onTuningAdded={(tuning: TuningElement | null) => {
                    if (!!tuning) {
                      setPendingIncomingTuning(tuning);
                    }
                  }}
                  onDeleteTuningClick={onTuningDeletedByTuningEditor}
                />
              )}

              {!!addingNewInstrument && (
                <ChooseTuningNameDialog
                  open={true}
                  onClose={(tuning: TuningElement | null) => {
                    setAddingNewInstrument(false);
                    setPendingIncomingTuning(tuning);
                  }}
                />
              )}
            </>
          </PersistentDrawerLeft>

          <BottomNavigation
            style={{ paddingBottom: 10, paddingTop: 10, width: 120 }}
            onChange={(event: string, newValue: any) => {
              dbg.log('onChange: with event ' + event, newValue);
              if (history.length > 0) {
                history.goBack();
              } else {
                history.push('/');
                window.location.reload();
              }
            }}
            showLabels
            // className={classes.root}
          >
            <BottomNavigationAction label='Done' icon={<DoneOutlineIcon />} />
          </BottomNavigation>
        </div>
      )}
    </>
  );
}

export default withRouter(CreateInstrumentsScreen);
