export const tr = {
  tr: {
    translation: {
      s_About:
        'Strobopro, Android, iOS ve Web için bir Strobe Akort Uygulamasıdır.\n\n',
      s_AboutThisApp: 'Bu uygulama hakkında',
      s_Cancel: 'İptal',
      s_Choose: 'Seç',
      s_CloseSettings: 'Ayarları Kapat',
      s_CookieConsent:
        'Bu web sitesi, kullanıcı deneyimini geliştirmek için çerezler kullanır. Kişisel bilgileri depolamak için çerezler kullanmıyoruz. Kabul etmek için Lütfen tıklayın.',
      s_Contact: 'İletişim',
      s_CurrentlyOn: 'Şu anda açık',
      s_CurrentlyOff: 'Şu anda kapalı',
      s_DataCollectionPolicy: 'Veri Toplama Politikası',
      s_EmailTemplate:
        'StroboPro hakkında dostça bir öneri&body=Merhaba geliştirici, size bazı geri bildirimlerim var...',
      s_EmailTheDeveloper: 'Geliştiriciye e-posta gönder',
      s_FFTSize: 'FFT Boyutu',
      s_GetTuningsFromStroboPro: "StroboPro.se'den akortları al",
      s_GlowEffects: 'Parlama Efektleri',
      s_HelpAboutPro:
        'Premium sürüme sahipsiniz. Destek için teşekkür ederiz! 🙏\n\n',
      s_HelpAdvancedSettings:
        'FFT boyutu, hassasiyet, strobe türü vb. gibi gelişmiş özellikler.',
      s_HelpEstimatedCents:
        'Tahmini cent değeri, tahmini frekans ile en yakın nota arasındaki farktır.\n\nŞüphe durumunda, strobelere bakın!\n\n(<_<)',
      s_HelpFftSize:
        'Daha yüksek FFT Boyutu daha doğru sonuçlar verir, ancak daha fazla CPU tüketir.\n\n' +
        'Daha düşük FFT Boyutu daha hızlıdır, ancak daha az doğru sonuçlar verir.\n\n' +
        'FFT, çalınan notanın hangi nota olduğunu belirlemek için kullanılır.\n\n' +
        'Cihazınız doğru notaları çözümlemekte zorluk yaşıyorsa, FFT boyutunu değiştirmeyi deneyin.\n\n' +
        'Varsayılan değer ',
      s_HelpGlow:
        'Parlama efektleri biraz ekstra cazibe katarken, eski cihazlarda uygulamayı yavaşlatabilir.',
      s_HelpInstrument: 'Listeden bir enstrüman ve akort seçin.',
      s_HelpInstrumentPro:
        "\n\nEnstrümanınızı veya akortunuzu bulamazsanız, web uygulaması strobopro.se'de akort ekleyebilir veya özelleştirebilirsiniz.\n\nGiriş yaptıktan sonra, bu uygulama özel enstrümanlarınızı ve akortlarınızı strobopro.se'den indirecektir.",
      s_HelpNoteLock:
        "Tuner hangi notayı çaldığınızı tam olarak belirleyemediğinde, not kilidine başvurabilirsiniz.\n\nNot kilidi, tuner'ı seçtiğiniz nota kilitlemeye çalışacaktır.\n\nBu, strobeları belirli bir frekansa kilitleyecektir.\n\nLütfen not kilidini tekrar kapatmak için not kilidinin solundaki kilit simgesine tıklayın.",
      s_HelpRating:
        'Beğendiyseniz, lütfen mağazada bize güzel bir derecelendirme verme konusunda düşünün, gerçekten yardımcı olur!\n\n⭐️⭐️⭐️⭐️⭐️',
      s_HelpReferenceFrequency:
        "Referans frekansı, A4 notasının frekansıdır.\n\nVarsayılan değer 440Hz'dir, ancak 432Hz veya başka bir değere değiştirebilirsiniz.",
      s_HelpSensitivity:
        "Cihazınız çok fazla boşta kalıyorsa hassasiyeti azaltın.\n\nCihazınız her zaman arka plan gürültüsünü algılayıp tepki veriyorsa hassasiyeti artırın.\n\nVarsayılan değer 9 dB'dir.",
      s_HelpStatusCents:
        'Bu, strobelardan türetilen cent cinsinden tahmini hata miktarını gösterir.\n\nŞüphe durumunda, strobelere bakın!\n\n(<_<)',
      s_HelpStatusFrequency:
        'Bu, strobelardan türetilen tahmini frekansı gösterir.\n\nOrtalama alınır ve biraz hassas olabilir.',
      s_HelpStrobeDisplayType:
        "Hız modu, mevcut strobe hızı kontrol edilir ve ardından strobelar, cihazınızın işleyebileceği herhangi bir kare hızında aynı hızda hareket ettirilir.\n\nPozisyon modu, gerçek bir strobe tuner'ın çalışma şekline daha benzer, mevcut pozisyon örneklendi ve ardından strobe o pozisyona taşınır, ancak bu sadece daha hızlı cihazlarda iyi çalışır.",
      s_HelpTransposition:
        "Transpozisyon, A440'den farklı bir perdeye ayarlanmış enstrümanlar için kullanışlıdır.\n\nÖrnek: B-flat klarinetler, yazılmış bir C çalındığında B-flat sesi verir.\n\nC->Bb, algılanan bir C'yi görüntülenen bir Bb'ye dönüştürür.",
      s_Instructions: 'Kullanım Talimatları',
      s_listening: 'dinleniyor',
      s_Login: 'Giriş yap',
      s_Logout: 'Çıkış yap',
      s_LogoutToClearSettings: 'Ayarları temizlemek için çıkış yap',
      s_LongPressItem: 'Yardım için bir öğeye uzun basın',
      s_NavigateToAdvanced: 'Gelişmiş ayarlar menüsüne git',
      s_NoneChromatic: 'Hiçbiri (Kromatik)',
      s_Off: 'Kapalı',
      s_On: 'Açık',
      s_Position: 'Pozisyon',
      s_PrivacyPolicy: 'Gizlilik Politikası',
      s_Reset: 'Sıfırla',
      s_START: 'BAŞLA',
      s_SettingsHint: 'Ayarlar ekranına gitmek için dokunun',
      s_StrobeDisplayType: 'Strobe Ekran Türü',
      s_TapToChange: 'Değiştirmek için dokunun',
      s_TapToChangeTo: 'Değiştirmek için dokunun ',
      s_TitleAboutStrobopro: 'Strobopro Hakkında',
      s_TitleAddInstrument: 'Enstrüman Ekle',
      s_TitleAdvanced: 'Gelişmiş',
      s_TitleChooseTuning: 'Akort Seç',
      s_TitleHelp: 'Yardım',
      s_TitleInstrument: 'Enstrüman',
      s_TitleNoteLock: 'Not Kilidi',
      s_TitlePaywall: 'Ödeme Duvarı',
      s_TitleReferenceFrequencyHz: 'Referans Frekansı (Hz)',
      s_HintReferenceFrequencyHz:
        'Referans frekansını değiştirmek için dokunun',
      s_TitleSensitivity: 'Hassasiyet',
      s_TitleSettings: 'Ayarlar',
      s_TitleSignIn: 'Giriş Yap',
      s_TitleStatusCents: 'Strobe Centleri',
      s_TitleStatusFrequency: 'Strobe Frekansı',
      s_TitleTransposition: 'Transpozisyon',
      s_TitleTuner: 'Tuner',
      s_UpgradeToPremium: "Premium'a Yükselt",
      s_Velocity: 'Hız',
      s_HintSettings: 'Ayarlar ekranına gitmek için dokunun',
      s_HelpFrequencyDisp: 'Yardım - Frekans',
      s_HelpCents: 'Yardım - Centler',
      s_TitleEstimatedCents: 'Tahmini Centler',
      s_HintNoteItem: 'Haritalamadaki notlardan biri',
      s_TitlePreviousNote: 'Önceki Not',
      s_HelpPreviousNote: 'Önceki notu seçer',
      s_TitleNextNote: 'Sonraki Not',
      s_HelpNextNote: 'Sonraki notu seçer',
      s_TitleMicrophone: 'Mikrofon hassasiyeti',
      s_HelpMicrophone: 'Mikrofon hassasiyetini değiştirmek için dokunun',
      s_TitleNoteLockToggle: 'Not Kilidi Anahtarı',

      s_SignInToDeleteData: 'Verilerinizi silmek için giriş yapın',
      s_DeleteDataAndSignOut: 'Verileri Sil ve Çıkış Yap',
      s_SignInToSync: 'Senkr. için giriş yapın.',
      s_AreYouSureDeleteData:
        'Verilerinizi silmek istediğinizden emin misiniz?',
      s_LogOutToDisableSync: 'Senkronizasyonu kapatmak için çıkış yapın?',
      s_YesDeleteDataAndLogOut: 'Evet, Verilerimi Sil ve Çıkış Yap',
      s_YesLogOut: 'Evet, Çıkış Yap',

      s_GooglePlayWarning:
        'Strobopro uygulaması için Applicaudia AB tarafından depolanan tüm verileri silmek üzeresiniz.\n' +
        'Devam etmek için, silinecek verilere sahip olduğunuzu doğrulamak için giriş yapın.\n' +
        'Tüm verileriniz silinecek, kapsayacak şekilde\n\n' +
        '\t- enstrüman tercihleri,\n' +
        '\t- özel akortlar ve ayarlar,\n' +
        '\t- cihaz kimlikleri ve cihaza özgü ayarlar,\n' +
        '\t- kullanıcı kimlikleri,\n' +
        '\t- ve zaman damgaları.\n' +
        '\nSilme işleminden sonra hiçbir veri saklanmayacaktır.',
    },
  },
};
